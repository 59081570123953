<template>
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="39" height="39" rx="0.5" fill="#1A1A1A" stroke="#1A1A1A"/>
        <rect x="11" y="10" width="20" height="4" fill="#FAF9F9"/>
        <rect x="19" y="18" width="12" height="4" fill="#FAF9F9"/>
        <rect x="11" y="26" width="12" height="4" fill="#FAF9F9"/>
    </svg>
</template>

<script>
    export default {
        name: "HpHowItWorks1"
    }
</script>

<style scoped>

</style>
