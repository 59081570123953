import Vue from 'vue';
import Vuex from 'vuex';
import StorageRepository from "@/repository/Resource/StorageRepository";
import DateTimeMixin from "@/mixins/DateTimeMixin";
import {Mp3MediaRecorder} from "mp3-mediarecorder";
import Mp3RecorderWorker from 'workerize-loader!../../../mp3-mediarecorder/mp3Worker';

Vue.use(Vuex);

const getDefaultState = () => {
    return {
        recorderRecordStartAt: 0,
        recorderLengthSeconds: 0,
        recorderLimitSeconds: 300,
        recording: false,
        audioChunks: [],
        mediaRecorder: null,
        audioBlob: null,
        audio: null,
        recorderState: false,
        recorderTimer: null,
        shouldSave: true,
    }
}

export default {
    state: getDefaultState(),
    getters: {
        getRecorderRecordStartAt: (state) => {
            return state.recorderRecordStartAt;
        },
        getRecorderLengthSeconds: (state) => {
            return state.recorderLengthSeconds;
        },
        getRecording: (state) => {
            return state.recording;
        },
        getRecorderState: (state) => {
            return state.recorderState;
        },
        getRecorderLimitSeconds: (state) => {
            return state.recorderLimitSeconds;
        },
        getAudioChunks: (state) => {
            return state.audioChunks;
        },
        getAudioBlob: (state) => {
            return state.audioBlob;
        },
        getMediaRecorder: (state) => {
            return state.mediaRecorder;
        },
        getShouldSave: (state) => {
            return state.shouldSave;
        },
    },
    mutations: {
        setRecorderRecordStartAt: (state, startAt) => {
            state.recorderRecordStartAt = startAt;
        },
        setRecorderLengthSeconds: (state, startAt) => {
            state.recorderLengthSeconds = startAt;
        },
        setRecording: (state, recording) => {
            state.recording = recording;
        },
        setRecorderState: (state, recorderState) => {
            state.recorderState = recorderState;
        },
        setRecorderTimer: (state, recorderTimer) => {
            state.recorderTimer = recorderTimer;
        },
        stopRecorderTimer: (state) => {
            clearInterval(state.recorderTimer);
        },
        addAudioChunks: (state, chunk) => {
            state.audioChunks.push(chunk);
        },
        clearAudioChunks: (state) => {
            state.audioChunks = [];
        },
        setAudioBlob: (state, blob) => {
            state.audioBlob = blob;
        },
        setMediaRecorder: (state, mediaRecorder) => {
            state.mediaRecorder = mediaRecorder;
        },
        setShouldSave: (state, shouldSave) => {
            state.shouldSave = shouldSave;
        },
        resetRecorderStore(state) {
            Object.assign(state, getDefaultState());
        },
    },
    actions: {
        initRecorder({commit}) {
            commit('setRecorderState', true);
        },
        closeRecorder({commit, dispatch}) {
            dispatch('cancelRecording');
            commit('setRecorderState', false);
            // dispatch('stopRecording');
        },
        stopRecording({commit, getters}) {
            if (getters.getMediaRecorder) {
                getters.getMediaRecorder.stop();
            }
            commit('setMediaRecorder', null);
            commit('setRecorderRecordStartAt', null);
            commit('stopRecorderTimer');
        },
        cancelRecording({commit, dispatch}) {
            commit('setShouldSave', false);
            commit('clearAudioChunks')
            dispatch('stopRecording');
        },
        startRecording({state, commit, dispatch, getters}) {
            commit('clearAudioChunks');
            commit('setRecorderRecordStartAt', new Date());
            commit('setRecorderLengthSeconds', DateTimeMixin.methods.calculateSecondsFrom(getters.getRecorderRecordStartAt));

            navigator.mediaDevices
                .getUserMedia({audio: true})
                .then(stream => {
                    if (!getters.getMediaRecorder) {
                        commit('setMediaRecorder', new Mp3MediaRecorder(
                            stream, {
                                worker: Mp3RecorderWorker()
                            }));
                        commit('setAudioBlob', new Blob(state.audioChunks));
                    }

                    commit('setShouldSave', true);
                    getters.getMediaRecorder.start();
                    commit('setRecording', true);

                    commit('setRecorderRecordStartAt', new Date());
                    commit('setRecorderTimer', setInterval(() => {
                        commit('setRecorderLengthSeconds', DateTimeMixin.methods.calculateSecondsFrom(getters.getRecorderRecordStartAt));
                    }, 1000));

                    getters.getMediaRecorder
                        .addEventListener("dataavailable", event => {
                            console.log('dataavailable', event)
                            if (getters.getShouldSave) {
                                commit('addAudioChunks', event.data);
                            }
                        });

                    getters.getMediaRecorder
                        .addEventListener("stop", () => {
                            // free device
                            stream.getTracks().forEach(track => track.stop());

                            commit('setRecording', false);

                            if (0 === getters.getAudioChunks.length) {
                                return;
                            }

                            commit('setAudioBlob', new Blob(getters.getAudioChunks));
                            const sizeInBytes = getters.getAudioBlob.size;
                            console.log(Math.round(sizeInBytes) / 1000, 'Kb');

                            StorageRepository
                                .create(getters.getAudioBlob, 'media', 'Some title')
                                .then(data => {
                                    commit('setRecorderState', false)
                                    dispatch('message/addResource', data);
                                })
                                .catch(() => commit('snackbarShow', 'Nie udało się wysłać wiadomości, spróbuj ponownie'))
                                .finally(() => commit('setRecorderState', false));
                        });
                })
                .catch(e => {
                    commit('snackbarShow', 'Nie udało się uzyskać uprawnień do mikrofonu');
                    console.log('unable to record', e);
                });
        },
    }
};
