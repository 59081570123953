<template>
    <div id="home-page" style="background-color:#faf9f9;">

        <div class="section" style="background-color:#fcf3f3;">
            <v-container id="top-menu">
                <v-row>
                    <v-col sm="4" lg="8">
                        <ul>
                            <li class="logo"><img :src="require('../../assets/hp/logo.png')" alt="Logo MójTerapeuta"></li>
                            <li class="hidden-md-and-down"><a href="#how-it-works" @click.prevent="scroll('how-it-works')">Jak zacząć</a></li>
                            <li class="hidden-md-and-down"><a href="#why-us" @click.prevent="scroll('why-us')">Dlaczego MójTerapeuta?</a></li>
                            <li class="hidden-md-and-down"><a href="#plans" @click.prevent="scroll('plans')">Abonamenty</a></li>
                        </ul>
                    </v-col>
                    <v-col class="text-right" sm="8" lg="4">
                        <ul>
                            <li>
                                <router-link :to="{ name: 'route_login' }" class="btn-login">
                                    Zaloguj się
                                </router-link>
                            </li>
                            <li class="hidden-xs-only">
                                <router-link :to="{ name: 'route_register' }" class="btn btn-black text-white">
                                    Rozpocznij rozmowę
                                </router-link>
                            </li>
                        </ul>
                    </v-col>
                    <v-col cols="12" class="hidden-lg-and-up pt-0 submenu-mobile">
                        <ul>
                            <li><a href="#how-it-works" @click.prevent="scroll('how-it-works')">Jak zacząć</a></li>
                            <li><a href="#why-us" @click.prevent="scroll('why-us')">Dlaczego MójTerapeuta?</a></li>
                            <li><a href="#plans" @click.prevent="scroll('plans')">Abonamenty</a></li>
                        </ul>
                    </v-col>
                </v-row>
            </v-container>
        </div>

        <div class="section header" style="background-color:#fcf3f3;">
            <v-container>
                <v-row>
                    <v-col cols="12" sm="6" order="1" order-sm="1">
                        <h1>Psychoterapia online - sięgnij po taką pomoc, jakiej aktualnie potrzebujesz</h1>
                    </v-col>
                    <v-col cols="12" style="position: relative;" order="2" order-sm="3" class="img-slogans text-center text-sm-left">
                        <v-row>
                            <v-col sm="12" lg="auto">
                                <img :src="require('../../assets/hp/slogan-chats.svg')">
                            </v-col>
                            <v-col sm="12" lg="auto">
                                <img :src="require('../../assets/hp/slogan-sessions.svg')">
                            </v-col>
                            <v-col cols="12">
                                <img class="hidden-md-and-down" :src="require('../../assets/hp/slogan-video.svg')">
                                <img class="hidden-lg-and-up" :src="require('../../assets/hp/slogan-video-mob.svg')">
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" class="hidden-sm-and-up text-center" order="3">
                        <router-link :to="{ name: 'route_register' }" class="btn btn-black btn-start-therapy-mobile text-center">
                            Rozpocznij rozmowę
                        </router-link>
                    </v-col>
                    <v-col cols="12" sm="6" order="4" order-sm="2" style="position: relative" class="img-phone">
                        <img :src="require('../../assets/hp/screen1.png')">
                    </v-col>
                </v-row>
                <hr class="hidden-xs-only">
                <v-row>
                    <v-col xl="8" lg="6" md="7" sm="8">
                        <h3>Jesteś w kryzysie&nbsp;i potrzebujesz wsparcia na co dzień?</h3>
                        <p>MójTerapeuta to aplikacja, która umożliwia rozpoczęcie psychoterapii online, każdemu z dowolnego miejsca na świecie.</p>
                        <p>W każdej chwili możesz napisać do swojego psychoterapeuty lub nagrać mu wiadomość głosową&nbsp;i wyrazić na bieżąco to, co czujesz.
                            Twój psychoterapeuta codziennie do Ciebie odpisze. Jesteście w stałym kontakcie.</p>
                    </v-col>
                </v-row>
            </v-container>
        </div>

        <div class="section" style="background-color: #faf9f9;">
            <v-container>
                <v-row>
                    <v-col cols="12" sm="6" style="position: relative; overflow: hidden">
                        <img class="hidden-xs-only" :src="require('../../assets/hp/img2.png')"
                             style="position: absolute; right: -30px; top: -100px;"/>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <h3>Potrzebujesz dłuższej rozmowy&nbsp;i zobaczenia się z psychoterapeutą?</h3>
                        <p>Możesz spotykać się podczas sesji wideo tak często, jak jest to potrzebne w danym momencie Twojej psychoterapii.</p>
                        <p>Skuteczność psychoterapii z użyciem wiadomości tekstowych została w ostatnich latach dogłębnie przebadana.
                            Jej efektywność w udzielaniu pomocy jest równie wysoka jak w terapii prowadzonej w formie gabinetowej.</p>
                    </v-col>
                </v-row>
            </v-container>
        </div>

        <div class="section" style="background-color: #faf9f9;">
            <v-container>
                <v-row>
                    <v-col class="text-left text-sm-center">
                        <img :src="require('../../assets/hp/citation.png')" style="width: 40px; height: 40px; margin: 10px 0; display: inline-block">
                        <p class="citation">Pacjenci deklarują, że ujawniają w terapii tekstowej online informacje, których nigdy nie udzielili w swojej wieloletniej psychoterapii w gabinecie</p>
                        <p class="citation-footer">– Irvin Yalom</p>
                    </v-col>
                </v-row>
            </v-container>
        </div>

        <div class="section how-it-works" style="background-color: #faf9f9;">
            <v-container>
                <v-row>
                    <Header header-id="how-it-works" label="Jak zacząć?" bg-color="#faf9f9"/>
                </v-row>

                <v-row>
                    <v-col cols="12" md="4">
                        <v-row>
                            <v-col cols="auto">
                                <div style="width:41px;height:41px;float:left;border:1px solid #000;">
                                    <HpHowItWorks1/>
                                </div>
                                <div style="width:41px;height:41px;float:left;border:1px solid #000;text-align:center;line-height: 41px;font-weight: 600;font-size: 22px;">
                                    1
                                </div>
                            </v-col>
                            <v-col class="hidden-sm-and-down" style="padding-top:18px; background-position: right center;"
                                   :style="{'background-image':'url('+require('../../assets/hp/arrow.svg')+')'}">
                            </v-col>
                        </v-row>
                        <h3>Rozmowa z konsultującym terapeutą, który poznaje Ciebie, Twoje potrzeby&nbsp;i problem
                            z jakim się zgłaszasz. </h3>
                        <p>Terapeuta zbiera podstawowe informacje o Tobie, które pomogą mu w dopasowaniu odpowiedniego terapeuty</p>
                        <router-link :to="{ name: 'route_register' }" class="btn btn-black text-uppercase hidden-md-and-up">
                            Rozpocznij rozmowę
                        </router-link>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-row>
                            <v-col cols="auto">
                                <div style="width:41px;height:41px;float:left;border:1px solid #000;">
                                    <HpHowItWorks2 style="margin-left: -7px"/>
                                </div>
                                <div style="width:41px;height:41px;float:left;border:1px solid #000;text-align:center;line-height: 41px;font-weight: 600;font-size: 22px;">2</div>
                            </v-col>
                            <v-col class="hidden-sm-and-down" style="padding-top:18px; background-position: right center;"
                                   :style="{'background-image':'url('+require('../../assets/hp/arrow.svg')+')'}">
                            </v-col>
                        </v-row>
                        <h3>Wybór odpowiedniego abonamentu.</h3>
                        <p>Abonament możesz dopasować do Twoich potrzeb. Pamiętaj, że zawsze możesz go zmienić
                            w trakcie trwania psychoterapii.</p>
                        <a href="#plans" @click.prevent="scroll('plans')" class="btn btn-black-outline text-uppercase hidden-md-and-up">
                            Sprawdź plany
                        </a>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-row>
                            <v-col cols="auto">
                                <div style="width:41px;height:41px;float:left;border:1px solid #000;">
                                    <HpHowItWorks3/>
                                </div>
                                <div style="width:41px;height:41px;float:left;border:1px solid #000;text-align:center;line-height: 41px;font-weight: 600;font-size: 22px;">3</div>
                            </v-col>
                            <v-col>
                            </v-col>
                        </v-row>
                        <h3>Rozpocznij psychoterapię z wybranym dla Ciebie psychoterapeutą.</h3>
                        <p>Jeśli będziesz mieć pytania dotyczące abonamentów lub organizacji swojej psychoterapii,
                            możesz je w każdym momencie zadać konsultującemu terapeucie</p>
                    </v-col>
                </v-row>
                <v-row class="hidden-sm-and-down">
                    <v-col cols="12" md="4" xl="4">
                        <router-link :to="{ name: 'route_register' }" class="btn btn-black text-uppercase">
                            Rozpocznij rozmowę
                        </router-link>
                    </v-col>
                    <v-col cols="12" md="4" xl="4">
                        <a href="#plans" @click.prevent="scroll('plans')" class="btn btn-black-outline text-uppercase">
                            Sprawdź plany
                        </a>
                    </v-col>
                </v-row>
            </v-container>
        </div>

        <div class="section faq availability" style="background-color: #fcf3f2;">
            <v-container>
                <v-row>
                    <Header header-id="why-us" label="Dlaczego MójTerapeuta?" bg-color="#fcf3f2"/>
                </v-row>
                <v-row>
                    <v-col order="2" order-sm="1" cols="12" sm="6" style="position: relative; overflow: hidden;">
                        <img :src="require('../../assets/hp/screen3.png')">
                    </v-col>
                    <v-col order="1" order-sm="2" cols="12" sm="5" class="mb-12">
                        <FaqHeader subtitle="Dostępność i wygoda" header-id="availability"
                            title="Dzięki MójTerapeuta możesz już dziś rozpocząć rozmowę z wykwalifikowanym psychoterapeutą z dowolnego miejsca."/>

                        <p>Nie ogranicza Cię choroba, niepełnosprawność czy brak opieki dla dziecka. Przeszkody typu:
                            przeziębienie, intensywny dzień w pracy czy przełożone spotkanie nie muszą już kolidować z
                            Twoją psychoterapią.</p>

                        <p>Możesz pisać, nagrywać wiadomości głosowe w dowolnym momencie każdego dnia&nbsp;i być pewnym,
                            że otrzymasz na nie odpowiedź od swojego psychoterapeuty jeszcze tego samego dnia.</p>

                        <p class="hidden-xs-only">
                            <router-link :to="{ name: 'route_register' }" class="btn btn-black text-white text-uppercase">
                                Rozpocznij rozmowę
                            </router-link>
                        </p>
                    </v-col>
                    <v-col sm="1" order-sm="3" class="hidden-xs-only">
                        <FaqIndicator :active-item="1"/>
                    </v-col>
                </v-row>
            </v-container>
        </div>

        <div class="section faq for-whom">
            <v-container>
                <v-row>
                    <v-col cols="12" sm="6">
                        <FaqHeader subtitle="Dla kogo" header-id="for-whom"
                                   title="Czujesz, że doświadczane obecnie trudności życiowe przerastają Cię i nie jesteś w stanie sam sobie z nimi poradzić."/>
                        <p>Twoje własne sposoby radzenia sobie z problemami&nbsp;i rozwiązania ich stają się nieskuteczne,
                            a wręcz same stają się źródłem cierpienia Twojego lub Twoich bliskich. Twoje najbliższe
                            otoczenie nie potrafi Ci pomóc. Nie lubisz mówić o sobie, a wolisz pisać wiadomości tekstowe.</p>
                        <p>Specjalizujemy się, zarówno w leczeniu wielu zdiagnozowanych problemów, jak&nbsp;i w szeroko
                            pojętym samorozwoju osobistym. Pracujemy z ludźmi, którzy borykają się m.in. z:</p>
                    </v-col>
                    <v-col class="hidden-xs-only" cols="12" sm="5" style="text-align: center">
                        <img :src="require('../../assets/hp/bg-for-whom.png')">
                    </v-col>
                    <v-col class="hidden-xs-only" sm="1">
                        <FaqIndicator :active-item="2"/>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="4">
                        <ul>
                            <li>depresją</li>
                            <li>zaburzeniami lękowymi</li>
                            <li>zaburzeniami odżywiania</li>
                            <li>problemami w relacjach</li>
                            <li>zaburzeniami</li>
                            <li>obsesyjno- kompulsywnymi</li>
                            <li>zaburzeniami psychosomatycznymi</li>
                            <li>nieradzeniem sobie z emocjami</li>
                        </ul>
                    </v-col>
                    <v-col cols="12" md="4">
                        <ul>
                            <li>zaburzeniami typu borderline</li>
                            <li>pracoholizmem</li>
                            <li>fobią społeczną</li>
                            <li>stresem</li>
                            <li>kryzysem w związku</li>
                            <li>kryzysem emocjonalnym</li>
                            <li>stanem żałoby</li>
                            <li>niskim poczuciem własnej wartości</li>
                        </ul>
                    </v-col>
                    <v-col cols="12" md="4">
                        <ul>
                            <li>wypaleniem zawodowym</li>
                            <li>bezsennością</li>
                            <li>innego typu fobiami</li>
                            <li>zaburzeniami osobowości</li>
                            <li>problemami rodzinnymi</li>
                            <li>problemami seksualnymi</li>
                            <li>problemami wychowawczymi</li>
                            <li>trudnościami okołoporodowymi</li>
                        </ul>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <p>Pomocy psychoterapeutycznej udzielamy osobom dorosłym.</p>
                    </v-col>
                </v-row>
                <v-row class="doubts">
                    <v-col cols="12" sm="9">
                        <strong>Jeśli masz wątpliwości czy możemy pomóc Ci z Twoim problemem rozpocznij rozmowę z
                            konsultującym terapeutą. W razie potrzeby przekieruje Cię w odpowiednie miejsce,
                            gdzie uzyskasz pomoc.</strong>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <router-link :to="{ name: 'route_register' }"
                                     class="btn btn-black text-white text-uppercase">
                            Rozpocznij rozmowę
                        </router-link>
                    </v-col>
                </v-row>
            </v-container>
        </div>

        <div class="section faq team" style="background-color:#fcf3f3;">
            <v-container>
                <v-row>
                    <v-col cols="12" sm="6" style="position: relative; overflow: hidden">
                        <img :src="require('../../assets/hp/bg-our-team.png')">
                    </v-col>
                    <v-col cols="12" sm="5">
                        <FaqHeader subtitle="Zespół" header-id="our-team"
                                   title="Wszyscy nasi psychoterapeuci posiadają wysokie kwalifikacje i wymagane wykształcenie."/>
                        <p>Wśród naszych psychoterapeutów są osoby z wieloletnim doświadczeniem w prowadzeniu pacjentów,
                            którzy ukończyli renomowane szkoły psychoterapii, członkowie&nbsp;i posiadacze certyfikatów
                            Polskiego Towarzystwa Psychologicznego.</p>
                        <p>Zespół składa się z psychoterapeutów pracujących w różnych nurtach. Część z nich pracuje w
                            nurcie psychodynamicznym, inni w poznawczo-behawioralnym, jeszcze inni w humanistycznym,
                            gestaltowskim czy integracyjnym.</p>
                        <p><strong>MójTerapeuta dobiera swój zespół z dużą rozwagą, bada skuteczność pracy
                            psychoterapeutów&nbsp;i zapewnia im regularną superwizję. </strong></p>
                        <p>
                            <router-link :to="{ name: 'route_register' }" class="btn btn-black text-white text-uppercase">
                                Rozpocznij rozmowę
                            </router-link>
                        </p>
                    </v-col>
                    <v-col class="hidden-xs-only" sm="1">
                        <FaqIndicator :active-item="3"/>
                    </v-col>
                </v-row>
            </v-container>
        </div>

        <div class="section faq price">
            <v-container>
                <v-row>
                    <v-col cols="12" sm="9">
                        <FaqHeader subtitle="Przystępna cena" header-id="good-price"
                                   title="MójTerapeuta umożliwia pracę ze świetnie wykwalifikowanymi specjalistami w cenie, która nie byłaby możliwa w gabinetowej formie pracy."/>
                        <p class="strong">Rozmowa z konsultującym terapeutą jest darmowa.</p>
                        <p>
                            <a href="#plans" @click.prevent="scroll('plans')" class="btn btn-black-outline text-uppercase">
                                Sprawdź abonamenty
                            </a>
                        </p>
                    </v-col>
                    <v-col class="hidden-xs-only" sm="1" offset-sm="2">
                        <FaqIndicator :active-item="4"/>
                    </v-col>
                </v-row>
            </v-container>
        </div>

        <div class="section faq security">
            <div>
                <v-container>
                    <v-row>
                        <v-col cols="12" sm="8">
                            <FaqHeader subtitle="Bezpieczeństwo" header-id="security" class="text-white"
                                       title="Możesz mieć pewność, że dostęp do Twoich rozmów z psychoterapeutą masz tylko Ty i on."/>
                            <p class="text-white">MójTerapeuta korzysta z najnowocześniejszych zabezpieczeń, wiadomości są szyfrowane.</p>
                            <p class="text-white">Po zalogowaniu możesz z dowolnego urządzenia łączyć się ze swoim
                                psychoterapeutą oraz mieć dostęp do historii Waszej dotychczasowej pracy.</p>
                        </v-col>
                        <v-col class="hidden-xs-only" sm="1" offset-sm="3">
                            <FaqIndicator :active-item="5" :inverted="true"/>
                        </v-col>
                    </v-row>
                </v-container>
            </div>
        </div>

        <div class="section" style="background-color: #faf9f9;">
            <v-container>
                <v-row>
                    <Header header-id="plans" bg-color="#faf9f9" label="Abonamenty"/>
                </v-row>
                <v-row>
                    <v-col sm="6">
                        <p>W ramach abonamentów możesz pisać codziennie wiadomości
                            tekstowe lub nagrywać wiadomości głosowe do swojego terapeuty oraz odbywać sesje wideo.</p>
                    </v-col>
                </v-row>
                <v-row class="plans" style="background-position: 35px 0; background-size: 882px 501px; padding-top: 15px; padding-bottom: 50px;"
                       :style="{'background-image':'url('+require('../../assets/hp/bg-plans.png')+')'}">
                    <v-col cols="12" sm="6" md="3">
                        <Plan name="Abonament 1" :price="prices.planWithChatOnly"
                              answers="1 - 2" session-duration="30" />
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                        <Plan name="Abonament 2" :price="prices.planWith1SessionAndChat"
                              answers="1 - 2" :sessions="1" session-duration="30" />
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                        <Plan name="Abonament 3" :price="prices.planWith4SessionsAndChat"
                              answers="1 - 2" :sessions="4" session-duration="30" />
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                        <Plan name="Abonament 4" :price="prices.planWith4Sessions"
                              :sessions="4" session-duration="30" />
                    </v-col>
                    <v-col cols="12" style="margin-top: 20px;" offset-sm="2" sm="8">
                        <AdditionalSession :price="prices.session30" :duration="30"/>
                    </v-col>
                    <v-col cols="12" offset-sm="2" sm="8">
                        <AdditionalSession :price="prices.session50" :duration="50"/>
                    </v-col>
                </v-row>
            </v-container>
        </div>

        <div class="section promo">
            <v-container>
                <v-row>
                    <v-col cols="12" md="5">
                        <p>Oferta dla nowych użytkowników
                            <small>Wykup pojedynczy tydzień próbny.</small></p>
                        <router-link :to="{ name: 'route_register' }" class="btn">
                            Zarejestruj się
                        </router-link>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="12" md="3" class="plan-container">
                        <Plan name="Tydzień psychoterapii tekstowej" :price="prices.trialWithoutSession"
                              :preserve-height="false" :trial="true"/>
                    </v-col>
                    <v-col cols="12" md="3" class="plan-container">
                        <Plan name="Tydzień psychoterapii tekstowej i 30 minutowa sesja"
                              :price="prices.trialWithSession" :preserve-height="false" :trial="true"/>
                    </v-col>
                    <v-spacer></v-spacer>
                </v-row>
            </v-container>
        </div>

        <div class="section footer1">
            <v-container>
                <v-row>
                    <v-col>
                        <p>Rozmowa z terapeutą konsultującym jest darmowa</p>
                        <p>
                            <router-link :to="{ name: 'route_register' }" class="btn btn-white text-green">
                                Rozpocznij teraz
                            </router-link>
                        </p>
                    </v-col>
                </v-row>
            </v-container>
        </div>

        <div class="section footer2" style="background-color: #000">
            <v-container>
                <v-row>
                    <v-col>
                        <p>Jeśli jesteś w sytuacji kryzysowej, w której towarzyszą Ci realne myśli samobójcze, NIE
                            KORZYSTAJ z nasze oferty.<br>Pomoc uzyskasz pod numerem
                            <a href="tel:116123">116 123</a> (czynny codziennie w godzinach 14:00-20:00).</p>
                    </v-col>
                </v-row>
            </v-container>
        </div>

        <div class="section footer3" style="background-color: #faf9f9">
            <v-container>
                <v-row>
                    <v-col order-md="5" class="hidden-sm-and-down"></v-col>
                    <v-col cols="12" md="auto" order="1" order-md="1" class="pb-0">
                        <p><img :src="require('../../assets/hp/logo.png')" style="height: 20px;"></p>
                    </v-col>
                    <v-col cols="12" md="auto" order="2" order-md="6" class="pb-0" v-if="pwaInstallPrompt != null">
                        <p><a @click="installPwa"
                              class="btn btn-black-outline">Zainstaluj aplikację</a></p>
                    </v-col>
                    <v-col cols="12" md="auto" order="3" order-md="2" class="pb-0">
                        <a href="https://www.facebook.com/MojTerapeuta"><img src="../../assets/hp/ic-facebook.png"></a>
                        <a href="https://www.instagram.com/mojterapeuta.app"><img src="../../assets/hp/ic-instagram.png"></a>
                    </v-col>
                    <v-col cols="12" md="auto" order="4" order-md="3" class="pb-0">
                        <img src="../../assets/hp/ic-mail.png" style="vertical-align: -3px; display: inline-block; margin-right: 5px">
                        <a href="mailto:info@mojterapeuta.app" style="font-weight: 600">info@mojterapeuta.app</a>
                    </v-col>
                    <v-col cols="12" md="auto" order="5" order-md="4" class="pb-0">
                        Puławska 24a/6, 02-512 Warszawa<br>
                    </v-col>
                </v-row>
                <v-row class="privacy">
                    <v-col cols="12" md="auto" order="2" order-md="1" class="pt-0">
                        <span class="copyright">&copy; 2021 All Rights Reserved</span>
                    </v-col>
                    <v-col cols="12" md="auto" order="1" order-md="2" class="pt-0">
                        <span class="links"><a href="https://mojterapeuta.app/regulamin.pdf">Regulamin</a> i <a
                            href="https://mojterapeuta.app/polityka-prywatnosci.pdf">Polityka prywatności</a></span>
                    </v-col>
                </v-row>
            </v-container>
        </div>

        <div class="cookies" v-if="!cookiesAccepted">
            <v-container>
                <v-row style="align-items: center">
                    <v-col>
                        Na tej stronie korzystamy z plików cookie, aby zadbać o jak najlepsze doświadczenie naszych
                        użytkowników.<br> Kontynuując po wyświetleniu tej wiadomości, wyrażasz na to zgodę.
                    </v-col>
                    <v-col cols="12" md="auto" class="text-center">
                        <a class="btn" @click="acceptCookies">
                            Zgadzam się
                        </a>
                    </v-col>
                </v-row>
            </v-container>
        </div>

        <WelcomeOffer/>

    </div>
</template>

<script>
    import HpHowItWorks3 from "../Icons/HpHowItWorks3";
    import HpHowItWorks2 from "../Icons/HpHowItWorks2";
    import HpHowItWorks1 from "../Icons/HpHowItWorks1";
    import Header from "@/components/Home/Header";
    import FaqIndicator from "@/components/Home/FaqIndicator";
    import FaqHeader from "@/components/Home/FaqHeader";
    import {scrollToElement} from "@/service/Scroll";
    import Plan from "@/components/Common/Plan";
    import AdditionalSession from "@/components/Common/AdditionalSession";
    import '@pwabuilder/pwainstall'
    import WelcomeOffer from "@/components/Home/WelcomeOffer";
    import prices from '../../prices';

    export default {
        name: 'HomePage',
        props: {
            msg: String
        },
        components: {
            WelcomeOffer,
            AdditionalSession,
            Plan,
            FaqHeader,
            FaqIndicator,
            Header,
            HpHowItWorks1,
            HpHowItWorks2,
            HpHowItWorks3,
            // ChatMain,
            // ButtonOrange,
            // Button,
            // LogoFull,
        },
        data() {
            return {
                prices,
                products: null,
                areCookiesAccepted: false,
                // keep reference to PWA installation prompt
                pwaInstallPrompt: null
            }
        },
        watch: {
            '$route' () {
                this.pagination = null;
            }
        },
        // data() {
        //     return {
        //         loaded: false
        //     }
        // },
        computed: {
            cookiesAccepted: {
                get() {
                    return this.areCookiesAccepted || localStorage.getItem('cookies_accepted') !== null
                },
                set(value) {
                    localStorage.setItem('cookies_accepted', `${value}`)
                    this.areCookiesAccepted = true;
                }
            },
            isLoaded() {
                return true;
                // return null !== this.products;
            }
        },
        created() {
            window.addEventListener('beforeinstallprompt', (e) => {
                e.preventDefault();
                this.pwaInstallPrompt = e;
            });
        },
        methods: {
            scroll(id) {
                scrollToElement(id);
            },
            acceptCookies() {
                this.cookiesAccepted = true;
            },
            async installPwa() {
                this.pwaInstallPrompt.prompt();
                await this.pwaInstallPrompt.userChoice;
                this.pwaInstallPrompt = null;
            },
            // collectProducts() {
            //     let page = 1;
            //
            //     if (this.$route && typeof this.$route.query.page !== "undefined" && !isNaN(parseInt(this.$route.query.page))) {
            //         page = parseInt(this.$route.query.page);
            //     }
            //
            // },
        },
    }
</script>

<style>
.plan-container > div {
    height: 200px;
}
</style>
<style scoped lang="scss">
@import '~vuetify/src/styles/settings/_variables.scss';

h2 {
    font-size: 40px;
    line-height: 32px;
    font-weight: 600;

    @media #{map-get($display-breakpoints, 'md-and-down')} {
        font-size: 32px;
    }
}

h3 {
    font-size: 22px;
    font-weight: 600;

    @media #{map-get($display-breakpoints, 'md-and-down')} {
        font-size: 18px;
    }
}

p {
    font-size: 18px;
    line-height: 28px;
    text-align: justify;

    @media #{map-get($display-breakpoints, 'md-and-down')} {
        font-size: 16px;
        line-height: 26px;
    }
}

.container {
    @media #{map-get($display-breakpoints, 'xs-only')} {
        padding: 18px;
    }
}

#top-menu {
    @media #{map-get($display-breakpoints, 'xs-only')} {
        padding-top: 0;
        padding-bottom: 0;

        .col {
            padding: 0;
        }

        .btn-login {
            font-size: 14px;
            line-height: 38px;
        }
    }
}

#top-menu ul {
    list-style-type: none;
    padding: 0;
}

#top-menu ul li {
    display: inline-block;
    margin: 0 16px;
    font-size: 16px;
    line-height: 44px;
    font-weight: 500;
}

@media #{map-get($display-breakpoints, 'xs-only')} {
    #top-menu ul li.logo {
        padding-left: 16px;
    }
}

#top-menu ul li.logo img {
    height: 30px;
    margin: -7px 0;

    @media #{map-get($display-breakpoints, 'xs-only')} {
        height: 20px;
    }
}

#top-menu ul li a {
    color: #1a1a1a;
}

#top-menu ul li a:hover {
    text-decoration: underline;
}

#top-menu ul li:first-child {
    margin: 0 16px 0 0;
}

#top-menu ul li:last-child {
    margin: 0 0 0 16px;
}

#home-page {
    font-size: 18px;
    /*font-weight:regular;*/
    line-height: 2;
    color: #1a1a1a;
}

.btn-start-therapy-mobile {
    display: block;
}

.section {
    padding-top: 32px;

    @media #{map-get($display-breakpoints, 'xs-only')} {
        padding-top: 0;
    }
}

.section:first-child {
    padding-top: inherit;
}

.section.how-it-works {
    @media #{map-get($display-breakpoints, 'xs-only')} {
        a {
            display: block;
            text-align: center;
        }
    }
}

.section.faq p, .section.faq ul {
    font-size: 18px;
    line-height: 32px;
    margin-bottom: 32px;

    @media #{map-get($display-breakpoints, 'md-and-down')} {
        font-size: 16px;
        line-height: 28px;
        margin-bottom: 24px;
    }
}

@media #{map-get($display-breakpoints, 'md-and-down')} {
    .section.faq ul {
        margin-bottom: 0;
    }
}

.section.faq p strong {
    font-weight: 600;
}

.section.faq p.strong {
    font-size: 32px;
}

.section.header {

    h1 {
        font-size: 40px;

        @media #{map-get($display-breakpoints, 'md-and-down')} {
            font-size: 31px;
        }

        @media #{map-get($display-breakpoints, 'xs-only')} {
            font-size: 24px;
            line-height: 37px;
        }
    }

    .img-slogans .row .col {
        &:nth-child(1) {

        }
        &:nth-child(2) {
            padding-left: 50px;

            @media #{map-get($display-breakpoints, 'xs-only')} {
                padding-left: 0;
                text-align: right;
            }
        }
    }

    hr {
        margin-right: 380px;
        color: #BA3D1A;
        margin-top: 75px;
        margin-bottom: 50px;
        border: 1px solid #BA3D1A;
    }

    > .container {
        background-image: url(../../assets/hp/bg-header.png);
        background-size: 300px;
        background-position: top 20px right 100px;

        @media #{map-get($display-breakpoints, 'xs-only')} {
            background-image: none;

            > .row:first-child {
                margin-left: -18px;
                margin-right: -18px;

                > .col-12 {
                    padding-left: 18px;
                    padding-right: 18px;
                }
            }

            .img-phone {
                background-image: url(../../assets/hp/bg-header.png);
                background-size: 300px;
                background-position: top 20px left -30px;
            }
        }
    }

    .img-phone img {
        width: 320px;
        position: absolute;
        top: 70px;
        right: 20px;

        @media #{map-get($display-breakpoints, 'md-only')} {
            //with: 640px;
        }

        @media #{map-get($display-breakpoints, 'sm-and-down')} {
            //height: inherit;
            //max-width: 100%;
            //max-height: 540px;
        }

        @media #{map-get($display-breakpoints, 'xs-only')} {
            display: block;
            position: static;
            margin: 60px auto 0;
        }
    }
}

.section.faq.for-whom {
    img {
        width: 400px;

        @media #{map-get($display-breakpoints, 'xs-only')} {
            width: 60%;
        }
    }

    .doubts {
        background-color: #fff;
        box-shadow: rgba(8, 30, 45, 0.074) 0 -2px 14px;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 50px;
        padding: 10px;

        strong {
            font-weight: 600;
            line-height: 33px;
            font-size: 18px;
        }

        a {
            margin-top: 20px;
        }

        @media #{map-get($display-breakpoints, 'xs-only')} {

            strong {
                line-height: 28px;
                font-size: 16px;
            }

            a {
                margin-top: 0;
                display: block;
                text-align: center;
            }
        }
    }
}

.section.faq.availability {
    > .container {
        background-position: top 200px left 0;
        background-image: url(../../assets/hp/bg-availability.png);
        background-size: 490px;
    }

    clip-path: polygon(0 2%, 25% 0, 33% 2%, 55% 0, 66% 1%, 75% 0, 90% 2%, 100% 2%,
        100% 100%, 92% 98%, 84% 100%, 66% 99%, 45% 100%, 25% 98%, 15% 99%, 0 99%);

    @media #{map-get($display-breakpoints, 'md-and-down')} {
        > .container {
            background-size: 390px;
        }
    }

    @media #{map-get($display-breakpoints, 'sm-and-down')} {
        > .container {
            background-position-x: 20px;
            background-size: 340px;
        }
    }

    @media #{map-get($display-breakpoints, 'xs-only')} {
        clip-path: none;

        > .container {
            background-size: 490px;
            background-position: center bottom;
        }
    }

    img {
        width: 300px;
        position: absolute;
        right: 20px;
        bottom: 0;

        @media #{map-get($display-breakpoints, 'md-and-down')} {
            position: absolute;
            right: 20px;
            width: 250px;
        }

        @media #{map-get($display-breakpoints, 'sm-and-down')} {
            position: static;
            width: 80%;
            margin: 50px auto 0;
            display: block;
        }

        @media #{map-get($display-breakpoints, 'xs-only')} {
            width: 180px;
            margin-top: 0;
        }
    }
}

.section.faq.team {
    margin-bottom: -20px;
    clip-path: polygon(0 2%, 25% 0, 33% 2%, 55% 0, 66% 1%, 75% 0, 90% 2%, 100% 2%,
        100% 100%, 92% 98%, 84% 100%, 66% 99%, 45% 100%, 25% 98%, 15% 99%, 0 99%);

    @media #{map-get($display-breakpoints, 'xs-only')} {
        clip-path: none;

        a {
            display: block;
            text-align: center;
        }
    }

    img {
        width: 539px;

        @media #{map-get($display-breakpoints, 'md-and-down')} {
            position: absolute;
            right: 20px;
            width: 439px;
        }

        @media #{map-get($display-breakpoints, 'xs-only')} {
            position: static;
        }
    }
}

.section.faq.price {
    padding-top: 100px;
    padding-bottom: 100px;
    background-size: 520px;
    background-position: 80% 65%;
    background-image: url(../../assets/hp/bg-price.png);

    @media #{map-get($display-breakpoints, 'xs-only')} {
        background-image: none;

        a {
            display: block;
            text-align: center;
        }
    }
}

.section.faq.security {
    margin-top: -20px;
    background-color: #396e51;
    clip-path: polygon(0 2%, 25% 0, 33% 2%, 55% 0, 66% 1%, 75% 0, 90% 2%, 100% 2%,
        100% 100%, 92% 98%, 84% 100%, 66% 99%, 45% 100%, 25% 98%, 15% 99%, 0 99%);
    background-size: 700px;
    padding-top: 100px;
    background-position: right -120px;
    background-image: url(../../assets/hp/bg-security-1.png);

    > div {
        background-image: url(../../assets/hp/bg-security-2.png);
        padding-bottom: 130px;
        background-size: 600px;
        background-position: center 140%
    }

    @media #{map-get($display-breakpoints, 'xs-only')} {
        clip-path: none;
    }
}

a {
    color: #42b983;
}

.tile {
    border-radius: 20px;
    background-color: #fff;
    padding: 15px;
}

.btn-login {
    color: #000;
    text-decoration: underline;
}

.btn-login:hover {
    color: #000;
    text-decoration: none;
}

.citation, .citation-footer {
    line-height: 57px;
    text-align: center;

    @media #{map-get($display-breakpoints, 'md-and-down')} {
        line-height: 50px;
    }
}

.citation {
    font-size: 42px;
    font-weight: 300;

    @media #{map-get($display-breakpoints, 'md-and-down')} {
        font-size: 36px;
    }

    @media #{map-get($display-breakpoints, 'xs-only')} {
        font-size: 24px;
        line-height: 32px;
    }
}

.citation-footer {
    font-size: 20px;
    font-weight: 700;
}

.plans {
    padding-left: 60px;
    padding-right: 60px;

    @media #{map-get($display-breakpoints, 'md-only')} {
        padding-left: 10px;
        padding-right: 10px;
    }

    @media #{map-get($display-breakpoints, 'sm-and-down')} {
        padding-left: 3px;
        padding-right: 3px;
    }

    .col {
        padding-left: 13px;
        padding-right: 13px;

        @media #{map-get($display-breakpoints, 'sm-only')} {
            padding-left: 6px;
            padding-right: 6px;
        }
    }
}

@media #{map-get($display-breakpoints, 'xs-only')} {
    .footer1 a, .footer3 a {
        display: block;
        text-align: center;
    }
}

.promo {
    background-color: #2D87A0;
    background-image: url('../../assets/hp/bg-promo.png');
    background-size: 300px;
    background-position: top right;
    padding: 50px 0;
}

/*
.promo > div {
    background-image: url('../../assets/hp/bg-promo-2.png');
    background-size: 220px;
    background-position: center 30px;
}
*/

.promo p {
    font-size: 34px;
    line-height: 40px;
    font-weight: 700;
    color: #fff;
    margin-bottom: 80px;
    text-align: left;
}
.promo p small {
    display: block;
    margin: 25px 0;
    font-size: 18px;
    line-height: 27px;
}
.promo a {
    color: #2D87A0 !important;
    background-color: white;
    font-weight: 700;
    padding-left: 30px;
    padding-right: 30px;
}

.footer1 {
    background-color: #386D50;
    padding: 50px 0;
}

.footer1 p {
    text-align: center;
    color: #fff;
}
.footer1 p:first-child {
    font-weight: 700;
    font-size: 34px;
    margin-bottom: 40px;
}
.footer2 p {
    text-align: center;
    font-weight: 700;
    color: #fff;
}
.footer2 p a {
    color: #fff;
    text-decoration: underline;
}
.footer2 p a:hover {
    text-decoration: none;
}
.footer3 {
    text-align: center;
    background-color: #fff;
    font-size: 12px;
    line-height: 38px;
}
.footer3 p {
    font-size: 16px;
    line-height: 38px;
    text-align: center;
}
.footer3 .btn {
    font-size: 14px;
    font-weight: 600;
}
.footer3 p strong {
    font-weight: 500;
}
.footer3 p a, .footer3 a {
    color: #1a1a1a;
    text-decoration: underline;
}
.footer3 p a:hover, .footer3 a:hover {
    text-decoration: none;
}
.footer3 .privacy {
    border-top: 2px solid #d8d8d8;
    padding-top: 20px;
    margin-bottom: 0;
    @media #{map-get($display-breakpoints, 'md-and-up')} {
        padding-top: 0;
        border: 0;
    }
}

.footer3 p.privacy a {
    text-decoration: none;
    font-weight: 500;
}
.footer3 p.privacy a:hover {
    text-decoration: underline;
}

.cookies {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: black;
    color: white;
    font-size: 16px;
    line-height: 26px;
}
.cookies .btn {
    color: black;
    background-color: white;
    font-weight: 600;
}

@media #{map-get($display-breakpoints, 'xs-only')} {
    .submenu-mobile {
        margin-left: 16px;
    }
    .submenu-mobile a {
        font-size: 90%;
    }
}

</style>
