import Vue from 'vue';
import HomePage from '../components/Home/HomePage.vue';
import VueRouter from "vue-router";
import LoginPage from "../components/Page/Authorization/LoginPage";
import RegisterPage from "../components/Page/Authorization/RegisterPage";
import ChatPage from "../components/Page/ChatPage";
import SettingsPage from "../components/Page/Account/SettingsPage";
import SubscriptionPage from "../components/Page/Account/SubscriptionPage";
import TherapistPage from "../components/Page/Account/TherapistPage";
import ResetPasswordPage from "../components/Page/Authorization/ResetPasswordPage";
import CalendarPage from "@/components/Page/Therapist/CalendarPage";
import TherapyPage from "@/components/Page/TherapyPage";

Vue.use(VueRouter);

const router =  new VueRouter({
    mode: 'history',
    // base: '/fr',
    routes: [
        // alias
        {
            path: '/',
            name: 'route_home',
            component: HomePage,
            meta: {
                public: true,
                footer: true,
                top: false,
                onlyWhenLoggedOut: true,
            },
        },
        {
            path: '/chat',
            name: 'route_chat',
            component: ChatPage,
            meta: {
                chatWithTherapist: true,
                chatWithConsultant: false,
                public: false,
                onlyWhenLoggedOut: false,
            }
        },
        {
            path: '/consultation',
            name: 'route_consultation',
            component: ChatPage,
            meta: {
                chatWithTherapist: false,
                chatWithConsultant: true,
                public: false,
                onlyWhenLoggedOut: false,
            }
        },
        {
            path: '/settings',
            name: 'route_settings',
            component: SettingsPage,
            meta: {
                public: false,
                onlyWhenLoggedOut: false,
            }
        },
        {
            path: '/calendar',
            name: 'route_calendar',
            component: CalendarPage,
            meta: {
                public: false,
                onlyWhenLoggedOut: false,
            }
        },
        {
            path: '/therapy',
            name: 'route_therapy',
            component: TherapyPage,
            meta: {
                public: false,
                onlyWhenLoggedOut: false,
            }
        },
        {
            path: '/subscription',
            name: 'route_subscription',
            component: SubscriptionPage,
            meta: {
                public: false,
                onlyWhenLoggedOut: false,
            }
        },
         {
            path: '/therapist',
            name: 'route_therapist',
            component: TherapistPage,
            meta: {
                public: false,
                onlyWhenLoggedOut: false,
            }
        },
        {
            path: '/register',
            name: 'route_register',
            component: RegisterPage,
            meta: {
                public: true,  // Allow access to even if not logged in
                onlyWhenLoggedOut: true,
                top: false
            }
        },
        {
            path: '/login',
            name: 'route_login',
            component: LoginPage,
            meta: {
                public: true,  // Allow access to even if not logged in
                onlyWhenLoggedOut: true,
                top: false
            }
        },
        {
            path: '/reset-password/:hash',
            name: 'route_reset_password',
            component: ResetPasswordPage,
            meta: {
                public: true,  // Allow access to even if not logged in
                onlyWhenLoggedOut: true,
                top: false
            }
        },
        // {
        //     path: '/*',
        //     name: 'route_error404',
        //     component: Error404,
        //     meta: {
        //         public: true
        //     }
        // },

        // {
        //     path: '/about',
        //     name: 'about',
        //     // route level code-splitting
        //     // this generates a separate chunk (about.[hash].js) for this route
        //     // which is lazy-loaded when the route is visited.
        //     component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
        // },
    ]
});

router.beforeResolve((to, from, next) => {
    next();
});

// router.afterEach((to, from) => {
//     // Complete the animation of the route progress bar.
//     // store.commit('setInitialized', true);
// });

router.beforeEach((to, from, next) => {
    let loggedIn = !!localStorage.getItem('token');

    if (loggedIn) {
        if (to.meta.onlyWhenLoggedOut !== undefined && to.meta.onlyWhenLoggedOut === true) {
            return next('/consultation');
        }
    }
    else {
        if ((to.meta === undefined || to.meta.public === undefined)) {
            return next('/login');
        }

        if (to.meta.public === false) {
            return next('/login');
        }
    }

    next();
});


export default router;
