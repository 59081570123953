import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default {
    // namespaced: true,
    state: {
        initialized: false,
        config: {
            i18n: {},
            store: {
                currencyConfig: {},
            },
        }
    },
    getters: {
        getCurrencyConfig: (store) => {
            return store.config.store.currencyConfig;
        },
    },
    mutations: {
        setConfig: (store, config) => {
            store.config = config;
        }
    }
};