<template>
    <div class="text-center recorder" :class="{'full-width': fullWidth, 'overlay': !fullWidth}"
         v-if="this.getRecorderState()" v-click-outside="closeRecorder">
        <div>
            <v-col>
                <v-row>
                    <v-col class="text-right pa-0" style="padding-top:2px !important;">
                        <img v-if="this.getRecording()" :src="require('../../assets/recorder/record_icon.png')"
                             :alt="$t('record_in_progress')">
                    </v-col>
                    <v-col class="pa-1" cols="auto">
                        {{ this.formatTimeToTimer(this.getRecorderLengthSeconds()) }}
                    </v-col>
                    <v-col class="pa-1">
<!--                        <span v-if="this.getRecording()" @click="cancelRecording()">{{ $t('record_cancel') }}</span>-->
                    </v-col>
                </v-row>
                <div v-if="this.getRecording()" @click="stopRecording()"
                     style="cursor:pointer;margin-left:auto;margin-right:auto;width:90px;height:90px;background-color:#000;border-radius:90px;color:#fff;margin-top:10px;">
                    <img :src="require('../../assets/recorder/recorder_active.png')" :alt="$t('record_in_progress')">
                </div>
                <div v-else @click="startRecording()"
                     style="cursor: pointer;margin-left:auto;margin-right:auto;width:90px;height:90px;background-color:#000;border-radius:90px;color:#fff;margin-top:10px;padding-top:34px;">
                    {{ $t('start_recording')|capitalize }}
                </div>
                <div style="margin-top:10px;">
                    {{ $t('record_maximum_x_minutes', {minutes: this.getRecorderLimitSeconds() / 60}) }}
                </div>
            </v-col>
        </div>
    </div>
</template>

<script>
import DateTimeMixin from "@/mixins/DateTimeMixin";
import {mapActions, mapGetters, mapMutations} from 'vuex';

export default {
    name: "ChatAudioRecorder",
    props: {
        fullWidth: {
            type: Boolean,
            required: true
        }
    },
    methods: {
        ...mapGetters([
            'getRecorderRecordStartAt',
            'getRecorderLengthSeconds',
            'getRecorderLimitSeconds',
            'getRecorderState',
            'getRecording',
        ]),
        ...mapMutations([
            'setRecorderRecordStartAt',
            'setRecorderLengthSeconds',
            'setRecorderTimer',
        ]),
        ...mapActions([
            'closeRecorder',
            'cancelRecording',
            'startRecording',
            'stopRecording',
        ])
    },
    mixins: [
        DateTimeMixin,
    ],
}
</script>

<style scoped>
.recorder.full-width {
    height: 190px;
    width: 100%;
    color: #999;
    background-color: #f2f2f2;
    border-top: 1px solid #000;
    z-index: 1000;
}

.recorder.overlay {
    position: absolute;
    height: 200px;
    width: 220px;
    left: 7px;
    bottom: 53px;
    color: #999;
    /* drop shadow (https://css-tricks.com/using-box-shadows-and-clip-path-together/) */
    filter: drop-shadow(0 0 10px #ddd);
    z-index: 1000;
}

/* emulate border on clip rect */
.recorder.overlay > div {
    height: 200px;
    background-color: #dbdbdb;
    clip-path: polygon(0 0, 220px 0, 220px 190px, 30px 190px, 20px 200px, 10px 190px, 0 190px);
}

/* inner rect is 1 pixel smaller */
.recorder.overlay > div > .col {
    height: 200px;
    background-color: #fff;
    clip-path: polygon(1px 1px, 219px 1px, 219px 189px, 29px 189px, 20px 199px, 11px 189px, 1px 189px);
}
</style>
