import Repository from '../Repository';
import {buildHeaders} from '../Headers';
import dayjs from "dayjs";

const ENDPOINT = '/api/sessions';

export default {
    getById(id) {
        return Repository
            .get(`${ENDPOINT}/${id}`, {
                headers: buildHeaders()
            })
            .then((response) => {
                if (response.status === 200) {
                    response.data['date'] = dayjs(response.data['date']);
                    return response.data;
                }
                return null;
            })
    },
    accept(id) {
        return Repository
            .post(`${ENDPOINT}/${id}/acceptance`, {},{
                headers: buildHeaders()
            })
            .then((response) => {
                return response;
            });
    },
    cancel(id) {
        return Repository
            .delete(`${ENDPOINT}/${id}`, {
                headers: buildHeaders()
            });
    },
    getSessions(from) {
        return Repository
            .get(`${ENDPOINT}?from=${from}`, {
                headers: buildHeaders()
            })
            .then((response) => {
                return response.data;
            });
    },
    getSessionTypes() {
        return Repository
            .get('/api/session-types', {
                headers: buildHeaders()
            })
            .then((response) => {
                return response.data;
            });
    }
}
