import Vue from 'vue';
import Vuex, {Module} from 'vuex';
import dayjs from "dayjs";
import ChatChannelRepository from "@/repository/Resource/ChatChannelRepository";
import StorageRepository from "@/repository/Resource/StorageRepository";
import UIEvents from "@/service/store/UIEvents";
import {messageDelivered} from "@/service";
import {MessageQueue} from "@/service/store/Module/SendingMessageStore";

Vue.use(Vuex);

const getDefaultState = () => {
    return {
        socket: {
            isConnected: true,
            message: '',
            reconnecting: false,
            reconnectError: false,
        },

        members: [],
        currentChatChannelId: null,
        messagesByChatChannel: [],
        notSent: [],
        chatRecipient: null,
        chatRecipientAvatar: null,

        sending: false,
        timeout: null,
    }
}

const getNewNotSent = (channelId) => {
    return {
        channelId: channelId,
        message: '',
        resources: [],
    }
}

export default {
    state: getDefaultState(),
    getters: {
        getCurrentChatChannelId: function (state) {
            return state.currentChatChannelId;
        },
        getChatMessages: (state) => (channelId) => {
            const channel = state.messagesByChatChannel.find((channel) => {
                return channel.id === channelId;
            }) ?? {messages: [], id: channelId};

            return channel.messages;
        },
        getNotSentResources: (state) => (channelId) => {
            let notSent = state.notSent.find((notSent) => {
                return notSent.channelId === channelId;
            });

            if (!notSent) {
                return [];
            }

            return notSent.resources;
        },
        getNotSentMessage: (state) => (channelId) => {
            let notSent = state.notSent.find((notSent) => {
                return notSent.channelId === channelId;
            });

            if (!notSent) {
                return '';
            }

            return notSent.message;
        },
        getSocket: (state) => state.socket,
        getChatMembers: (state) => {
            return state.members;
        },
        getChatMember: (state) => (memberId) => {
            return state.members.find(m => m.id === memberId);
        },
        getActiveChatRecipient(state) {
            return state.chatRecipient;
        },
        getActiveChatRecipientAvatar: (state) => {
            if (state.chatRecipientAvatar) {
                return 'data:image/jpeg;base64,' + state.chatRecipientAvatar;
            }
            return '/img/default-avatar-300x300.png';
        },
        isSending: (state) => {
            return state.sending;
        },
    },
    mutations: {
        setActiveChatRecipient(state, user) {
            state.chatRecipient = user;
        },
        setActiveChatRecipientAvatar(state, avatar) {
            state.chatRecipientAvatar = avatar;
        },
        resetActiveChat(state) {
            state.currentChatChannelId = null;
        },
        SOCKET_ONOPEN(state, event) {
            Vue.prototype.$socket = event.currentTarget
            state.socket.isConnected = true
            state.socket.reconnecting = false
            state.socket.reconnectError = false
            UIEvents.updated();
            console.log('SOCKET_ONOPEN');
            MessageQueue.resume();
        },
        SOCKET_ONCLOSE(state) {
            console.log('SOCKET_ONCLOSE');
            state.socket.reconnecting = false
            state.socket.isConnected = false
            MessageQueue.pause();
        },
        SOCKET_ONERROR(state, event) {
            console.error('SOCKET_ONERROR', state, event)
            state.socket.isConnected = false
            MessageQueue.pause();
        },
        // default handler called for all methods
        SOCKET_ONMESSAGE(state, message) {
            if (message) {
                let messageType = 'chat-message';
                if (typeof message.message_type !== 'undefined') {
                    messageType = message.message_type;
                }
                if (messageType === 'chat-message') {
                    if (message.sending_id) {
                        messageDelivered(message.sending_id);
                    }
                    this.commit('ADD_MESSAGES', [message]);
                    // this.commit('setSending', false);
                    this.commit('resetNotSentMessageAndResources', state.currentChatChannelId);
                } else if (messageType === 'state-updated') {
                    UIEvents.updated();
                } else if (messageType === 'video-offer') {
                    this.commit('therapySession/acceptInvitation', message.sdp);
                } else if (messageType === 'video-answer') {
                    this.commit('therapySession/startChat', message.sdp);
                } else if (messageType === 'new-ice-candidate') {
                    this.commit('therapySession/addIceCandidate', message.candidate);
                } else if (messageType === 'hang-up') {
                    this.commit('therapySession/remoteHangedUp');
                } else if (messageType === 'turn') {
                    this.commit('therapySession/setTurnCredentials', message.credentials);
                }
            }
        },
        // mutations for reconnect methods
        SOCKET_RECONNECT(state, count) {
            console.log(`reconnect ${count}`)
            state.socket.isConnected = false
            state.socket.reconnecting = true
            state.socket.reconnectError = false
        },
        SOCKET_RECONNECT_ERROR(state) {
            state.socket.reconnectError = true;
        },
        SET_MESSAGES(state, messages) {
            messages
                .forEach((message) => {
                    let index = state.messagesByChatChannel.findIndex((channel) => {
                        return channel.id === message.channel_id;
                    });

                    if (-1 !== index) { // replace
                        let messageIndex = state.messagesByChatChannel[index].messages.findIndex((m) => {
                            return m.id === message.id;
                        })

                        if (-1 === messageIndex) {
                            state.messagesByChatChannel[index].messages.push(message);
                        } else {
                            state.messagesByChatChannel[index].messages[messageIndex] = message;
                        }
                    } else {
                        const channel = {
                            id: message.channel_id,
                            messages: [message],
                        };

                        state.messagesByChatChannel.push(channel);
                    }
                });

        },
        addNotSentResource(state, resource) {
            const channelId = state.currentChatChannelId;
            let notSentIndex = state.notSent.findIndex((notSent) => {
                return notSent.channelId === channelId;
            });

            if (notSentIndex === -1) {
                let notSent = getNewNotSent(channelId);
                notSentIndex = state.notSent.push(notSent) - 1;
            }

            state.notSent[notSentIndex].resources.push(resource);
            localStorage.setItem(`messages/${channelId}`, JSON.stringify(state.notSent[notSentIndex]));
        },
        setNotSentMessage(state, payload) {
            let notSent = state.notSent.find((notSent) => {
                return notSent.channelId === payload.channelId;
            });

            if (!notSent) {
                notSent = getNewNotSent(payload.channelId);
                state.notSent.push(notSent);
            }

            notSent.message = payload.message;
            localStorage.setItem(`messages/${payload.channelId}`, JSON.stringify(notSent));
        },
        removeNotSentResource(state, payload) {
            let notSentIndex = state.notSent.findIndex((notSent) => {
                return notSent.channelId === payload.channelId;
            });

            if (notSentIndex === -1) {
                console.warn('Unable to find channelId `' + payload.channelId + '`');
                return false;
            }

            let resourceIndex = state.notSent[notSentIndex].resources.findIndex((resource) => {
                return resource.id === payload.storageId;
            })

            if (resourceIndex === -1) {
                console.warn('Unable to find resource `' + payload.storageId + '`');
                return false;
            }

            state.notSent[notSentIndex].resources.splice(resourceIndex, 1);
            localStorage.setItem(`messages/${payload.channelId}`, JSON.stringify(state.notSent[notSentIndex]));
        },
        resetNotSentMessageAndResources(state, channelId) {
            let notSentIndex = state.notSent.findIndex((notSent) => {
                return notSent.channelId === channelId;
            })
            // todo: remove resources from storage
            state.notSent.splice(notSentIndex, 1);
            localStorage.setItem(`messages/${channelId}`, "{}");
        },
        ADD_MEMBERS(state, members) {
            members.forEach((member) => {
                const existing = state.members.find((m) => {
                    return m.id === member.id;
                });

                if (!existing) {
                    state.members.push(member);
                }
            })
        },
        RESET_MEMBERS(state) {
            state.members = [];
        },
        SET_CURRENT_CHAT_CHANNEL_ID(state, chatChannelId) {
            state.currentChatChannelId = chatChannelId;
        },
        resetChatStore(state) {
            Object.assign(state, getDefaultState());
        },
        addMessage(state, payload) {
            state.notSent.push(payload);
        },
        // setSending(state, sending) {
        //     state.sending = sending;
        //     if (sending === false) {
        //         clearTimeout(state.timeout);
        //         state.timeout = null;
        //     }
        // },
        // startSendingTimeout(state, callback) {
        //     state.timeout = setTimeout(callback, 30000);
        // },
        // this mutation is used when message is retrieved from WebSocket
        // TODO should be the first thing to refactor
        ADD_MESSAGES(state, messages) {
            messages
                .forEach((message) => {
                    let index = state.messagesByChatChannel.findIndex((channel) => {
                        return channel.id === message.channel_id;
                    });

                    if (-1 === index) {
                        index = state.messagesByChatChannel.push({
                            id: message.channel_id,
                            messages: [],
                        }) - 1;
                    }

                    state.messagesByChatChannel[index].messages.push(message);

                    let user = this.getters.getUser;
                    let newUser = {...user};
                    if (user.consultation && user.consultation.chat_channel === message.channel_id) {
                        newUser.consultation = {
                            ...user.consultation,
                            unread: message.channel_id !== state.currentChatChannelId,
                            last_message: message.body,
                            last_message_date: message.created_at,
                            last_message_author: message.member_id,
                            last_message_author_name: user.consultation.user.firstname,
                        }
                    }
                    if (user.active_therapy && user.active_therapy.chat_channel === message.channel_id) {
                        newUser.active_therapy = {
                            ...user.active_therapy,
                            unread: message.channel_id !== state.currentChatChannelId,
                            last_message: message.body,
                            last_message_date: message.created_at,
                            last_message_author: message.member_id,
                            last_message_author_name: user.active_therapy.user.firstname,
                        }
                    }
                    this.commit('setUser', newUser);
                    this.commit('user/setUser', newUser);

                    this.commit('therapist_chat/setChats', this.getters['therapist_chat/chats']
                        .map((therapy) => {
                            if (therapy.chat_channel === message.channel_id) {
                                return {
                                    ...therapy,
                                    unread: message.channel_id !== state.currentChatChannelId,
                                    last_message: message.body,
                                    last_message_date: message.created_at,
                                    last_message_author: message.member_id,
                                    last_message_author_name: this.getters.getChatMember(message.member_id).firstname,
                                }
                            }
                            return therapy;
                        }).sort((therapy1, therapy2) => {
                            return therapy1.last_message_date > therapy2.last_message_date ? -1 : 1;
                        }));

                    // if other user has this chat opened, he must send information to server
                    // (but probably after he really displayed the chat, not in background)
                    // TODO mark as read on chat page when any user action happened
                    if (message.channel_id === state.currentChatChannelId
                        && message.member_id !== user.id) {
                        ChatChannelRepository.markAsRead(message.channel_id);
                    }
                });
        },
        MARK_AS_READ(state, channelId) {
            ChatChannelRepository.markAsRead(channelId)
                .then(() => {
                    let user = this.getters.getUser;
                    if (user.isAPatient()) {
                        let newUser = {...user};
                        if (user.consultation && user.consultation.chat_channel === channelId) {
                            newUser.consultation = {
                                ...user.consultation,
                                unread: false
                            }
                        }
                        if (user.active_therapy && user.active_therapy.chat_channel === channelId) {
                            newUser.active_therapy = {
                                ...user.active_therapy,
                                unread: false
                            }
                        }
                        this.commit('setUser', newUser);
                        this.commit('user/setUser', newUser);
                    } else {
                        this.commit('therapist_chat/markAsRead', channelId);
                    }
                });
        },
    },
    actions: {
        loadCurrentChatMessages: (context) => {
            if (context.getters.getCurrentChatChannelId === null) {
                return;
            }
            ChatChannelRepository.getOneById(context.getters.getCurrentChatChannelId)
                .then(result => {
                    if (result) {
                        context.commit('SET_MESSAGES', result.chat_messages);
                    }
                })
        },
        loadChatChannel: (context, channelId) => {
            const payload = JSON.parse(localStorage.getItem(`messages/${channelId}`) || '{}');
            const notSentIndex = context.state.notSent.findIndex((notSent) => notSent.channelId === channelId);
            if (payload != null && notSentIndex === -1) {
                context.commit('addMessage', payload);
            }

            context.commit('SET_CURRENT_CHAT_CHANNEL_ID', channelId);
            context.commit('setActiveChatRecipient', null);
            context.commit('setActiveChatRecipientAvatar', null);
            ChatChannelRepository
                .getOneById(channelId)
                .then(result => {
                    if (result) {
                        context.commit('ADD_MEMBERS', result.chat_members);
                        context.commit('SET_MESSAGES', result.chat_messages);
                        if (context.getters.getUser.isAPatient()) {
                            const recipient = result.chat_members
                                .find((member) => member.therapist || member.consultant);
                            const avatar = recipient.therapist ? recipient.therapist.avatar : recipient.consultant.avatar;
                            context.commit('setActiveChatRecipient', {
                                name: (recipient.therapist ? recipient.therapist.title + ' ' : '') + recipient.firstname + ' ' + recipient.lastname,
                                description: recipient.therapist ? recipient.therapist.bio : recipient.consultant.bio,
                                avatar: avatar
                            });
                            if (avatar) {
                                StorageRepository
                                    .preview(avatar)
                                    .then(response => {
                                        if (response.status === 200) {
                                            context.commit('setActiveChatRecipientAvatar', response.data);
                                        }
                                    });
                            }
                        } else {
                            const recipient = result.chat_members
                                .find((member) => !member.therapist && !member.consultant);
                            let description = '';
                            if (recipient.active_subscription) {
                                const plan = recipient.active_subscription.plan.name;
                                const sessionsLeft = recipient.active_subscription.sessions_left;
                                const subscriptionEnd = dayjs(recipient.active_subscription.current_period_end).format('D MMMM');
                                description = `${plan}, ważny do ${subscriptionEnd}, pozostało sesji: ${sessionsLeft}`;
                                if (recipient.nearest_session) {
                                    description = `${description}, najbliższa sesja: ${dayjs(recipient.nearest_session).format('dddd, D MMMM, HH:mm')}`;
                                }
                            } else {
                                description = 'Brak wykupionego abonamentu';
                            }
                            context.commit('setActiveChatRecipient', {
                                name: recipient.firstname + ' ' + recipient.lastname,
                                description: description
                            });
                        }
                        context.commit('MARK_AS_READ', channelId);
                    }
                });
        },
        sendStateUpdatedMessage: function(context, chat_channel) {
            const payload = {
                message_type: 'state-updated',
                chat_channel: chat_channel,
                token: localStorage.getItem('token')
            };
            Vue.prototype.$socket.send(JSON.stringify(payload));
        }
    }
}
