import axios from 'axios';
import config from '../config/config';
// import https from 'https';
import { buildHeaders } from './Headers';
// import Router from '../service/Router';


// let CancelToken = axios.CancelToken;
// let cancel;

let api = axios.create({
    baseURL: config.API_BASE_URL,
    // httpsAgent: new https.Agent({
    //     rejectUnauthorized: true // todo for development
    // }),
    // withCredentials: true,
    headers: buildHeaders(),
    // cancelToken: new CancelToken(function executor(c) {
    //     cancel = c;
    // }),
});

// api.interceptors.response.use(response => {
//     return response;
// }, error => {
//     if (error.response.status === 401) {
//         store.dispatch('doLogout');
//         // Promise.reject(error.response || error.message);
//     }
//     Promise.reject(error);
// });

api.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error.response?.status === 401) {
        localStorage.removeItem('token');
    }
    return Promise.resolve(error.response);
});

export default api;
