import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default {
    state: {
        alert: {
            visibility: false,
            type: 'success',
            title: null,
            message: null,
        },
        snackbar: {
            visible: false,
            displayed: false,
            message: null,
            showTimeout: null,
            hideTimeout: null,
            removeTimeout: null
        },
        createDirectoryModal: {
            visibility: false,
            directory: null,
        },
        createSchemaModalVisibility: false,
        createSchemaModalDirectoryId: null,
        createProjectModalVisibility: false,
        createDirectoryModalVisibility: false,
        createDirectoryModalDirectoryId: null,
        deleteProjectModalVisibility: false,
        deleteProjectModalProject: null,
        globalLoaderVisibility: false,
        deleteSuiteTestModalVisibility: false,
        deleteSuiteTestModalSuiteTest: null,
        windowWidth: null,
        windowHeight: null,
        bottomPanelHeight: null
    },
    getters: {
        getWindowWidth: (state) => {
            return state.windowWidth;
        },
        getWindowHeight: (state) => {
            return state.windowHeight;
        },
        getBottomPanelHeight: (state) => {
            return state.bottomPanelHeight;
        },
        isAlertVisible: (state) => {
            return state.alert.visibility;
        },
        getAlertType: (state) => {
            return state.alert.type;
        },
        getAlertTitle: (state) => {
            return state.alert.title;
        },
        getAlertMessage: (state) => {
            return state.alert.message;
        },
        isSnackbarVisible: (state) => {
            return state.snackbar.visible;
        },
        isSnackbarDisplayed: (state) => {
            return state.snackbar.displayed;
        },
        getSnackbarMessage: (state) => {
            return state.snackbar.message
        },
        isCreateSchemaModalVisible: (state) => {
            return state.createSchemaModalVisibility;
        },
        getCreateSchemaModalDirectoryId: (state) => {
            return state.createSchemaModalDirectoryId;
        },

        isCreateProjectModalVisible: (state) => {
            return state.createProjectModalVisibility;
        },

        isCreateDirectoryModalVisible: (state) => {
            return state.createDirectoryModal.visibility;
        },
        getCreateDirectoryModalDirectory: (state) => {
            return state.createDirectoryModal.directory;
        },

        isGlobalLoaderVisible: (state) => {
            return state.globalLoaderVisibility;
        },

        isDeleteProjectModalVisible: (state) => {
            return state.deleteProjectModalVisibility;
        },
        getDeleteProjectModalProject: (state) => {
            return state.deleteProjectModalProject;
        },

        isDeleteSuiteTestModalVisible: (state) => {
            return state.deleteSuiteTestModalVisibility;
        },
        getDeleteSuiteTestModalSuiteTest: (state) => {
            return state.deleteSuiteTestModalSuiteTest;
        },
    },
    actions: {
        toggleFullScreen() {
            const doc = window.document;
            const docEl = doc.documentElement;

            const requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen;
            const cancelFullScreen = doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen;

            if(!doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement) {
                requestFullScreen.call(docEl);
            } else {
                cancelFullScreen.call(doc);
            }
        },

        closeCreateSchemaModal(state) {
            state.commit('toggleCreateSchemaModal', false);
        },
        openCreateSchemaModal(state, directory) {
            state.commit('toggleCreateSchemaModal', true);
            state.commit('setCreateSchemaModalDirectoryId', directory ? directory.id : null);
        },

        closeCreateProjectModal(state) {
            state.commit('toggleCreateProjectModal', false);
        },
        openCreateProjectModal(state) {
            state.commit('toggleCreateProjectModal', true);
        },

        openDeleteProjectModal(state, project) {
            state.commit('TOGGLE_DELETE_PROJECT_MODAL', true);
            state.commit('SET_DELETE_PROJECT', project);
        },
        closeDeleteProjectModal(state) {
            state.commit('TOGGLE_DELETE_PROJECT_MODAL', false);
            state.commit('SET_DELETE_PROJECT', null);
        },

        openDeleteSuiteTestModal(state, suiteTest) {
            state.commit('TOGGLE_DELETE_SUITE_TEST_MODAL', true);
            state.commit('SET_DELETE_SUITE_TEST', suiteTest);
        },
        closeDeleteSuiteTestModal(state) {
            state.commit('TOGGLE_DELETE_SUITE_TEST_MODAL', false);
            state.commit('SET_DELETE_SUITE_TEST', null);
        },

        closeCreateDirectoryModal(state) {
            state.commit('toggleCreateDirectoryModal', false);
        },
        openCreateDirectoryModal(state, directory) {
            state.commit('toggleCreateDirectoryModal', true);
            state.commit('setCreateDirectoryModalDirectory', directory ? directory : null);
        },

        showGlobalLoader(state) {
            state.commit('TOGGLE_GLOBAL_LOADER', true);
        },
        hideGlobalLoader(state) {
            state.commit('TOGGLE_GLOBAL_LOADER', false);
        },

    },
    mutations: {
        toggleCreateSchemaModal(state, visibility) {
            state.createSchemaModalVisibility = !!visibility;
        },
        setCreateSchemaModalDirectoryId(state, directoryId) {
            state.createSchemaModalDirectoryId = directoryId;
        },

        toggleCreateProjectModal(state, visibility) {
            state.createProjectModalVisibility = !!visibility;
        },

        toggleCreateDirectoryModal(state, visibility) {
            state.createDirectoryModal.visibility = !!visibility;
        },
        setCreateDirectoryModalDirectory(state, directory) {
            state.createDirectoryModal.directory = directory;
        },

        TOGGLE_GLOBAL_LOADER(state, toggle) {
            state.globalLoaderVisibility = toggle;
        },

        TOGGLE_DELETE_PROJECT_MODAL(state, toggle) {
            state.deleteProjectModalVisibility = toggle;
        },
        SET_DELETE_PROJECT(state, project) {
            state.deleteProjectModalProject = project;
        },

        TOGGLE_DELETE_SUITE_TEST_MODAL(state, toggle) {
            state.deleteSuiteTestModalVisibility = toggle;
        },
        SET_DELETE_SUITE_TEST(state, suiteTest) {
            state.deleteSuiteTestModalSuiteTest = suiteTest;
        },
        alertShow(state, data) {
            Object.assign(state.alert, {
                visibility: true,
                type: typeof data.type !== 'undefined' ? data.type : 'success',
                title: typeof data.title !== 'undefined' ? data.title : null,
                message: typeof data.message !== 'undefined' ? data.message : null,
            });
        },
        alertHide(state) {
            Object.assign(state.alert, {
                visibility: false,
                type: 'success',
                title: null,
                message: null,
            });
        },

        snackbarShow(state, message) {
            clearTimeout(state.snackbar.showTimeout);
            clearTimeout(state.snackbar.hideTimeout);
            clearTimeout(state.snackbar.removeTimeout);
            Object.assign(state.snackbar, {
                displayed: true,
                message: message,
                hideTimeout: setTimeout(() => {
                    state.snackbar.visible = false;
                    state.snackbar.removeTimeout = setTimeout(() => state.snackbar.displayed = false, 600);
                }, 5000)
            })
            state.snackbar.showTimeout = setTimeout(() => state.snackbar.visible = true, 10);
        },
        snackbarHide(state) {
            state.snackbar.visibility = false;
        },

        setWindowWidth(state, width) {
            state.windowWidth = width;
        },
        setWindowHeight(state, height) {
            state.windowHeight = height;
        },
        setBottomPanelHeight(state, height) {
            state.bottomPanelHeight = height;
        }
    },
};
