import {Module, Mutation, VuexModule} from "vuex-module-decorators";
import {ChatChannelId, UserId} from "@/service/store/Module/domain";
import UserRepository from "@/repository/Resource/UserRepository";
import {MediaConnection, Peer} from 'peerjs';

const iceServerUrl = process.env.VUE_APP_ICE_SERVER || 'turn:3.66.97.168:3478';

interface TurnCredentials {
    username: string;
    password: string;
}

/**
 * Manage video session using peerjs library.
 */
@Module({namespaced: true, name: 'therapySession'})
export class TherapySessionStore extends VuexModule {

    private _showVideoChat = false;
    private _muted = false;
    private _videoStopped = false;

    private _newChatEnabled = false;

    private _localStream: MediaStream | null = null;
    private _remoteStream: MediaStream | null = null;

    private turnCredentials: TurnCredentials | null = null;

    private peer: Peer | null = null;
    private call: MediaConnection | null = null;

    get newChatEnabled(): boolean {
        return this._newChatEnabled;
    }

    get videoChatStarted(): boolean {
        return this._showVideoChat;
    }

    get localStream(): MediaStream | null {
        return this._localStream;
    }

    get remoteStream(): MediaStream | null {
        return this._remoteStream;
    }

    get muted(): boolean {
        return this._muted;
    }

    get videoStopped(): boolean {
        return this._videoStopped;
    }

    @Mutation
    public setLocalStream(stream: MediaStream) {
        this._localStream = stream;
    }

    @Mutation
    public setRemoteStream(stream: MediaStream) {
        this._remoteStream = stream;
    }

    @Mutation
    public setTurnCredentials(credentials: TurnCredentials) {
        this.turnCredentials = credentials;
    }

    @Mutation
    async invite({localUserId, remoteUserId}: {localUserId: UserId, remoteUserId: UserId}) {
        // ensure peer
        if (this.peer == null) {
            this.peer = new Peer(localUserId, {
                host: process.env.VUE_APP_CHAT_HOST ?? 'chat.mojterapeuta.app',
                port: process.env.VUE_APP_CHAT_PORT ?? 443,
                path: '/video',
                secure: process.env.VUE_APP_CHAT_SECURE ?? true,
                config: {
                    iceServers: [{
                        urls: iceServerUrl,
                        username: this.turnCredentials?.username,
                        credential: this.turnCredentials?.password
                    }]
                }
            });
        }

        const stream = await navigator.mediaDevices.getUserMedia({video: true, audio: true});
        this._localStream = stream;
        this._showVideoChat = true;

        this.peer!.on('call', call => {
            this.call?.close();
            this.call = call;
            this.call.on('stream', remoteStream => {
                this._remoteStream = remoteStream;
            }).on('close', () => {
                this._remoteStream?.getTracks().forEach(t => t.stop());
                this._remoteStream = null;
            });
            this.call.answer(stream);
        });

        this.call?.close();
        this.call = this.peer!.call(remoteUserId, stream);
        this.call.on('stream', remoteStream => {
            this._remoteStream = remoteStream;
        });
    }

    @Mutation
    public toggleMute() {
        this._muted = !this._muted;
        this._localStream?.getAudioTracks().forEach(t => t.enabled = !this._muted);
    }

    @Mutation
    public toggleVideoStopped() {
        this._videoStopped = !this._videoStopped;
        this._localStream?.getVideoTracks().forEach(t => t.enabled = !this._videoStopped);
    }

    @Mutation
    public hangUp() {
        this.call?.close();
        this.peer?.disconnect();
        this.peer = null;
        this._localStream?.getTracks().forEach(t => t.stop());
        this._localStream = null;
        this._remoteStream?.getTracks().forEach(t => t.stop());
        this._remoteStream = null;
        this._showVideoChat = false;
        this._muted = false;
        this._videoStopped = false;
    }

    /**
     * This is executed when nearest therapy session is loaded.
     */
    @Mutation
    public async checkNewChat(chatChannelId: ChatChannelId) {
        this._newChatEnabled = await UserRepository.isNewChatEnabled(chatChannelId);
    }
}
