// noinspection JSUnresolvedFunction - Stripe is loaded dynamically

import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default {
    state: {
        therapist: null,
        stripe: null,
        stripeLoading: false
    },
    getters: {
        getCheckoutTherapist: (state) => {
            return state.therapist;
        },
        getStripe: (state) => {
            return state.stripe;
        }
    },
    mutations: {
        setCheckoutTherapist: (state, therapist) => {
            state.therapist = therapist;
        }
    },
    actions: {
        checkout(context, sessionId) {
            if (context.state.stripe) {
                context.state.stripe.redirectToCheckout({
                    sessionId: sessionId
                });
            }
        },
        initPaymentGateway(context) {
            if (!context.state.stripe && !context.state.stripeLoading) {
                context.state.stripeLoading = true;
                let documentTag = document, tag = 'script',
                    object = documentTag.createElement(tag),
                    scriptTag = documentTag.getElementsByTagName(tag)[0];
                object.src = 'https://js.stripe.com/v3/';
                object.addEventListener('load', function () {
                    // eslint-disable-next-line no-undef
                    context.state.stripe = Stripe(process.env.VUE_APP_STRIPE_KEY);
                    context.state.stripeLoading = false;
                }, false);
                object.addEventListener('error', function () {
                    context.state.stripeLoading = false;
                }, false);
                scriptTag.parentNode.insertBefore(object, scriptTag);
            }
        }
    }
}
