<template>
    <div style="text-align: center; margin: 50px 0 20px;">
        <div class="image"><img :src="require('../../assets/logo.png')"></div>
        <div class="header">Porozmawiajmy na spokojnie</div>
        <div class="text" v-if="isConsultation">Konsultacja pomoże dobrać najbardziej odowiedniego terapeutę i plan abonamentowy
            do Twoich potrzeb i przekieruje Cię do rozpoczęcia terapii</div>
        <div class="text" v-if="isDailyMessage">Na czacie możesz rozmawiać z terapeutą, codziennie dostaniesz 1-2 wiadomości.
            Tutaj tez umówisz spotkanie tekstowe lub wideo. </div>
        <div class="text shine darker" v-if="!loaded">&nbsp;</div>
        <div class="text shine darker" v-if="!loaded">&nbsp;</div>
    </div>
</template>

<script>
export default {
    name: "WelcomeMessage",
    props: {
        "loaded": {
            type: Boolean,
            required: true
        },
        "isConsultation": {
            type: Boolean
        },
        "isDailyMessage": {
            type: Boolean
        }
    }
}
</script>

<style scoped>
.header {
    padding: 20px 0;
    margin: 0;
    font-size: 32px;
    font-weight: 500;
}
.text {
    font-size: 14px;
    line-height: 18px;
    max-width: 500px;
    margin: 0 auto;
}
.text.shine {
    height: 16px;
    margin-bottom: 2px;
    width: 100%;
}
</style>
