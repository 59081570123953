export function scrollToElement(id) {
    const element = document.getElementById(id);
    const elementRect = element.getBoundingClientRect();
    const absoluteElementTop = elementRect.top + window.pageYOffset;
    window.scrollTo({
        left: 0,
        top: absoluteElementTop,
        behavior: 'smooth'
    });
    setTimeout(() => window.location.hash = id, 500);
}
