<template>
    <div style="display: inline-block; max-width: 460px; width: 100%;">
        <v-row class="card align-center pa-2" style="margin: 15px 0;">
            <v-col style="margin-right: 20px;">
                <div v-if="session" class="date">{{ session.date.format('dddd, D MMMM, [godz.] HH:mm') }}<br>({{ session.duration }} minut)</div>
                <div v-else class="date shine"></div>
            </v-col>
            <v-col cols="auto" v-if="this.getUser() && (!session || session.status !== 'active')">
                <Button :disabled="!session || !this.getUser().isAPatient()" placeholder="Umów sesję" @click.native="openDialog"/>
            </v-col>
            <v-col cols="auto" v-else style="line-height: 41px;">
                Sesja opłacona
            </v-col>
        </v-row>
        <v-dialog v-model="confirmDialog" max-width="500">
            <ConfirmationDialog title="Czy chcesz umówić sesję?"
                                :text="confirmationText"
                                confirm-text="Umów sesję"
                                confirm-icon="calendar-check"
                                :action="arrange"
                                :loading="isBeingArranged"
                                :finished="isArranged"
                                @hide="confirmDialog = false"/>
        </v-dialog>
    </div>
</template>

<script>
import dayjs from "dayjs";
import Button from "@/components/Form/Button";
import {mapActions, mapGetters, mapMutations} from "vuex";
import ConfirmationDialog from "@/components/Common/ConfirmationDialog";
import SessionRepository from "@/repository/Resource/SessionRepository";

dayjs.locale(require('dayjs/locale/pl'));

const CONFIRM_FROM_SUBSCRIPTION = 'Sesja zostanie umówiona w ramach abonamentu.';
const CONFIRM_SINGLE_SESSION = 'W celu umówienia sesji zostaniesz przeniesiony do strony płatności.';

export default {
    name: "ArrangeSession",
    components: {ConfirmationDialog, Button},
    data: function () {
        return {
            sessionRepository: SessionRepository,
            session: null,
            confirmDialog: false,
            isBeingArranged: false,
            isArranged: false
        };
    },
    props: ['id'],
    mounted() {
        this.initPaymentGateway();
        this.sessionRepository.getById(this.id)
            .then(session => this.session = session);
    },
    computed: {
        confirmationText() {
            const hasSubscription = this.getUser() != null && this.getUser().active_subscription != null;
            if (!hasSubscription) {
                return CONFIRM_SINGLE_SESSION;
            }
            const duration = this.session ? this.session.duration : 30;
            const subscriptionDuration = this.getUser().active_subscription.session_duration || 30;
            const sessionsLeft = this.getUser().active_subscription.sessions_left;
            if (duration === subscriptionDuration && sessionsLeft > 0) {
                return CONFIRM_FROM_SUBSCRIPTION;
            }
            return CONFIRM_SINGLE_SESSION;
        }
    },
    methods: {
        ...mapGetters(['getUser', 'getNearestSession']),
        ...mapMutations(['snackbarShow', 'setNearestSession', 'subtractSession']),
        ...mapActions(['initPaymentGateway', 'checkout', 'sendStateUpdatedMessage']),
        openDialog: function () {
            this.confirmDialog = true;
            this.isArranged = false;
        },
        arrange: function () {
            this.isBeingArranged = true;
            this.sessionRepository.accept(this.id)
                .then((response) => {
                    if (response.status === 201) {
                        if (response.data.session_id) {
                            this.checkout(response.data.session_id);
                        } else {
                            this.snackbarShow('Sesja została umówiona w ramach abonamentu');
                            this.isBeingArranged = false;
                            this.isArranged = true;
                            this.session.status = 'active';
                            this.session.url = response.data.meeting_url;
                            if (!this.getNearestSession() || this.session.date.isBefore(this.getNearestSession().date)) {
                                this.setNearestSession(this.session);
                            }
                            this.subtractSession();
                            this.sendStateUpdatedMessage(this.getUser().active_therapy.chat_channel);
                            setTimeout(() => this.confirmDialog = false, 250);
                        }
                    } else {
                        this.isBeingArranged = false;
                        this.snackbarShow('Wystąpił błąd podczas umawiania sesji. Spróbuj ponownie za chwilę lub skontaktuj się z nami.');
                    }
                })
                .catch(() => this.isBeingArranged = false);
        }
    }
}
</script>

<style scoped lang="scss">

@import '~vuetify/src/styles/settings/_variables.scss';

.date {
    font-weight: 600;
}
.date.shine {
    height: 18px;
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
    .card {
        flex-direction: column;
        width: auto;
    }
}

</style>
