<template>
    <div>
        <MainMenu/>

        <div id="therapist-main" style="overflow:hidden;height:100%;">
            therapist
        </div>
    </div>
</template>

<script>
    import MainMenu from "../MainMenu";

    export default {
        name: "TherapistPage",
        components: {
            MainMenu,
        }
    }
</script>

<style scoped>

</style>
