<template>
    <h3 class="faq-header" :id="headerId">
        <small>{{ subtitle }}</small>
        <span v-html="title"></span>
    </h3>
</template>

<script>
export default {
    name: "FaqHeader",
    props: ['title', 'subtitle', 'headerId']
}
</script>

<style scoped lang="scss">
@import '~vuetify/src/styles/settings/_variables.scss';
h3.faq-header {
    margin-top: 0;
    font-size: 32px;
    font-weight: 700;
    line-height: 42px;

    @media #{map-get($display-breakpoints, 'md-and-down')} {
        font-size: 24px;
        line-height: 36px;
    }

    @media #{map-get($display-breakpoints, 'xs-only')} {
        font-size: 18px;
        line-height: 28px;
    }
}
h3.faq-header small {
    display: block;
    text-transform: uppercase;
    line-height: 38px;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 10px;
}
</style>