<template>
    <div class="page-header">
        <div style="display: inline-block; width: 100%; overflow-x: hidden">
            <v-row>
                <v-col cols="auto" class="pt-0" :class="{'shine': !this.getActiveChatRecipient()}" style="width:85px;height:70px;">
                    <img :src="avatar()" :alt="this.getActiveChatRecipient()" style="max-width:70px;max-height:70px;">
                </v-col>
                <v-col>
                    <div class="therapist-name" v-if="this.getActiveChatRecipient()">
                        {{ this.fullName() }}
                    </div>
                    <div v-else class="therapist-name shine darker"></div>
                    <div class="therapist-short-bio" v-if="this.getActiveChatRecipient()">
                        {{ this.bio() }}
                    </div>
                    <div v-else>
                        <div class="line shine darker"></div>
                        <div class="line shine darker"></div>
                    </div>
                </v-col>
            </v-row>
        </div>
    </div>
</template>



<script>
    import { mapGetters } from "vuex";
    import StorageRepository from "@/repository/Resource/StorageRepository";

    export default {
        name: "ChatTherapistHeader",
        created() {
            StorageRepository
                .preview(this.storageId)
                .then((result) => {
                    this.file = 'data:image/jpeg;base64,' + result.data;
                });
        },
        methods: {
            ...mapGetters([
                "getActiveChatRecipient", 'getActiveChatRecipientAvatar'
            ]),
            fullName: function () {
                const recipient = this.getActiveChatRecipient();
                return recipient.name;
            },
            bio: function () {
                const recipient = this.getActiveChatRecipient();
                return recipient.description;
            },
            avatar: function () {
                return this.getActiveChatRecipientAvatar();
            }
        }
    }
</script>

<style scoped>
@media only screen and (max-width: 800px) {
    .page-header {
        position: absolute;
        top: 35px;
    }
}
.page-header {
    overflow-y: hidden;
    height: 69px;
}
.therapist-name {
    display: block;
    font-size: 16px;
    line-height: 19px;
}
.therapist-name.shine {
    height: 19px;
    width: 200px;
}
.therapist-short-bio {
    display: block;
    font-size: 12px;
    line-height: 15px;
    height: 40px;
    overflow-y: auto;
    opacity: 0.6;
}
.line.shine {
    height: 14px;
    line-height: 15px;
    width: 100%;
    margin-top: 2px;
}
</style>
