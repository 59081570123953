<template>
    <div class="subscription">
        <v-row class="plan pa-2" style="margin: 15px 0;">
            <v-col>
                <span class="plan-name" v-if="plan"><strong>{{ plan.name }}</strong></span>
                <span class="plan-name shine" v-else></span>
                <span class="plan-price" v-if="plan"><strong>{{
                        plan.price
                    }}</strong> <small v-if="plan && !plan.trial">zł/tygodniowo</small><small v-if="plan && plan.trial">zł</small></span>
                <span class="plan-price shine" v-else></span>
            </v-col>
            <v-col>
                <span class="plan-trait-answers" v-if="plan && plan.answers">
                    <span><strong>1-2 codziennych</strong></span>
                    <span>odpowiedzi od terapeuty</span>
                </span>
                <span class="plan-trait-answers" v-else-if="!plan">
                    <span class="line shine"></span>
                    <span class="line shine"></span>
                </span>
            </v-col>
            <v-col>
                <span class="plan-trait-sessions" v-if="plan && plan.sessions">
                    <span v-if="plan.trial"><strong>{{ plan.sessions }}</strong></span>
                    <span v-else><strong>{{ plan.sessions }} miesięcznie</strong></span>
                    <span v-if="plan.sessions === 1">{{ plan.session_duration }} minutowa sesja online (video lub tekstowa)</span>
                    <span v-else>{{ plan.session_duration }} minutowe sesje online (video lub tekstowe)</span>
                </span>
                <span class="plan-trait-answers" v-else-if="!plan">
                    <span class="line shine"></span>
                    <span class="line shine"></span>
                </span>
            </v-col>
            <v-col cols="auto" v-if="this.getUser().isAPatient()">
                <Button :disabled="!plan || cannotBuySubscription"
                        placeholder="Wybierz plan" @click.native="goToCheckout"/>
                <span v-if="plan && !plan.trial" class="plan-footer">Opłata pobierana jest za 4 tygodnie</span>
                <span v-if="plan && plan.trial" class="plan-footer">Opłata jednorazowa</span>
            </v-col>
        </v-row>
        <v-dialog max-width="400" v-model="dialog" v-if="plan" @click:outside="dialog = false">
            <v-card class="checkout">
                <v-card-title>Wykup abonament</v-card-title>
                <v-card-text>
                <dl>
                    <dt>Abonament</dt>
                    <dd>
                        <span class="plan-name"><strong>{{ plan.name }}</strong></span>
                        <span v-if="plan.answers" class="plan-trait-answers">
                            <strong>1-2 codziennych</strong>
                            <br>odpowiedzi od terapeuty
                        </span>
                        <span :class="{'hide':!plan.sessions}" class="plan-trait-sessions">
                            <strong>{{ plan.sessions }} miesięcznie</strong>
                            <br>
                            <span v-if="plan.sessions === 1">{{ plan.session_duration }} minutowa sesja online (video lub tekstowa)</span>
                            <span v-else>{{ plan.session_duration }} minutowe sesje online (video lub tekstowe)</span>
                        </span>
                    </dd>
                    <dt>Cena</dt>
                    <dd style="text-align: center">
                        <span v-if="plan.trial" class="plan-price"><strong>{{ plan.price }}</strong> <small>zł jednorazowo</small></span>
                        <span v-else class="plan-price"><strong>{{ plan.price }}</strong> <small>zł/tygodniowo (opłata pobierana jest za 4 tygodnie)</small></span>
                        <br>
                    </dd>
                </dl>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <a @click="dialog = false">Anuluj</a>
                    <Button :icon="isBeingPaid ? 'spinner' : 'credit-card'" :spin="isBeingPaid"
                            :disabled="isBeingPaid" placeholder="Przejdź do płatności" @click.native="pay"/>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import Button from "@/components/Form/Button";
import {mapActions, mapGetters, mapMutations} from "vuex";
import PlanRepository from "@/repository/Resource/PlanRepository";
import CheckoutService from "@/repository/CheckoutService";

export default {
    name: "SubscriptionCheckout",
    components: {Button},
    data: function () {
        return {
            plan: null,
            isBeingPaid: false,
            dialog: false
        }
    },
    props: {
        "planId": {
            type: String,
            required: true
        }
    },
    mounted() {
        this.initPaymentGateway();
        PlanRepository
            .getOneById(this.planId)
            .then(p => {
                this.plan = p;
                this.$emit('data-loaded');
            });
    },
    computed: {
        cannotBuySubscription() {
            // user cannot buy subscription if he already has an active subscription
            const hasActiveSubscription = this.getUser().active_subscription !== null;
            // user cannot buy subscription if he doesn't have active therapy
            const doesNotHaveActiveTherapy = this.getUser().active_therapy === null;
            return hasActiveSubscription || doesNotHaveActiveTherapy;
        }
    },
    methods: {
        ...mapGetters(['getUser']),
        ...mapMutations(['snackbarShow']),
        ...mapActions(['initPaymentGateway', 'checkout']),
        goToCheckout() {
            this.isBeingPaid = false;
            this.dialog = true;
        },
        pay() {
            this.isBeingPaid = true;
            CheckoutService
                .checkout(this.planId)
                .then(sessionId => this.checkout(sessionId));
        }
    }
}
</script>

<style scoped lang="scss">

@import '~vuetify/src/styles/settings/_variables.scss';

* {
    text-align: left;
}

.checkout {
    background-color: #FAF9F9;
    //padding: 10px 16px;
    a {
        color: #666 !important;
        margin-right: 20px;
    }

    a:hover {
        color: #999 !important;
    }

    h2 {
        margin: 0;
        font-size: 18px;
        line-height: 38px;
        font-weight: 600;
    }

    @media #{map-get($display-breakpoints, 'xs-only')} {
        .v-card__actions {
            flex-direction: column-reverse;
        }
        a {
            margin-right: 0;
            margin-top: 10px;
        }
    }

    .plan-name, .plan-price, .plan-trait-answers, .plan-trait-sessions {
        font-size: 90%;
        margin-bottom: 10px;
    }
}

.subscription {
    display: inline-block;
    max-width: 1024px;
    width: 100%;

    @media #{map-get($display-breakpoints, 'md-and-down')} {
        flex-direction: column;
        width: auto;
    }
}

span {
    display: block;
}

.plan {
    border-radius: 15px;
    background-color: #fff;
    box-shadow: rgba(8, 30, 45, 0.074) 0 -2px 14px;
    padding: 20px;

    @media #{map-get($display-breakpoints, 'md-and-down')} {
        flex-direction: column;
    }
    @media #{map-get($display-breakpoints, 'xs-only')} {
        text-align: center;
    }
}

.plan-name {
    font-size: 16px;
}

.plan-name, .plan-price, .plan-trait-answers, .plan-trait-sessions {
    margin-bottom: 20px;
}

.plan-price small, .plan-footer {
    font-size: 12px;
}

.plan span.hide {
    visibility: hidden;

    @media #{map-get($display-breakpoints, 'xs-only')} {
        display: none;
    }
}

.therapist-name {
    font-size: 16px;
    font-weight: bold;
    line-height: 32px;
}

dt {
    border-top: 1px solid #E6E8EA;
    padding-top: 10px;
    font-size: 14px;
    line-height: 32px;

    &:first-child {
        padding-top: 0;
        border-top-width: 0;
    }
}

dd {
    padding: 10px 0;
}

.shine {
    &.plan-name {
        width: 200px;
        height: 20px;
    }

    &.line {
        margin-bottom: 8px;
        width: 200px;
        height: 16px;
    }
}

.plan-price {
    font-size: 18px;

    small {
        font-size: 12px;
        line-height: 32px;
    }
}

</style>
