import Repository from '../Repository';
import { buildHeaders } from '../Headers';

const ENDPOINT = '/api/therapists';

export default {
    getOneById(id) {
        return Repository
            .get(`${ENDPOINT}/${id}`, {
                headers: buildHeaders()
            })
            .then((response) => {
                return response.data;
            })
            .catch(() => {
                this.errored = true
            })
    },
    create(data) {
        return Repository
            .post(`${ENDPOINT}`, data, {
                headers: buildHeaders()
            })
            .then((response) => {
                return response.data;
            })
        ;
    },
    getTherapies(id) {
        return Repository
            .get(`${ENDPOINT}/${id}/therapies`, {
                headers: buildHeaders()
            })
            .then((response) => {
                return response.data;
            })
        ;
    },
}
