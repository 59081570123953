import Vue from 'vue';
import Vuex from 'vuex';
import ConfigStore from './Module/ConfigStore';
import AccountStore from "./Module/AccountStore";
import UiStore from "./Module/UiStore";
import CalendarStore from "./Module/CalendarStore";
import RecorderStore from "@/service/store/Module/RecorderStore";
import CheckoutStore from "@/service/store/Module/CheckoutStore";
import ChatStore from "@/service/store/Module/ChatStore";
import {TherapistChatStore} from "@/service/store/Module/TherapistChatStore";
import {PatientChatStore} from "@/service/store/Module/PatientChatStore";
import {UserStore} from "@/service/store/Module/UserStore";
import {MessageStore} from "@/service/store/Module/MessageStore";
import {SendingMessageStore} from "@/service/store/Module/SendingMessageStore";
import {getModule} from "vuex-module-decorators";
import {TherapySessionStore} from "@/service/store/Module/TherapySessionStoreV2";

Vue.use(Vuex);

export const store = new Vuex.Store({
    modules: {
        account: AccountStore,
        config: ConfigStore,
        chat: ChatStore,
        recorder: RecorderStore,
        calendar: CalendarStore,
        checkout: CheckoutStore,
        ui: UiStore,
        therapist_chat: TherapistChatStore,
        patient_chat: PatientChatStore,
        user: UserStore,
        message: MessageStore,
        sendingMessages: SendingMessageStore,
        therapySession: TherapySessionStore,
    },
    actions: {
        resetStore({commit}) {
            commit('resetAccountStore');
            commit('resetRecorderStore');
            commit('resetChatStore');
            commit('resetCalendarStore');
        }
    }
});
export const userStore = getModule(UserStore, store);
export const therapySessionStore = getModule(TherapySessionStore, store);
export const therapistChatStore = getModule(TherapistChatStore, store);

export const patientChatStore = getModule(PatientChatStore, store);

export const messageStore = getModule(MessageStore, store);

export const sendingMessagesStore = getModule(SendingMessageStore, store);
