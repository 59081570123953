import Repository from '../Repository';
import { buildHeaders } from '../Headers';

const ENDPOINT = '/api/consultants';

export default {
    getTherapies(id) {
        return Repository
            .get(`${ENDPOINT}/${id}/consultations`, {
                headers: buildHeaders()
            })
            .then((response) => {
                return response.data;
            })
        ;
    },
}
