const DateTimeMixin = {
    methods: {
        formatDateForChat: (date) => {
            if (typeof date === "string") {
                date = new Date(date);
            }

            let now = new Date();
            let month = date.getMonth() + 1;
            const formatted = date.getFullYear() + '-' + (month <= 9 ? '0' + month : month) +
                '-' + (date.getDate() <= 9 ? '0' + date.getDate() : date.getDate());
            if (date.getFullYear() !== now.getFullYear() || date.getMonth() !== now.getMonth() || date.getDate() !== now.getDate()) {
                return formatted;
            }

            return 'Dzisiaj, ' + formatted;
        },
        formatTimeForChat: (date) => {
            if (typeof date === "string") {
                date = new Date(date);
            }

            // let now = new Date();
            let formatted = '';

            formatted = (date.getHours() <= 9 ? '0' + date.getHours() : date.getHours()) + ':' + (date.getMinutes() <= 9 ? '0' + date.getMinutes() : date.getMinutes());
            return formatted;

/*
            if (date.getFullYear() !== now.getFullYear() || date.getMonth() !== now.getMonth() || date.getDate() !== now.getDate()) {
                formatted = (date.getDate() <= 9 ? '0' + date.getDate() : date.getDate()) + ' ' + formatted;
                formatted = date.getFullYear() + '-' + (date.getMonth() <= 9 ? '0' + date.getMonth() : date.getMonth()) + '-' + formatted;

                return formatted
            }

            return 'dzisiaj, ' + formatted;
*/
        },
        formatTimeToTimer(seconds) {
            const minutes = Math.floor(seconds / 60);
            const restSeconds = seconds - minutes * 60;

            return minutes + ':' + (restSeconds < 10 ? '0' + restSeconds : restSeconds);
        },
        calculateSecondsFrom(startAt) {
            if (!startAt) {
                return 0;
            }

            const startTimestamp = startAt.getTime();
            const now = new Date();

            const lengthInSeconds = Math.round((now.getTime() - startTimestamp) / 1000);

            return lengthInSeconds;
        },
    }
}

export default DateTimeMixin;
