<template>
    <div class="authorization">
        <div class="container fill-height">
            <div class="row hidden-xs-only options justify-space-between">
                <div class="col-auto">
                    <img class="logo" :src="require('../../../assets/hp/logo.png')">
                </div>
                <div class="col-auto">
                    <router-link :to="{ name: 'route_register' }" style="color:#1a1a1a;font-weight:500;">
                        Nie masz konta?
                    </router-link>
                    <router-link :to="{ name: 'route_register' }" class="btn btn-sm btn-black">
                        Zarejestruj się
                    </router-link>
                </div>
            </div>
            <div class="row form">
                <div v-if="this.login">
                    <img class="logo hidden-sm-and-up" :src="require('../../../assets/hp/logo.png')">

                    <h2>Zaloguj się</h2>

                    <form @submit.prevent="loginSubmit" style="padding-top:30px;">
                        <div class="form-group">
                            <input type="email" v-model="username" id="username" name="username" placeholder="Email"
                                   :class="{ 'is-invalid': submitted && !username }" :disabled="loading"/>
                            <div v-show="submitted && !username" class="error-invalid">Email is required</div>
                            <div v-show="submitted && invalidCredentials" class="error-invalid">Bad credentials
                            </div>
                        </div>
                        <div class="form-group">
                            <input type="password" v-model="password" id="password" name="password" :disabled="loading"
                                   placeholder="Password" :class="{ 'is-invalid': submitted && !password }"/>
                            <div v-show="submitted && !password" class="error-invalid">Password is required</div>
                        </div>
                        <Button class="btn-fluid"
                                :icon="loading ? 'spinner' : null" :spin="loading"
                                :disabled="loading" placeholder="Zaloguj"/>
                    </form>
                    <div style="text-align:center;padding:5px;font-size:14px;">
                        <span @click="showResetPasswordForm()" class="cursor">{{ $t('restore_password') }}</span>
                    </div>
                </div>
                <div v-else>
                    <img class="logo hidden-sm-and-up" :src="require('../../../assets/hp/logo.png')">

                    <h2>{{ $t('restore_password') }}</h2>

                    <form @submit.prevent="resetPassword" style="padding-top:30px;">
                        <div class="form-group">
                            <input type="email" v-model="username" name="username" placeholder="Email"
                                   :class="{ 'is-invalid': submitted && !username }"/>
                            <div v-show="submitted && !username" class="error-invalid">Email is required</div>
                        </div>
                        <button class="btn btn-xs btn-black btn-fluid">{{ $t('restore_password_button') }}</button>
                    </form>
                    <div style="text-align:center;padding:5px;font-size:14px;">
                        <span @click="showLoginForm()" class="cursor">{{ $t('sign_in') }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState, mapActions} from 'vuex';
import UserRepository from "../../../repository/Resource/UserRepository";
import Router from "../../../service/Router";
import config from "../../../config/config";
import Button from "@/components/Form/Button";

export default {
    name: "LoginPage",
    components: {Button},
    data() {
        return {
            login: true,
            username: '',
            password: '',
            invalidCredentials: false,
            submitted: false,
            loading: false
        }
    },
    computed: {
        appName: () => {
            return config.APP_NAME;
        },
    },
    methods: {
        ...mapActions([
            'doLogin',
        ]),
        ...mapState([
            'loggingIn'
        ]),
        showResetPasswordForm() {
            this.login = false;
        },
        showLoginForm() {
            this.login = true;
        },
        loginSubmit() {
            this.loading = true;
            this.submitted = true;
            this.$store.commit('loginStart');

            if (!this.username || !this.password) {
                this.loading = false;
                return;
            }

            UserRepository
                .login(this.username, this.password)
                .then((response) => {
                    if (response.status === 401) {
                        this.invalidCredentials = true;
                        this.$store.commit('loginStop', null);
                        this.$store.commit('removeToken');
                    } else {
                        this.$store.commit('loginStop', null);
                        this.$store.commit('saveToken', response.data.token);
                        localStorage.setItem('token', response.data.token);
                        Router.push({name: 'route_home'});
                    }
                })
                .catch(() => {
                    this.invalidCredentials = true;
                    this.$store.commit('loginStop', null);
                    this.$store.commit('removeToken');
                })
                .finally(() => this.loading = false)
            ;
        },
        resetPassword() {
            this.$store
                .dispatch('resetPasswordByUsername', this.username)
                .then((response) => {
                    if (response.status === 204) {
                        this.$store.commit('alertShow', {
                            type: 'success',
                            title: this.$t('alert_title_success'),
                            message: this.$t('alert_password_reset_by_username_message'),
                        });
                        this.showLoginForm();
                    } else {
                        this.$store.commit('alertShow', {
                            type: 'error',
                            title: this.$t('alert_title_error'),
                            message: this.$t('alert_password_reset_by_username_fail'),
                        });
                    }
                });
        },
        toggleAlert() {

        },
    }
}
</script>

<style scoped>
.error-invalid {
    position: relative;
    top: -18px;
    color: #e98da8;
    text-align: right;
    font-size: 12px;
}

.is-invalid {
    border-color: #e98da8;
}
</style>
