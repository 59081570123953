<template>
    <button class="btn-default" :disabled="disabled">
        <font-awesome-icon v-if="icon" :icon="icon" :class="{'fa-spin': spin}" class="fa-fw"/>
        {{ placeholder }}
    </button>
</template>

<script>
    export default {
        name: "Button",
        props: {
            icon: {
                type: String,
                required: false
            },
            spin: {
                type: Boolean
            },
            disabled: {
                type: Boolean
            },
            placeholder: {
                type: String,
                required: true
            },
        }
    }
</script>

<style scoped>
    button {
        background-color:#131313;
        color:#ffffff;
        padding:10px 20px;
        font-weight:bold;
        font-size:14px;
    }
    button:hover, button:focus {
        color: #ccc;
    }
    button:active {
        background-color: #434343;
    }
    button:disabled {
        background-color: #ccc;
    }
    button:disabled:hover, button:disabled:focus {
        color:#ffffff;
    }
    svg {
        margin-right: 2px;
    }
</style>
