import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {Message} from "@/dataModel/chat";
import {messageTimeout, sendMessage, waitForMessage} from "@/service";

class Queue {

    private queue: {message: Message, onSuccess?: (messages: Message[]) => void}[] = [];
    private isLooping = false;
    private isPaused = true;

    enqueue(message: Message, onSuccess?: (messages: Message[]) => void) {
        this.queue.push({message, onSuccess});
        if (this.isPaused) return;
        if (!this.isLooping) this.loop();
    }

    pause() {
        this.isPaused = true;
    }

    resume() {
        this.isPaused = false;
        this.loop();
    }

    private loop() {
        this.isLooping = true;
        const item = this.queue.shift();
        if (item != null) {
            const message = item.message;
            const sendingId = message.id;

            const timeout = setTimeout(() => {
                this.queue.unshift(item);
                messageTimeout(sendingId);
                this.next();
            }, 5000)

            console.log('sending message', sendingId);
            sendMessage({
                sending_id: sendingId,
                token: localStorage.getItem('token')!,
                message: message.body,
                chat_channel: message.channel_id,
                message_type: 'chat-message',
                created_at: message.created_at,
                resources: message.resources.map((resource) => resource.id),
            });
            waitForMessage(sendingId, () => {
                const messages = JSON.parse(localStorage.getItem(STORAGE_KEY) ?? '[]') as Message[];
                const newMessages = messages.filter(m => m.id !== sendingId);
                localStorage.setItem(STORAGE_KEY, JSON.stringify(newMessages));
                clearTimeout(timeout);
                this.next();
                item.onSuccess?.(newMessages);
            });
        } else {
            this.next();
        }
    }

    private next() {
        if (!this.queue.length || this.isPaused) {
            this.isLooping = false;
            return;
        }
        this.loop();
    }
}

export const MessageQueue = new Queue();

const STORAGE_KEY = 'messagesBeingSent';

@Module({namespaced: true, name: 'sendingMessages'})
export class SendingMessageStore extends VuexModule {

    private _messages: Message[] = [];

    get messages(): (channelId: string) => Message[] {
        return (channelId) => this._messages.filter((message) => message.channel_id === channelId);
    }

    @Mutation
    setMessages(messages: Message[]) {
        this._messages = messages;
    }

    @Mutation
    push(message: Message) {
        this._messages = [...this._messages, message];
        localStorage.setItem(STORAGE_KEY, JSON.stringify(this._messages));
        MessageQueue.enqueue(message, messages => this._messages = messages);
    }

    @Action
    loadFromStorage() {
        const messages = JSON.parse(localStorage.getItem(STORAGE_KEY) ?? '[]') as Message[];
        this.context.commit('setMessages', messages);
        messages.forEach(message => MessageQueue.enqueue(message, messages => this.context.commit('setMessages', messages)));
    }
}
