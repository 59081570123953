import Component from "vue-class-component";
import Vue from "vue";

@Component
export default class Base extends Vue {

    getter<T>(name: string): T {
        return this.$store.getters[name];
    }

    setter<T>(name: string, value?: T) {
        this.$store.commit(name, value);
    }

    action<T>(name: string, value?: T): Promise<any> {
        return this.$store.dispatch(name, value);
    }
}
