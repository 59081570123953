import Vue from 'vue';
import Vuex from 'vuex';
import dayjs from "dayjs";
import SessionRepository from "@/repository/Resource/SessionRepository";

dayjs.locale(require('dayjs/locale/pl'));
dayjs.extend(require('dayjs/plugin/utc'));
dayjs.extend(require('dayjs/plugin/isoWeek'));

Vue.use(Vuex);

const getDefaultState = () => {
    const t = dayjs.utc().startOf('day').startOf('isoWeek').locale('pl');
    return {
        scale: 1.35,
        weekDescription: 'Ten tydzień',
        thisWeek: t,
        weekStart: t,
        days: computeDays(t),
        sessions: [[], [], [], [], [], [], []]
    }
}

const computeDays = (weekStart) => {
    let result = [];
    for (let i = 0; i < 7; i++) {
        result.push(weekStart.add(i, 'day'));
    }
    return result;
}

const computeDescription = (thisWeek, weekStart) => {
    if (thisWeek.format('YYYY-MM-DD') === weekStart.format('YYYY-MM-DD')) {
        return 'Ten tydzień';
    } else {
        return `${weekStart.format('D MMMM')} - ${weekStart.clone().add(6, 'days').format('D MMMM')}`;
    }
}

export default {
    state: getDefaultState(),
    getters: {
        getWeekDescription: (state) => {
            return state.weekDescription;
        },
        getCalendarSessions: (state) => {
            return state.sessions;
        },
        getWeekStart: (state) => {
            return state.weekStart;
        },
        getDays: (state) => {
            return state.days;
        },
        getCalendarScale: (state) => {
            return state.scale;
        }
    },
    actions: {
        cancelSession: (context, session) => {
            SessionRepository.cancel(session.id)
                .then(() => context.commit('removeSession', session))
                .finally(() => {
                    context.dispatch('fetchUserMe');
                    context.dispatch('sendStateUpdatedMessage', session.chat_channel);
                });
        },
    },
    mutations: {
        resetCalendarStore: (state) => {
            Object.assign(state, getDefaultState());
        },
        nextWeek: (state) => {
            state.weekStart = state.weekStart.add(1, 'week');
            state.days = computeDays(state.weekStart);
            state.weekDescription = computeDescription(state.thisWeek, state.weekStart);
        },
        previousWeek: (state) => {
            state.weekStart = state.weekStart.subtract(1, 'week');
            state.days = computeDays(state.weekStart);
            state.weekDescription = computeDescription(state.thisWeek, state.weekStart);
        },
        createSession: (state, data) => {
            const duration = 30;
            state.sessions[data.idx].push({
                start: data.start,
                end: data.start.add(duration, 'minutes'),
                person: "Pacjent",
                duration: duration
            })
        },
        removeSession: (state, session) => {
            const dayOfWeek = session.start.isoWeekday() - 1;
            const copy = [...state.sessions];
            copy[dayOfWeek] = copy[dayOfWeek].filter(s => s.id !== session.id);
            state.sessions = copy;
        },
        scaleUp: (state) => state.scale += 0.25,
        scaleDown: (state) => {
            if (state.scale > 0.5) {
                state.scale -= 0.25
            }
        },
        setSessions: (state, sessions) => state.sessions = sessions,
        fetchCalendar: (state) => {
            state.sessions = [[], [], [], [], [], [], []];
            SessionRepository
                .getSessions(state.weekStart.format('YYYY-MM-DD'))
                .then(sessions => {
                    const result = [[], [], [], [], [], [], []];
                    sessions.forEach(session => {
                        const date = dayjs(session.date);
                        result[date.isoWeekday()-1].push({
                            id: session.id,
                            start: date,
                            end: date.add(session.duration, 'minutes'),
                            duration: session.duration,
                            person: session.description,
                            status: session.status,
                            chat_channel: session.chat_channel,
                        });
                    });
                    state.sessions = result;
                });
        }
    }
}
