<template>
    <div :style="componentStyle">
        <ul v-if="this.getCurrentChatChannelId()" class="messages" ref="messagesContainer">
            <WelcomeMessage :loaded="true"
                            :is-consultation="this.$router.currentRoute.meta.chatWithConsultant"
                            :is-daily-message="this.$router.currentRoute.meta.chatWithTherapist"/>
            <template v-for="(chatMessage,key) in this.getChatMessages">
                <li :key="-key-1" class="message-date" v-if="chatMessage.previous === null || chatMessage.previous.date !== chatMessage.date">
                    <p><span>{{chatMessage.date}}</span></p></li>
                <li :key="key" :class="{ 'with-avatar': chatMessage.previous === null || chatMessage.previous.header !== chatMessage.header,
                               'my-message': textRight(chatMessage.current.member_id),
                               'other-message': !textRight(chatMessage.current.member_id) }">
                    <p v-if="chatMessage.previous && !chatMessage.previous.message.unread && chatMessage.current.unread"
                       class="new-messages-header"><span>Nowe wiadomości</span></p>
                    <div class="message-container" :class="{'sending': chatMessage.current.sending}">
                        <p v-if="chatMessage.previous === null || chatMessage.previous.header !== chatMessage.header"
                           class="user-header"><span v-html="chatMessage.header"></span></p>
                        <ul v-if="chatMessage.current.resources.length" class="resources">
                            <li v-for="(resource, key2) in chatMessage.current.resources" :key="key2">
                                <SecureImage :mime-type="resource.mime_type" @data-loaded="scrollToEnd" v-if="resource.mime_type === 'image/jpeg'" :storage-id="resource.id"/>
                                <SecureImage :mime-type="resource.mime_type" @data-loaded="scrollToEnd" v-else-if="resource.mime_type === 'image/png'" :storage-id="resource.id"/>
                                <AudioPlayer :mime-type="resource.mime_type" @data-loaded="scrollToEnd" v-else-if="resource.mime_type === 'audio/mpeg'" :storage-id="resource.id"/>
                                <AudioPlayer :mime-type="resource.mime_type" @data-loaded="scrollToEnd" v-else-if="resource.mime_type === 'audio/ogg'" :storage-id="resource.id"/>
                                <AudioPlayer :mime-type="resource.mime_type" @data-loaded="scrollToEnd" v-else-if="resource.mime_type === 'video/webm'" :storage-id="resource.id"/>
                                <div style="clear:both;"></div>
                            </li>
                        </ul>
                        <span v-if="chatMessage.current.metadata && chatMessage.current.metadata.type === 'therapist'">
                            <span>Propozycja terapeuty</span>
                            <br>
                            <Therapist @data-loaded="scrollToEnd" :id="chatMessage.current.metadata.id"/>
                        </span>
                        <span v-if="chatMessage.current.metadata && chatMessage.current.metadata.type === 'subscription'">
                            <span>Propozycja abonamentu</span>
                            <br>
                            <SubscriptionCheckout @data-loaded="scrollToEnd" :plan-id="chatMessage.current.metadata.id"/>
                        </span>
                        <span v-else-if="chatMessage.current.metadata && chatMessage.current.metadata.type === 'session'">
                            <span>Propozycja terminu sesji</span>
                            <br>
                            <ArrangeSession :id="chatMessage.current.metadata.id"/>
                        </span>
                        <div class="message-body" v-html="chatMessage.current.body" v-else>
                        </div>
                    </div>
                    <font-awesome-icon style="position: absolute; bottom: 15px; right: 4px; font-size: 80%;" fixedWidth spin
                                       v-if="chatMessage.current.sending" icon="spinner"></font-awesome-icon>
                </li>
            </template>
        </ul>
    </div>
</template>

<script>
import AudioPlayer from "../Common/AudioPlayer";
import SecureImage from "../Common/SecureImage";
import {mapGetters} from "vuex";
import DateTimeMixin from "../../mixins/DateTimeMixin";
import Therapist from "@/components/Common/Therapist";
import ArrangeSession from "@/components/Common/ArrangeSession";
import SubscriptionCheckout from "@/components/Common/SubscriptionCheckout";
import WelcomeMessage from "@/components/Chat/WelcomeMessage";

const md = require('markdown-it')({html: false, linkify:true, typographer:true});

let currentMessageUser;// todo: global scope because of infinity loop

    export default {
        name: "ChatWindow",
        components: {
            WelcomeMessage,
            SubscriptionCheckout,
            ArrangeSession,
            Therapist,
            AudioPlayer,
            SecureImage,
        },
        props: {
            channelId: {
                type: String,
            },
        },
        mixins: [
            DateTimeMixin,
        ],
        updated() {
            this.scrollToEnd();
        },
        computed: {
            ...mapGetters([
                'getWindowWidth',
                'getWindowHeight',
                'getActiveChatRecipient',
                'getBottomPanelHeight'
            ]),
            componentStyle() {
                return {
                    'position': 'absolute',
                    'overflow-x': 'hidden',
                    'overflow-y': 'scroll',
                    'width': '100%',
                    'top': this.getWindowWidth > 800 ? '71px' : '104px',
                    'height': `${this.getWindowHeight - this.getBottomPanelHeight - (this.getWindowWidth > 800 ? 71 : 125)}px`,
                };
            },
            getChatMessages: function() {
                let chatMessages = this.$store.getters.getChatMessages(this.getCurrentChatChannelId());
                let sendingMessages = this.$store.getters['sendingMessages/messages'](this.getCurrentChatChannelId());
                const messages = [
                    ...chatMessages,
                    ...sendingMessages.map((sendingMessage) => ({
                        ...sendingMessage,
                        sending: true
                    }))
                ];

                function compare(a, b) {
                    if (a.created_at < b.created_at) {
                        return -1;
                    }
                    if (a.created_at > b.created_at) {
                        return 1;
                    }

                    return 0;
                }

                messages.sort(compare);

                return messages.map((msg, i) => {
                    return {
                        current: {...msg, body: md.render(msg.body)},
                        date: this.formatDateForChat(msg.created_at),
                        header: this.showUserHeader(msg),
                        previous: i > 0 ? {
                            message: {...messages[i - 1], body: md.render(messages[i - 1].body)},
                            date: this.formatDateForChat(messages[i - 1].created_at),
                            header: this.showUserHeader(messages[i - 1]),
                        } : null
                    }
                });
            },
        },
        methods: {
            ...mapGetters([
                'getUser',
                'getChatMembers',
                'getCurrentChatChannelId',
            ]),
            scrollToEnd: function () {
                let content = this.$refs.messagesContainer;
                if (content) this.$el.scrollTop = content.scrollHeight;
            },
            showUserHeader(chatMessage) {
                if (!chatMessage.member_id) {
                    return;
                }

                if (currentMessageUser !== chatMessage.member_id) {
                    currentMessageUser = chatMessage.member_id;
                }

                let member = this.getChatMembers().find((member) => {
                    return member.id === currentMessageUser;
                });

                let date = new Date(chatMessage.created_at);

                if (this.textRight(chatMessage.member_id)) {
                    return this.formatTimeForChat(date) + ' &nbsp;<b>&middot;</b>&nbsp; Twoja wiadomość';
                }

                return member.firstname + ' ' + member.lastname + ' &nbsp;<b>&middot;</b>&nbsp; ' + this.formatTimeForChat(date);
            },
            textRight(memberId) {
                return this.getUser() && memberId === this.getUser().id;
            },
        }
    }
</script>

<style scoped>
    ul.resources { list-style:none;padding-left: 0; }
    ul.messages { list-style:none;padding-left: 0; }
    /*.messages { list-style:none;padding-left: 0; }*/
    ul.messages>li { font-size: 14px; line-height: 19px; }
    /*.messages>div { font-size: 14px; line-height: 19px; }*/

    .my-message {
        position: relative;
        text-align: right;
    }
    .my-message .message-container > p, .other-message .message-container > p {
        margin-bottom: 8px;
    }
    .message-container {
        display: inline-block;
        max-width: 80%;
        padding: 10px 20px 15px;
    }
    .message-container.sending {
        opacity: 0.5;
    }
    .my-message .message-container {
        background-color: #fff;
        text-align: right;
        border-radius: 20px 0 20px 20px;
    }
    .my-message .secure-image { float:right; }

    .other-message .message-container {
        background-color: #396e51;
        border-radius: 0 20px 20px 20px;
    }
    .other-message .message-container .message-body >>> * {
        color: #fff;
    }
    /*.other-message { background-color:#e6e6e6; }*/
    .other-message .secure-image { float: left; }
    .message-date {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1;
    }
    .message-date p {
        font-weight: 500;
        color: #a5a5a5;
        font-size: 12px;
        line-height: 15px;
        background-color: #faf9f9;
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAAEnQAABJ0Ad5mH3gAAAAMSURBVBhXY7h9+zYABSYCknxnPAQAAAAASUVORK5CYII=');
        background-size: 100% 1px;
        background-position: 0 17px;
        margin-bottom: 0;
        padding: 10px 0 5px;
    }
    .user-header {
        color: #a5a5a5;
        font-size: 12px;
    }
    .user-header img {
        width: 37px;
        height: 37px;
        border-radius: 50%;
    }
    .new-messages-header {
        text-align: left;
        font-weight: 500;
        color: #1a1a1a;
        font-size: 12px;
        line-height: 20px;
        /*background-color: #fff;*/
        background-image:  url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAABhGlDQ1BJQ0MgcHJvZmlsZQAAKJF9kT1Iw0AcxV9TpaIVBztI6ZChOlkQFXGUKhbBQmkrtOpgcukXNGlIUlwcBdeCgx+LVQcXZ10dXAVB8APEzc1J0UVK/F9SaBHjwXE/3t173L0DhGaVqWbPBKBqlpFOxMVcflUMvEJAGCEMICIxU09mFrPwHF/38PH1LsazvM/9OQaVgskAn0g8x3TDIt4gntm0dM77xCFWlhTic+Jxgy5I/Mh12eU3ziWHBZ4ZMrLpeeIQsVjqYrmLWdlQiaeJo4qqUb6Qc1nhvMVZrdZZ+578hcGCtpLhOs0IElhCEimIkFFHBVVYiNGqkWIiTftxD3/Y8afIJZOrAkaOBdSgQnL84H/wu1uzODXpJgXjQO+LbX+MAoFdoNWw7e9j226dAP5n4Err+GtNYPaT9EZHix4BQ9vAxXVHk/eAyx1g5EmXDMmR/DSFYhF4P6NvygPDt0D/mttbex+nD0CWulq+AQ4OgbESZa97vLuvu7d/z7T7+wFmRHKiUGX+sgAAAAlwSFlzAAAuIwAALiMBeKU/dgAAAAd0SU1FB+UCGhQQJKLXOSYAAAAZdEVYdENvbW1lbnQAQ3JlYXRlZCB3aXRoIEdJTVBXgQ4XAAAADElEQVQI12OQkpICAACgAE/9sOR3AAAAAElFTkSuQmCC');
        background-size: 100% 1px;
        background-position: 0 20px;
        margin-bottom: 0;
        margin-right: -20px;
        padding: 10px 0;
    }
    .new-messages-header span {
        background-color: #faf9f9;
        padding-right: 10px;
    }
    .my-message {
        min-height: 50px;
        /*padding: 10px 77px 10px 20px;*/
        padding: 10px 20px;
    }
    .my-message.with-avatar {
        /*background-image: url("../../assets/default-avatar-300x300.png");*/
        background-size: 37px 37px;
        background-position: top 14px right 20px;
    }
    .other-message {
        min-height: 50px;
        /*padding: 10px 20px 10px 77px;*/
        padding: 10px 20px;
    }
    .other-message.with-avatar {
        /*background-image: url("../../assets/default-avatar-300x300.png");*/
        background-size: 37px 37px;
        background-position: top 14px left 20px;
    }
    .message-date span {
        padding: 0 20px;
        background-color: #faf9f9;
    }
    .message-body >>> p {
        font-size: 14px;
        line-height: 19px;
        margin: 0 0 8px;
    }
    .message-body >>> h1 {
        font-size: 17px;
        line-height: 19px;
        margin: 0 0 8px;
        font-weight: 600;
    }
    .message-body >>> h2 {
        font-size: 16px;
        line-height: 19px;
        margin: 0 0 8px;
        font-weight: 600;
    }
    .message-body >>> h3 {
        font-size: 15px;
        line-height: 19px;
        margin: 0 0 8px;
        font-weight: 600;
    }
    .message-body >>> h4 {
        font-size: 14px;
        line-height: 19px;
        margin: 0 0 8px;
        font-weight: 600;
    }
    .message-body >>> ol {
        margin: 0 0 8px;
    }
    .message-body >>> ul {
        margin: 0 0 8px;
    }
    .message-body >>> a {
        text-decoration: underline;
    }
    .message-body >>> a:hover {
        text-decoration: none;
    }
    .message-body >>> hr {
        margin: 8px 0;
        opacity: 0.5;
    }
    .message-body >>> blockquote > p {
        font-size: 90%;
    }
</style>
