import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './service/Router';
import { store } from "@/service/store";
import i18n from './i18n';
import VueGtm from '@gtm-support/vue2-gtm';

import { library } from '@fortawesome/fontawesome-svg-core'
import {faCamera, faMicrophone, faSlash, faTrash, faUser} from '@fortawesome/free-solid-svg-icons'
import { faMicrophoneAlt } from '@fortawesome/free-solid-svg-icons'
import { faImages } from '@fortawesome/free-solid-svg-icons'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import { faRecordVinyl } from '@fortawesome/free-solid-svg-icons'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { faCalendarCheck } from '@fortawesome/free-solid-svg-icons'
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons'
import { faCalendarPlus } from '@fortawesome/free-solid-svg-icons'
import { faCreditCard } from '@fortawesome/free-solid-svg-icons'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { faExpandArrowsAlt } from '@fortawesome/free-solid-svg-icons'
import { faChevronCircleRight } from '@fortawesome/free-solid-svg-icons'
import { faChevronCircleLeft } from '@fortawesome/free-solid-svg-icons'
import { faMinus } from '@fortawesome/free-solid-svg-icons'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { faVolumeUp } from '@fortawesome/free-solid-svg-icons'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { faLock } from '@fortawesome/free-solid-svg-icons'
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon, FontAwesomeLayers} from '@fortawesome/vue-fontawesome'
import './registerServiceWorker'

library.add(faTrash, faMicrophoneAlt, faImages, faPaperPlane);
library.add(faRecordVinyl);
library.add(faTimes);
library.add(faBars);
library.add(faSpinner);
library.add(faCalendarCheck);
library.add(faCalendarAlt);
library.add(faCalendarPlus);
library.add(faCreditCard);
library.add(faCheck);
library.add(faExpandArrowsAlt);
library.add(faChevronCircleRight);
library.add(faChevronCircleLeft);
library.add(faPlus);
library.add(faMinus);
library.add(faVolumeUp);
library.add(faCircle);
library.add(faLock);
library.add(faUser);
library.add(faPhone);
library.add(faMicrophone);
library.add(faCamera);
library.add(faSlash);

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('font-awesome-layers', FontAwesomeLayers);

Vue.config.productionTip = false;

Vue.filter('capitalize', function (value: string | null) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.use(VueGtm, {
  id: 'GTM-ML6G3TG',
  enabled: true,
  debug: true,
  loadScript: true,
  vueRouter: router,
})

new Vue({
  // @ts-ignore
  vuetify,
  store,
  i18n,
  router: router,
  render: h => h(App)
}).$mount('#app')
