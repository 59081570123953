export default {
    planWithChatOnly: 99, /* plan 1 */
    planWith1SessionAndChat: 129, /* plan 2 */
    planWith4SessionsAndChat: 169, /* plan 3 */
    planWith4Sessions: 119, /* plan 4 */
    session30: 119,
    session50: 159,
    trialWithoutSession: 109,
    trialWithSession: 149
}
