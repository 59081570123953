import Repository from '../Repository';
import { buildHeaders } from '../Headers';
import config from '../../config/config';

const ENDPOINT = `${config.PATH_PREFIX}/users`;

export default {
    handleError(statusCode) {
        switch (statusCode) {
            case 401:
        //         localStorage.removeItem('token');
        //         break;
        }
    },
    getOneById(id) {
        Repository
            .get(`${ENDPOINT}/${id}`, {
                headers: buildHeaders()
            })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                this.handleError(error.status);
            })
    },
    getMe() {
        return Repository
            .get(`${ENDPOINT}/me`, {
                headers: buildHeaders(),
            })
            .catch(error => {
                this.handleError(error.status);
            })
        ;
    },
    async isNewChatEnabled(chatChannelId) {
        const {data: {enabled = false}} = await Repository.get(`${config.PATH_PREFIX}/features/WEB_RTC/${chatChannelId}`, {headers: buildHeaders()});
        return enabled;
    },
    getSettings() {
        return Repository
            .get(`${ENDPOINT}/me/settings`, {
                headers: buildHeaders()
            });
    },
    saveSettings(settings) {
        return Repository
            .put(`${ENDPOINT}/me/settings`, settings, {
                headers: buildHeaders()
            });
    },
    login(username, password) {
        return Repository
            .post(`${config.PATH_PREFIX}/token`, {
                email: username,
                password: password,
            }, {
                headers: buildHeaders()
            })
        ;
    },
    create(username, firstname) {
        return Repository
            .post(`${ENDPOINT}`, {
                email: username,
                firstname: firstname,
            }, {
                headers: buildHeaders()
            })
        ;
    },
    resetPasswordByUsername(username) {
        return Repository
            .post(`${config.PATH_PREFIX}/password-reset`, {
                username: username,
            }, {
                headers: buildHeaders()
            })
        ;
    },
    setupPassword(hash, password) {
        return Repository
            .patch(`${config.PATH_PREFIX}/password-reset`, {
                hash: hash,
                password: password,
            }, {
                headers: buildHeaders({}, 'PATCH')
            })
        ;
    },
    changePassword(currentPassword, newPassword, confirmPassword) {
        return Repository
            .put(`${ENDPOINT}/me/password`, {
                current_password: currentPassword,
                new_password: newPassword,
                confirm_password: confirmPassword,
            }, {
                headers: buildHeaders({}, 'PUT')
            })
        ;
    }
}
