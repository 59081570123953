class UIEventsClass {

    listener = null;

    setListener(listener) {
        this.listener = listener;
    }

    updated() {
        if (this.listener !== null) this.listener();
    }
}

const UIEvents = new UIEventsClass();

export default UIEvents;
