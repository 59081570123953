
    import StorageRepository from "../../repository/Resource/StorageRepository";
    import Button from '@/components/Form/Button.vue';
    import ChatWindow from "../Chat/ChatWindow.vue";
    import ChatTherapistHeader from "../Chat/ChatTherapistHeader.vue";
    import SecureImage from "../Common/SecureImage.vue";
    import AudioPlayer from "../Common/AudioPlayer.vue";
    import UriMixin from "../../mixins/UriMixin";
    import MainMenu from "./MainMenu.vue";
    import DateTimeMixin from "@/mixins/DateTimeMixin";
    import ChatAudioRecorder from "@/components/Recorder/ChatAudioRecorder.vue";
    import {mapActions, mapGetters} from "vuex";
    import ImageReduce from "@/service/ImageReduce";
    import CreateSessionDialog from "@/components/Common/CreateSessionDialog.vue";
    import Component, {mixins} from "vue-class-component";
    import Base from "@/mixins/Base";
    import {PendingMessage, Resource} from "@/dataModel/chat";

    const reducer = new ImageReduce();

    let MAX_LENGTH = 2000;

    @Component<ChatPage>({
        name: "ChatPage",
        mixins: [
            UriMixin,
            DateTimeMixin,
        ],
        components: {
            CreateSessionDialog,
            ChatAudioRecorder,
            MainMenu,
            AudioPlayer,
            SecureImage,
            ChatTherapistHeader,
            ChatWindow,
            Button,
        },
        mounted() {
            if (this.$store.getters.getUser && (this.$store.getters.getUser.therapist || this.$store.getters.getUser.consultant)) {
                MAX_LENGTH = 1000000;
            }
        },
        computed: {
            maxLength: {
                get() {
                    return MAX_LENGTH;
                }
            },
            charsLeft: {
                get() {
                    return MAX_LENGTH - this.message.length;
                }
            }
        },
        methods: {
            ...mapGetters([
                'getSocket',
                'getRecorderState',
                'getRecorderState',
                'getUser',
            ]),
            ...mapActions([
                'initRecorder',
                'closeRecorder',
            ]),
            play() {
                // @ts-ignore
                this.aud.play();
            },
            pause() {
                // @ts-ignore
                this.aud.pause();
            },
        }
    })
    export default class ChatPage extends mixins(Base) {

        calendarOpen = false;

        get message(): string {
            return (this.getter('message/pending') as PendingMessage).message;
        }

        set message(newMessage: string) {
            this.action('message/setMessage', newMessage);
        }

        get resources(): Resource[] {
            return (this.getter('message/pending') as PendingMessage).resources;
        }

        get windowHeight(): number {
            return this.getter('getWindowHeight');
        }

        get windowWidth(): number {
            return this.getter('getWindowWidth');
        }

        get textAreas(): HTMLTextAreaElement[] {
            return [
                this.$refs.messageMobile as HTMLTextAreaElement | null,
                this.$refs.messageDesktop as HTMLTextAreaElement | null
            ].filter((element) => element != null) as HTMLTextAreaElement[];
        }

        get uploadedFiles(): FileList {
            return (this.$refs.files as HTMLInputElement).files ?? new FileList();
        }

        addResource(resource: Resource) {
            this.action('message/addResource', resource);
        }

        updateBottomPanelHeight() {
            this.setter('setBottomPanelHeight', (document.getElementById('panel-bottom')!.scrollHeight - (this.windowWidth < 800 ? 20 : 0)));
        }

        toggleCalendar() {
            this.calendarOpen = !this.calendarOpen;
        }

        autoGrow() {
            const element = (this.windowWidth < 800 ? this.$refs.messageMobile : this.$refs.messageDesktop) as HTMLTextAreaElement;
            if (!element) return;
            element.style.height = 'auto';
            let newHeight = element.scrollHeight;
            if (newHeight < 60) newHeight = 40;
            if (newHeight > 200) {
                newHeight = 200;
                element.style.overflowY = 'scroll';
            } else {
                element.style.overflowY = 'hidden';
            }
            this.textAreas.forEach((area) => area.style.height = newHeight + 'px');
            this.updateBottomPanelHeight();
        }

        onTextChanged() {
            this.autoGrow();
        }

        sendMessage() {
            if ((this.message.trim().length || this.resources.length) && this.message.length <= MAX_LENGTH) {
                this.$store.dispatch('message/send');
                this.updateBottomPanelHeight();
            }
        }

        sendMessageInTextarea(event: KeyboardEvent) {
            if (event.ctrlKey) {
                event.stopPropagation();
                if ((this.message.trim().length || this.resources.length) && this.message.length <= MAX_LENGTH) {
                    this.$store.dispatch('message/send');
                    this.textAreas.forEach((area) => area.style.height = '40px');
                    this.updateBottomPanelHeight();
                }
                event.preventDefault();
            }
        }

        createSession({date, sessionType}: {date: string, sessionType: string}) {
            this.toggleCalendar();
            this.message = `/termin ${date} 00 ${sessionType}`;
            this.sendMessage();
        }

        handleFileUploads() {
            const uploadedFiles = this.uploadedFiles;
            if (uploadedFiles.length > 5) {
                alert('Maximum allowed files for upload is 5.');
                return false;
            }
            for (let i = 0; i < uploadedFiles.length; i++) {
                reducer.toBlob(uploadedFiles.item(i), {max: 1000})
                    .then((file: Blob) => StorageRepository.create(file, 'media', 'Some title'))
                    .then((resource: Resource) => this.addResource(resource));
            }
        }
    }
