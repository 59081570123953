import {Module, Mutation, VuexModule} from "vuex-module-decorators";
import {Chat} from "@/dataModel/chat";

@Module({namespaced: true, name: 'patient_chat'})
export class PatientChatStore extends VuexModule {

    private _consultation: Chat | null = null;

    private _therapy: Chat | null = null;

    @Mutation
    public markAsRead(chatId: string) {
    }
}
