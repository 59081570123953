
import Component, {mixins} from "vue-class-component";
import {Chat} from "@/dataModel/chat";
import {User} from "@/dataModel/user";
import Base from "@/mixins/Base";
import {Session} from "@/dataModel/therapy";
import {Subscription} from "@/dataModel/subscription";
import {therapistChatStore, userStore} from "@/service/store";

@Component<MainMenu>({})
export default class MainMenu extends mixins(Base) {
    drawer = null;

    get lastMessage() {
        return (chat: Chat) => {
            const author = chat.last_message_author === this.user?.id ? this.$t('me') : chat.last_message_author_name;
            return `${author}: ${chat.last_message}`;
        };
    }

    get unreadChats(): Chat[] {
        return therapistChatStore.unreadChats;
    }

    get readChats(): Chat[] {
        return therapistChatStore.readChats;
    }

    get user(): User | null {
        return userStore.user;
    }

    get activeSubscription(): Subscription | null {
        if (this.user) {
            return this.user.active_subscription;
        }
        return null;
    }

    get nearestSession(): Session | null {
        return this.getter('getNearestSession');
    }

    logout() {
        this.$store.dispatch('doLogout');
    }

    deactivateChat() {
        this.$store.commit('resetActiveChat');
    }

    activateChat(channelId: string) {
        channelId = this.uriToId(channelId);
        if (this.$router.currentRoute.name !== 'route_chat') {
            this.$router.push({name: 'route_chat'})
                .then(() => this.$store.dispatch('loadChatChannel', channelId));
        } else {
            this.$store.dispatch('loadChatChannel', channelId);
        }
    }

    goToChat() {
        this.$store.dispatch('loadChatChannel', this.user!.active_therapy!.chat_channel.slice(-36));
    }

    goToConsultation() {
        this.$store.dispatch('loadChatChannel', this.user!.consultation!.chat_channel.slice(-36));
    }

    uriToId(uri: string) {
        if (0 === uri.indexOf('/')) {
            const part = uri.split('/');
            uri = part[part.length - 1];
        }

        return uri;
    }
}
