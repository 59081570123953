import Repository from '../Repository';
import {buildHeaders} from '../Headers';
import axios from "axios";

const ENDPOINT = '/api/chat-channels';

let previousRequest = {
    cancel() {}
};

let isLoadingChatChannel = {};

export default {
    getOneById(id) {
        if (isLoadingChatChannel[id]) {
            return Promise.reject();
        }
        isLoadingChatChannel[id] = true;
        previousRequest.cancel();
        previousRequest = axios.CancelToken.source();
        return Repository
            .get(`${ENDPOINT}/${id}`, {
                headers: buildHeaders(),
                cancelToken: previousRequest.token
            })
            .then(response => {
                if (response.status === 200) {
                    return response.data;
                }
                return Promise.reject(response.status);
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    return Promise.resolve();
                }
            })
            .finally(() => isLoadingChatChannel[id] = false);
    },
    markAsRead(id) {
        return Repository
            .put(`${ENDPOINT}/${id}/views`, {}, {
                headers: buildHeaders()
            })
            .then(response => {
                if (response?.status === 200) {
                    return true;
                }
                return Promise.reject(response?.status);
            });
    },
    create(data) {
        return Repository
            .post(`${ENDPOINT}`, data, {
                headers: buildHeaders(),
            })
            .then(response => response.data);
    }
}
