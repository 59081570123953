<template>
    <div>
        <MainMenu/>

        <div class="page-header">
            <h2>Moje konto</h2>
        </div>

        <div id="settings-main">

            <div class="card" v-if="user && user.isAPatient()">
                <v-row>
                    <v-col><h4>Powiadomienia</h4></v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <ul>
                            <li>
                                <v-checkbox dense hide-details :color="checkBoxColor" v-model="emailEnabled"
                                            :disabled="!ready" label="powiadomienia mailowe"/>
                                <ul>
                                    <li>
                                        <v-checkbox dense hide-details :color="checkBoxColor" :disabled="!emailEnabled"
                                                    v-model="emailHourBefore"
                                                    label="godzinę przed sesją"/>
                                    </li>
                                    <li>
                                        <v-checkbox dense hide-details :color="checkBoxColor" :disabled="!emailEnabled"
                                                    v-model="emailDayBefore"
                                                    label="dzień przed sesją"/>
                                    </li>
                                    <li>
                                        <v-checkbox dense hide-details :color="checkBoxColor" :disabled="!emailEnabled"
                                                    v-model="emailNewMessage"
                                                    label="nowa wiadomość tekstowa"/>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <div>
                            <v-text-field outlined :color="checkBoxColor" label="Email" v-model="email"
                                          @keyup="notificationForm.emailErrors = []"
                                          persistent-hint :disabled="!emailEnabled" type="email"
                                          :error-messages="notificationForm.emailErrors"
                                          hint="Podaj adres email, na który chcesz otrzymywać powiadomienia. Może być inny, niż adres główny."/>
                        </div>
                    </v-col>
                    <div class="separator"></div>
                    <v-col>
                        <ul>
                            <li>
                                <v-checkbox dense hide-details :color="checkBoxColor" v-model="smsEnabled"
                                            label="powiadomienia SMS" :disabled="!ready"/>
                                <ul>
                                    <li>
                                        <v-checkbox dense hide-details :color="checkBoxColor" :disabled="!smsEnabled"
                                                    v-model="smsHourBefore"
                                                    label="godzinę przed sesją"/>
                                    </li>
                                    <li>
                                        <v-checkbox dense hide-details :color="checkBoxColor" :disabled="!smsEnabled"
                                                    v-model="smsDayBefore"
                                                    label="dzień przed sesją"/>
                                    </li>
                                    <li>
                                        <v-checkbox dense hide-details :color="checkBoxColor" :disabled="!smsEnabled"
                                                    v-model="smsNewMessage"
                                                    label="nowa wiadomość tekstowa"/>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <div>
                            <v-text-field outlined :color="checkBoxColor" label="Numer telefonu"
                                          @keyup="notificationForm.phoneNumberErrors = []"
                                          :error-messages="notificationForm.phoneNumberErrors"
                                          persistent-hint :disabled="!smsEnabled" prefix="+48" v-model="phoneNumber"
                                          hint="Podaj numer telefonu, aby otrzymywać powiadomienia"/>
                        </div>
                    </v-col>
                </v-row>
                <v-row>
                    <v-spacer></v-spacer>
                    <v-col cols="auto">
                        <Button :disabled="!ready" @click.native="saveNotificationSettings"
                                placeholder="Zapisz zmiany"/>
                    </v-col>
                </v-row>
            </div>

            <div class="account" :class="{'no-margin': user == null || !user.isAPatient()}">
                <h4>Dane konta</h4>
                <div class="static-label">Email</div>
                <div>
                    <span class="static-form-control" style="margin-right: 24px;">{{ accountEmail }}</span>
                </div>
                <br>
                <a @click="showChangePasswordDialog">zmień hasło</a>
            </div>
        </div>
        <v-dialog v-model="changePassword" max-width="400">
            <v-card>
                <v-card-title>Zmiana hasła</v-card-title>
                <v-card-text>
                    <v-row style="flex-direction: column">
                        <v-col>
                            <v-text-field v-model="passwordForm.oldPassword.value" type="password"
                                          label="Aktualne hasło"
                                          :error-count="passwordForm.oldPassword.errors.length"
                                          outlined :error-messages="passwordForm.oldPassword.errors"
                                          @keyup="validateForm(false)" :color="checkBoxColor"/>
                        </v-col>
                        <v-col>
                            <v-text-field v-model="passwordForm.newPassword.value" type="password" outlined
                                          label="Nowe hasło"
                                          :error-count="passwordForm.newPassword.errors.length"
                                          :error-messages="passwordForm.newPassword.errors"
                                          @keyup="validateForm(false)" :color="checkBoxColor"/>
                        </v-col>
                        <v-col>
                            <v-text-field v-model="passwordForm.confirmPassword.value" type="password"
                                          label="Powtórz nowe hasło"
                                          outlined :color="checkBoxColor" @keyup="validateForm(false)"
                                          :error-count="passwordForm.confirmPassword.errors.length"
                                          :error-messages="passwordForm.confirmPassword.errors"
                            />
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <Button placeholder="Zmień hasło"
                            :icon="passwordForm.loading ? 'spinner' : 'lock'"
                            :spin="passwordForm.loading"
                            :disabled="passwordForm.loading"
                            @click.native="doChangePassword"/>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import MainMenu from "../MainMenu";
import Vue from 'vue';
import {mapActions, mapGetters, mapMutations} from "vuex";
import Button from "@/components/Form/Button";

export default Vue.extend({
    name: "SettingsPage",
    components: {
        Button,
        MainMenu,
    },
    data: function () {
        return {
            changePassword: false,
            passwordForm: {
                valid: true,
                loading: false,
                oldPassword: {
                    value: null,
                    errors: []
                },
                newPassword: {
                    value: null,
                    errors: []
                },
                confirmPassword: {
                    value: null,
                    errors: []
                }
            },
            notificationForm: {
                valid: true,
                emailErrors: [],
                phoneNumberErrors: []
            },
            checkBoxColor: '#000000'
        };
    },
    computed: {
        user() {
            return this.$store.getters['user/user'];
        },
        ready() {
            return this.getSettings() !== null;
        },
        email: {
            get() {
                return this.getSettings() && this.getSettings().notifications.email
            },
            set(value) {
                if (this.getSettings()) {
                    this.getSettings().notifications.email = value;
                }
            }
        },
        phoneNumber: {
            get() {
                return this.getSettings() && this.getSettings().notifications.phone_number
            },
            set(value) {
                if (this.getSettings()) {
                    this.getSettings().notifications.phone_number = value;
                }
            }
        },
        emailEnabled: {
            get() {
                return this.getSettings() && this.getSettings().notifications.email_enabled
            },
            set(value) {
                if (this.getSettings()) {
                    this.getSettings().notifications.email_enabled = value;
                }
            }
        },
        smsEnabled: {
            get() {
                return this.getSettings() && this.getSettings().notifications.sms_enabled
            },
            set(value) {
                if (this.getSettings()) {
                    this.getSettings().notifications.sms_enabled = value;
                }
            }
        },
        emailHourBefore: {
            get() {
                return this.isNotificationEnabled('session', 'email', '-1 hour');
            },
            set(value) {
                this.setNotificationEnabled('session', 'email', '-1 hour', value);
            }
        },
        smsHourBefore: {
            get() {
                return this.isNotificationEnabled('session', 'sms', '-1 hour');
            },
            set(value) {
                this.setNotificationEnabled('session', 'sms', '-1 hour', value);
            }
        },
        emailDayBefore: {
            get() {
                return this.isNotificationEnabled('session', 'email', '-1 day');
            },
            set(value) {
                this.setNotificationEnabled('session', 'email', '-1 day', value);
            }
        },
        smsDayBefore: {
            get() {
                return this.isNotificationEnabled('session', 'sms', '-1 day');
            },
            set(value) {
                this.setNotificationEnabled('session', 'sms', '-1 day', value);
            }
        },
        emailNewMessage: {
            get() {
                return this.isNotificationEnabled('message', 'email', null);
            },
            set(value) {
                this.setNotificationEnabled('message', 'email', null, value);
            }
        },
        smsNewMessage: {
            get() {
                return this.isNotificationEnabled('message', 'sms', null);
            },
            set(value) {
                this.setNotificationEnabled('message', 'sms', null, value);
            }
        },
        accountEmail: function () {
            return this.user?.email;
        }
    },
    methods: {
        ...mapGetters(['getSettings']),
        ...mapMutations(['snackbarShow']),
        ...mapActions(['fetchSettings', 'saveSettings', 'changePassword']),
        isNotificationEnabled(type, transport, interval) {
            return this.getSettings() && this.getSettings().notifications.settings
                .find(s => s.type === type && s.transport === transport && s.interval === interval) !== undefined;
        },
        setNotificationEnabled(type, transport, interval, enabled) {
            if (this.getSettings()) {
                if (enabled && !this.isNotificationEnabled(type, transport, interval)) {
                    this.getSettings().notifications.settings.push({
                        type: type, transport: transport, interval: interval
                    });
                } else if (!enabled && this.isNotificationEnabled(type, transport, interval)) {
                    this.getSettings().notifications.settings
                        .splice(this.getSettings()
                            .notifications
                            .settings
                            .findIndex(s => s.type === type && s.transport === transport && s.interval === interval), 1)
                }
            }
        },
        validateForm(strict) {
            this.passwordForm.oldPassword.errors = [];
            this.passwordForm.newPassword.errors = [];
            this.passwordForm.confirmPassword.errors = [];
            this.passwordForm.valid = true;

            const validateOldPassword = strict || this.passwordForm.oldPassword.value !== null;
            if (validateOldPassword && (this.passwordForm.oldPassword.value || '') === '') {
                this.passwordForm.oldPassword.errors = ['Pole jest wymagane'];
                this.passwordForm.valid = false;
            }
            const validateNewPassword = strict || this.passwordForm.newPassword.value !== null;
            if (validateNewPassword && (this.passwordForm.newPassword.value || '') === '') {
                this.passwordForm.newPassword.errors = ['Pole jest wymagane'];
                this.passwordForm.valid = false;
            }
            if (strict || this.passwordForm.confirmPassword.value !== null) {
                if ((this.passwordForm.confirmPassword.value || '') === '') {
                    this.passwordForm.confirmPassword.errors = ['Pole jest wymagane'];
                    this.passwordForm.valid = false;
                } else if (this.passwordForm.newPassword.value !== this.passwordForm.confirmPassword.value) {
                    this.passwordForm.confirmPassword.errors = ['Hasła nie są takie same'];
                    this.passwordForm.valid = false;
                }
            }
        },
        showChangePasswordDialog() {
            this.changePassword = true;
            this.passwordForm.oldPassword = {value: null, errors: []};
            this.passwordForm.newPassword = {value: null, errors: []};
            this.passwordForm.confirmPassword = {value: null, errors: []};
            this.passwordForm.valid = true;
        },
        doChangePassword() {
            this.validateForm(true);
            if (this.passwordForm.valid) {
                this.passwordForm.loading = true;
                this.$store.dispatch('changePassword', {
                    currentPassword: this.passwordForm.oldPassword.value,
                    newPassword: this.passwordForm.newPassword.value,
                    confirmPassword: this.passwordForm.confirmPassword.value
                }).then(response => {
                    if (response.status === 200) {
                        this.snackbarShow('Hasło zostało zmienione');
                        this.changePassword = false;
                    } else if (response.data.reason) {
                        if (response.data.reason === 'INVALID_CURRENT_PASSWORD') {
                            this.passwordForm.oldPassword.errors = ['Niepoprawne hasło']
                            this.passwordForm.valid = false;
                        }
                    } else {
                        this.snackbarShow('Wystąpił błąd podczas zmiany hasła')
                    }
                }).finally(() => this.passwordForm.loading = false);
            }
        },
        saveNotificationSettings() {
            let valid = true;
            if (this.emailEnabled && this.email.trim() === '') {
                valid = false;
                this.notificationForm.emailErrors = ['Podaj email do powiadomień'];
            }
            if (this.smsEnabled && this.phoneNumber.trim() === '') {
                valid = false;
                this.notificationForm.phoneNumberErrors = ['Podaj numer telefonu do powiadomień'];
            }
            if (valid) {
                this.saveSettings()
                    .then(response => {
                        if (response.status === 200) {
                            this.snackbarShow('Zmiany w ustawieniach powiadomień zostały zapisane');
                        } else {
                            return Promise.reject();
                        }
                    })
                    .catch(() => this.snackbarShow('Wystąpił błąd podczas zapisu'));
            }
        }
    }
})
</script>

<style scoped lang="scss">
@import '~vuetify/src/styles/settings/_variables.scss';

#settings-main {
    padding: 24px;
    height: calc(100vh - 71px);
    position: absolute;
    width: 100%;
    top: 71px;
    overflow-y: auto;
}

.card {
    display: inline-block;
    max-width: 700px;
    width: 100%;
}

h4 {
    font-size: 18px;
    font-weight: 700;
}

ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 48px;
}

ul ul {
    padding-left: 32px;
}

.separator {
    width: 1px;
    background-color: #DBDBDB;
}

.account {
    margin-top: 40px;
    margin-bottom: 40px;
}
.account.no-margin {
    margin-top: 0;
}

a {
    font-size: 14px;
    color: #1a1a1a60 !important;
}

a:hover {
    text-decoration: underline;
}
</style>
