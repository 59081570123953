import { render, staticRenderFns } from "./HpHowItWorks2.vue?vue&type=template&id=66e49b91&scoped=true&"
import script from "./HpHowItWorks2.vue?vue&type=script&lang=js&"
export * from "./HpHowItWorks2.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66e49b91",
  null
  
)

export default component.exports