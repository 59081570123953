<template>
    <svg width="52" height="40" viewBox="0 0 52 41" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="6.5" y="0.5" width="40" height="40" rx="0.5" fill="#1A1A1A" stroke="#1A1A1A"/>
        <circle cx="6" cy="20" r="6" fill="#FAF9F9"/>
        <circle cx="26" cy="20" r="6" fill="#FAF9F9"/>
        <circle cx="46" cy="20" r="6" fill="#FAF9F9"/>
    </svg>
</template>

<script>
    export default {
        name: "HpHowItWorks2"
    }
</script>

<style scoped>

</style>
