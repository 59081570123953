
export function buildHeaders(headers = {}, method = null) {

    const defaultHeaders = {
        'Accept': 'application/json',
        'Content-Type': method !== null && method === 'PATCH' ? 'application/merge-patch+json' : 'application/json',
        // 'Cookies': 'XDEBUG_SESSION=PHPSTORM;'
        // 'Cookies': {
        //     'XDEBUG_SESSION': 'PHPSTORM'
        // }
    };

    headers = {...defaultHeaders, ...headers };

    // let token = AccountStore.getters.getToken();
    let token = localStorage.getItem('token');

    if (typeof token === "string" && token.length) {
        headers['Authorization'] = 'Bearer ' + token;
    }

    return headers;
}
