<template>
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="39" height="39" rx="0.5" fill="#1A1A1A" stroke="#1A1A1A"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M20.5 9C13.0562 9 7 15.0794 7 22.5518C7 25.7434 8.10485 28.6809 9.95076 30.9991C10.6162 30.1516 11.3744 29.4086 12.2072 28.7907C14.5337 27.0332 17.4781 26.0652 20.5 26.0652C23.5219 26.0652 26.4663 27.0332 28.79 28.7907C29.6237 29.4085 30.3828 30.1517 31.0489 30.9995C32.895 28.6812 34 25.7436 34 22.5518C34 15.0794 27.9438 9 20.5 9ZM20.4999 15.023C19.195 15.023 18.0151 15.4867 17.1766 16.3293C16.3309 17.1806 15.9167 18.3376 16.0139 19.5874C16.2059 22.0499 18.2215 24.0576 20.4999 24.0576C22.7783 24.0576 24.79 22.0499 24.9859 19.588C25.0838 18.3482 24.6689 17.1951 23.8159 16.3368C22.9742 15.4898 21.8049 15.023 20.4999 15.023Z" fill="#FAF9F9"/>
    </svg>
</template>

<script>
    export default {
        name: "HpHowItWorks3"
    }
</script>

<style scoped>

</style>
