<template>
    <ul :class="{invert: inverted === true}">
        <li :class="{active: activeItem === 1}"><a @click.prevent="scroll('availability')" href="#availability"></a></li>
        <li :class="{active: activeItem === 2}"><a @click.prevent="scroll('for-whom')" href="#for-whom"></a></li>
        <li :class="{active: activeItem === 3}"><a @click.prevent="scroll('our-team')" href="#our-team"></a></li>
        <li :class="{active: activeItem === 4}"><a @click.prevent="scroll('good-price')" href="#good-price"></a></li>
        <li :class="{active: activeItem === 5}"><a @click.prevent="scroll('security')" href="#security"></a></li>
        <li v-if="activeItem < 5" class="next"><a @click.prevent="scroll(next)" :href="'#' + next"></a></li>
        <li v-else-if="activeItem === 5" class="next"><a style="cursor: default" @click.prevent></a></li>
    </ul>
</template>

<script>
import {scrollToElement} from "@/service/Scroll";

export default {
    name: "FaqIndicator",
    props: ['activeItem', 'inverted'],
    computed: {
        next() {
            if (this.activeItem === 1) {
                return 'for-whom';
            } else if (this.activeItem === 2) {
                return 'our-team';
            } else if (this.activeItem === 3) {
                return 'good-price';
            } else if (this.activeItem === 4) {
                return 'security';
            }
            return null;
        }
    },
    methods: {
        scroll(id) {
            scrollToElement(id);
        }
    }
}
</script>

<style scoped>
ul {
    margin-top: 20px;
    float: right;
    list-style: none;
}

li {
    width: 20px;
    height: 20px;
    padding-left: 6px;
    padding-right: 6px;
    padding-bottom: 40px;
}

li.next {
    width: 20px;
    height: 20px;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 40px;
}

li a {
    border: 4px solid #979797;
    border-radius: 50%;
    width: 8px;
    height: 8px;
    display: block;
}

li.next a {
    display: block;
    width: 20px;
    height: 20px;
    border-radius: inherit;
    border-style: solid;
    border-width: 16px 10px 0 10px;
    border-color: #1a1a1a transparent transparent transparent;
}
ul.invert li.next a {
    border-color: #fff transparent transparent transparent;
}

li.active a {
    border-color: #1a1a1a;
}
ul.invert li.active a {
    border-color: #fff;
}
</style>
