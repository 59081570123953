import Vue from 'vue';
import Vuex from 'vuex';
import UserRepository from "../../../repository/Resource/UserRepository";
import Router from "../../../service/Router";
import TherapistRepository from "../../../repository/Resource/TherapistRepository";
import ConsultantRepository from "@/repository/Resource/ConsultantRepository";
import dayjs from "dayjs";
import UIEvents from "@/service/store/UIEvents";

dayjs.locale(require('dayjs/locale/pl'));
dayjs.extend(require('dayjs/plugin/utc'));
dayjs.extend(require('dayjs/plugin/isoWeek'));

Vue.use(Vuex);

const TOKEN_KEY = 'token';
let fetchingUserMe = false;
let sessionEndTimeout;

const getDefaultState = () => {
    return {
        user: null,
        token: null,
        loggingIn: false,
        loginError: null,
        therapies: null,
        therapistConsultant: null,
        settings: null,
        newChatEnabled: false
    };
}

export default {
    state: getDefaultState(),
    getters: {
        getUser: (state) => {
            return state.user;
        },
        getToken(state) {
            return state.token;
        },
        isLogged: (state, getters) => {
            let token = getters.getToken;

            if (null === token) {
                return false;
            }

            return token.length >= 0;
        },
        getConsultation(state) {
            return state.therapistConsultant;
        },
        getNearestSession(state) {
            return state.user && state.user.nearest_session;
        },
        getSettings(state) {
            return state.settings;
        },
        isNewChatEnabled(state) {
            return state.newChatEnabled;
        }
    },
    mutations: {
        loginStart: state => state.loggingIn = true,
        loginStop: (state, errorMessage) => {
            state.loggingIn = false;
            state.loginError = errorMessage;
        },
        setUser(state, user) {
            state.user = user;
        },
        saveToken(state, token) {
            state.token = token;
        },
        removeToken(state) {
            state.token = null;
        },
        setNearestSession(state, session) {
            state.user = {
                ...state.user,
                nearest_session: session,
            }
        },
        setActiveTherapy(state, therapy) {
            state.user = {
                ...state.user,
                active_therapy: therapy
            }
        },
        subtractSession(state) {
            if (state.user.active_subscription) {
                const count = state.user.active_subscription.sessions_left - 1;
                state.user = {
                    ...state.user,
                    active_subscription: {
                        ...state.user.active_subscription,
                        sessions_left: count < 0 ? 0 : count
                    }
                }
            }
        },
        addSession(state) {
            if (state.user.active_subscription) {
                const count = state.user.active_subscription.sessions_left + 1;
                state.user = {
                    ...state.user,
                    active_subscription: {
                        ...state.user.active_subscription,
                        sessions_left: count
                    }
                }
            }
        },
        setConsultation(state, therapistConsultant) {
            state.therapistConsultant = therapistConsultant;
        },
        resetAccountStore(state) {
            Object.assign(state, getDefaultState());
        },
        setSettings(state, settings) {
            state.settings = settings;
        },
        setNewChatEnabled(state, enabled) {
            state.newChatEnabled = enabled;
        }
    },
    actions: {
        doLogin: ({commit, dispatch}, data) => {
            commit('loginStart');
            return UserRepository
                .login(data.username, data.password)
                .then((response) => {
                    commit('saveToken', response.data.token);
                    localStorage.setItem(TOKEN_KEY, response.data.token);
                    Router.push('/');
                    dispatch('fetchUserMe');
                })
                .catch(() => commit('removeToken'))
                .finally(() => commit('loginStop', null));
        },
        doLogout: ({dispatch}) => {
            dispatch('resetStore');
            localStorage.removeItem(TOKEN_KEY);
            Router.push('/login');
        },
        fetchUserMe: ({commit}) => {
            if (fetchingUserMe) {
                return;
            }
            fetchingUserMe = true;
            return UserRepository
                .getMe()
                .then((result) => {
                    if (typeof result === "undefined") {
                        return {};
                    }
                    if (result.data.nearest_session) {
                        result.data.nearest_session.date = dayjs(result.data.nearest_session.date);
                        if (sessionEndTimeout) {
                            clearTimeout(sessionEndTimeout);
                        }
                        const isSessionFinished = function () {
                            const sessionEndDate = result.data.nearest_session.date
                                .add(result.data.nearest_session.duration, 'minute');
                            if (dayjs().isAfter(sessionEndDate)) {
                                clearTimeout(sessionEndTimeout);
                                UIEvents.updated();
                            }
                            sessionEndTimeout = setTimeout(() => isSessionFinished(), 60000);
                        }
                        sessionEndTimeout = setTimeout(() => isSessionFinished(), 60000);
                    }
                    commit('setUser', {
                        ...result.data,
                        isAPatient: function () {
                            return !this.therapist && !this.consultant;
                        },
                        isATherapist: function () {
                            return this.therapist !== null;
                        },
                        isAConsultant: function () {
                            return this.consultant !== null;
                        }
                    });
                    commit('user/setUser', {
                        ...result.data,
                        isAPatient: function () {
                            return !this.therapist && !this.consultant;
                        },
                        isATherapist: function () {
                            return this.therapist !== null;
                        },
                        isAConsultant: function () {
                            return this.consultant !== null;
                        }
                    });
                    return result.data;
                })
                .finally(() => fetchingUserMe = false)
                ;
        },
        fetchSettings: (context) => {
            return UserRepository
                .getSettings()
                .then(result => {
                    if (result.status === 200) {
                        context.commit('setSettings', result.data);
                        return result.data;
                    }
                    context.commit('setSettings', {});
                    return {};
                })
        },
        fetchTherapies: ({commit}, therapistId) => {
            return TherapistRepository
                .getTherapies(therapistId)
                .then((result) => {
                    commit('therapist_chat/setChats', result);
                    result.forEach((therapy) => {
                        commit('ADD_MEMBERS', [therapy.user]);
                    })
                    return result;
                })
                ;
        },
        fetchConsultations: ({commit}, therapistId) => {
            return ConsultantRepository
                .getTherapies(therapistId)
                .then((result) => {
                    commit('therapist_chat/setChats', result);
                    result.forEach((therapy) => {
                        commit('ADD_MEMBERS', [therapy.user]);
                    })
                    return result;
                })
                ;
        },
        resetPasswordByUsername: (context, username) => {
            return UserRepository
                .resetPasswordByUsername(username)
                ;
        },
        changePassword: (context, {currentPassword, newPassword, confirmPassword}) => {
            return UserRepository
                .changePassword(currentPassword, newPassword, confirmPassword)
                ;
        },
        setupPasswordByHash: (context, {hash, password}) => {
            return UserRepository
                .setupPassword(hash, password)
                ;
        },
        saveSettings(context) {
            return UserRepository
                .saveSettings(context.state.settings);
        }
    }
};
