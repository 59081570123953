<template>
    <div class="plan" :class="{'trial': trial === true}">
        <span class="plan-name"><strong>{{ name }}</strong></span>
        <span class="plan-price"><strong>{{ price }}</strong> <small>zł/{{ trial === true ? 'pierwszy tydzień' : 'tygodniowo' }}</small></span>
        <span :class="{'hide':!answers,'preserve-height':preserveHeight !== false}" class="plan-trait-answers">
            <strong>{{ answers }} codziennych</strong>
            <br>odpowiedzi od terapeuty</span>
        <span :class="{'hide':!sessions,'preserve-height':preserveHeight !== false}" class="plan-trait-sessions">
            <strong>{{ sessions }} miesięcznie</strong>
            <br>
            <span v-if="sessions === 1">{{ sessionDuration }} minutowa sesja online (video lub tekstowa)</span>
            <span v-else>{{ sessionDuration }} minutowe sesje online (video lub tekstowe)</span>
        </span>
        <span v-if="trial !== true" class="plan-footer">Opłata pobierana jest za 4 tygodnie</span>
    </div>
</template>

<script>
export default {
    name: "Plan",
    props: ['name', 'price', 'answers', 'sessions', 'sessionDuration', 'preserveHeight', 'hideFooter', 'trial']
}
</script>

<style scoped lang="scss">
@import '~vuetify/src/styles/settings/_variables.scss';

.plan span {
    display: block;
}

.plan {
    border-radius: 15px;
    background-color: #fff;
    box-shadow: rgba(8, 30, 45, 0.074) 0 -2px 14px;
    padding: 20px;

    @media #{map-get($display-breakpoints, 'xs-only')} {
        text-align: center;
    }
}

.plan-name {
    margin-bottom: 20px;
}

.plan-price, .plan-trait-answers, .plan-trait-sessions {
    margin-bottom: 40px;

    @media #{map-get($display-breakpoints, 'xs-only')} {
        margin-bottom: 20px;
    }
}

.trial {
    .plan-price, .plan-trait-answers, .plan-trait-sessions {
        margin-bottom: 0;
    }
}

.plan-price small, .plan-footer {
    font-size: 12px;
}

.plan span.hide {
    display: none;

    &.preserve-height {
        display: block;
        visibility: hidden;
    }

    @media #{map-get($display-breakpoints, 'xs-only')} {
        display: none;
    }
}
</style>
