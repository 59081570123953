import Repository from '../Repository';
import { buildHeaders } from '../Headers';

const ENDPOINT = '/api/plans';

export default {
    getOneById(id) {
        return Repository
            .get(`${ENDPOINT}/${id}`, {
                headers: buildHeaders()
            })
            .then((response) => {
                return response.data;
            })
            .catch(() => {
                this.errored = true
            })
    }
}
