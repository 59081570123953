import {Module, Mutation, VuexModule} from "vuex-module-decorators";
import {User} from "@/dataModel/user";

@Module({namespaced: true, name: 'user'})
export class UserStore extends VuexModule {

    private _user: User | null = null;

    get user() {
        return this._user;
    }

    @Mutation
    setUser(user: User | null) {
        this._user = user;
    }
}
