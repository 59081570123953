import Repository from './Repository';
import {buildHeaders} from './Headers';

const ENDPOINT = '/api/checkout';

export default {
    checkout(plan) {
        return Repository
            .post(ENDPOINT, {
                plan: plan
            }, {
                headers: buildHeaders()
            })
            .then((response) => response.data.session_id)
            .catch(() => this.errored = true);
    }
}
