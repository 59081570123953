<template>
    <div v-if="thumb" style="display: inline-block; border:1px solid #ddd; padding: 5px; border-radius: 5px; width: 100px; height: 60px; position: relative">
        <font-awesome-icon icon="volume-up" size="2x"/>
        <span style="color:#000;width:20px;height:20px;position:absolute;right:0;">
            <font-awesome-icon icon="times" @click="removeResource"/>
        </span>
    </div>
    <img v-else-if="!this.file" style="width: 33px; height: 33px;" src="../../assets/loading-ring.gif">
    <audio v-else controls preload="auto" :src="this.file"></audio>
</template>

<script>
    import StorageRepository from "../../repository/Resource/StorageRepository";
    import {mapGetters, mapMutations} from "vuex";

    export default {
        name: "AudioPlayer",
        props: {
            storageId: {
                type: String,
                required: true,
            },
            mimeType: {
                type: String,
                required: true,
            },
            thumb: {
                type: Boolean
            }
        },
        data: () => {
            return {
                file: null,
            };
        },
        methods: {
            removeResource() {
                this.$store.dispatch('message/removeResource', this.storageId);
            }
        },
        created() {
            StorageRepository
                .preview(this.storageId)
                .then((result) => {
                    this.file = `data:${this.mimeType};base64,${result.data}`;
                    setTimeout(() => this.$emit('data-loaded'), 10);
                })
            ;
        }
    }
</script>

<style scoped>

</style>
