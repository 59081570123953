<template>
    <div>
        <v-card>
            <v-card-title>Umawianie sesji</v-card-title>
            <v-card-subtitle v-if="selectHour">{{ dayjsDate.format('D MMMM YYYY') }}</v-card-subtitle>
            <v-select filled v-model="sessionType" :items="sessionTypes" item-text="description" item-value="value"></v-select>
            <v-date-picker locale="pl" v-if="selectDate" v-model="date" @change="setDate"
                           full-width first-day-of-week="1"></v-date-picker>
            <v-time-picker v-if="selectHour" v-model="hour" full-width format="24h"></v-time-picker>
            <v-card-actions v-if="selectHour">
                <v-btn @click="changeDate">Zmień datę</v-btn>
                <v-spacer/>
                <v-btn @click="create">Utwórz</v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import {mapMutations} from "vuex";
import dayjs from "dayjs";
import SessionRepository from "@/repository/Resource/SessionRepository";

export default {
    name: "CreateSessionDialog",
    data: function () {
        return {
            selectDate: true,
            selectHour: false,
            sessionType: null,
            sessionTypes: [],
            date: dayjs().format('YYYY-MM-DD'),
            hour: "12:00"
        }
    },
    computed: {
        dayjsDate() {
            return dayjs(this.date);
        }
    },
    mounted() {
        SessionRepository.getSessionTypes()
            .then((sessionTypes) => {
                this.sessionType = sessionTypes[0].id;
                return this.sessionTypes = sessionTypes.map(sessionType => ({
                    value: sessionType.id,
                    description: sessionType.name
                }));
            });
    },
    methods: {
        ...mapMutations(['createSession']),
        setDate: function () {
            this.selectDate = false;
            this.selectHour = true;
        },
        changeDate: function () {
            this.selectHour = false;
            this.selectDate = true;
        },
        create: function () {
            const start = dayjs(`${this.date}T${this.hour}:00`);
            this.$emit('date-selected', {
                date: start.format('YYYY-MM-DDTHH:mm:ssZ'),
                sessionType: this.sessionType
            });
        }
    }
}
</script>

<style scoped>
div {
    background-color: #fff;
}
</style>
