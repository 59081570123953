<template>
    <div class="authorization">
        <div class="container fill-height">
            <div class="row hidden-xs-only options justify-space-between">
                <div class="col-auto">
                    <img class="logo" :src="require('../../../assets/hp/logo.png')">
                </div>
                <div class="col-auto">
                    <router-link :to="{ name: 'route_register' }" style="color:#1a1a1a;font-weight:500;">
                        Nie masz konta?
                    </router-link>
                    <router-link :to="{ name: 'route_register' }" class="btn btn-sm btn-black">
                        Zarejestruj się
                    </router-link>
                </div>
            </div>
            <div class="row form">
                <div>
                    <img class="logo hidden-sm-and-up" :src="require('../../../assets/hp/logo.png')">

                    <h2>Zdefiniuj nowe hasło</h2>

                    <form @submit.prevent="setupPassword" style="padding-top:30px;">
                        <div class="form-group">
                            <input type="password" v-model="password1" id="password1" name="password1" placeholder="Hasło"
                                   :class="{ 'is-invalid': submitted && !password1 }"/>
                            <div v-show="submitted && !password1" class="error-invalid">Hasło jest wymagane</div>
                            <div v-show="submitted && invalidCredentials" class="error-invalid">Bad credentials</div>
                        </div>
                        <div class="form-group">
                            <input type="password" v-model="password2" id="password2" name="password2"
                                   placeholder="Powtórz hasło" :class="{ 'is-invalid': submitted && !password2 }"/>
                            <div v-show="submitted && !password2" class="error-invalid">Powtórzenie hasła jest wymagane
                            </div>
                        </div>
                        <button class="btn btn-xs btn-black btn-fluid">Zapisz</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Router from "../../../service/Router";
import config from "../../../config/config";

export default {
    name: "ResetPasswordPage",
    data() {
        return {
            password1: '',
            password2: '',
            invalidCredentials: false,
            submitted: false
        }
    },
    computed: {
        appName: () => {
            return config.APP_NAME;
        },
    },
    methods: {
        setupPassword() {
            this.submitted = true;
            this.$store.commit('loginStart');

            if (!this.password1 || !this.password2) {
                this.invalidCredentials = true;
                return;
            }

            if (this.password1 !== this.password2) {
                this.invalidCredentials = true;
                return;
            }

            this.$store
                .dispatch('setupPasswordByHash', {hash: this.$route.params.hash, password: this.password1})
                .then((response) => {
                    if (response.status === 204) {
                        Router.push({name: 'route_login'});
                    } else {
                        this.invalidCredentials = true;
                    }
                })
                .catch(() => {
                    this.invalidCredentials = true;
                    Router.push({name: 'route_login'});
                })
            ;
        },
    }
}
</script>

<style scoped>
.error-invalid {
    position: relative;
    top: -18px;
    color: #e98da8;
    text-align: right;
    font-size: 12px;
}

.is-invalid {
    border-color: #e98da8;
}
</style>
