
import MainMenu from "./MainMenu.vue";
import ConfirmationDialog from "@/components/Common/ConfirmationDialog.vue";
import dayjs from "dayjs";
import Base from "@/mixins/Base";
import Component, {mixins} from "vue-class-component";
import {TherapySession} from "@/service/store/Module/domain";
import Button from "@/components/Form/Button.vue";
import {therapySessionStore, userStore} from "@/service/store";

@Component<TherapyPage>({
    name: "TherapyPage",
    components: {
        Button,
        ConfirmationDialog,
        MainMenu,
    }
})
export default class TherapyPage extends mixins(Base) {
    cancelling = false;

    beforeDestroy() {
        // todo should we allow user to browse page during video call, i.e., to read messages from chat?
        // this.hangUp();
    }

    get user() {
        return userStore.user;
    }

    get nearestSession() {
        const nearestSession: TherapySession | null = this.getter('getNearestSession');
        nearestSession && therapySessionStore.checkNewChat(nearestSession.chat_channel);
        return nearestSession;
    }

    get displayNearestSessionInfo(): boolean {
        return this.nearestSession != null && !this.videoChatStarted;
    }

    get displayVideoChat(): boolean {
        return this.videoChatStarted;
    }

    get videoChatStarted() {
        return therapySessionStore.videoChatStarted;
    }

    get muted() {
        return therapySessionStore.muted;
    }

    get videoStopped() {
        return therapySessionStore.videoStopped;
    }

    get localStream(): MediaStream | null {
        return therapySessionStore.localStream;
    }

    get remoteStream(): MediaStream | null {
        return therapySessionStore.remoteStream;
    }

    get newChatEnabled(): boolean {
        return therapySessionStore.newChatEnabled;
    }

    get cancelTextKey(): string {
        return this.freeOfCharge ? 'cancel_session_confirm_refund' : 'cancel_session_confirm_no_refund';
    }

    private get freeOfCharge(): boolean {
        const session = this.nearestSession;
        if (!session) {
            return false;
        }
        const now = dayjs();
        return now.isBefore(session.date) && now.add(24, 'hours').isBefore(session.date);
    }

    async startSession() {
        await therapySessionStore.invite({
            localUserId: this.user!.id,
            remoteUserId: this.nearestSession!.peer_id
        });
    }

    hangUp() {
        therapySessionStore.hangUp();
    }

    toggleMute() {
        therapySessionStore.toggleMute();
    }

    toggleVideoStopped() {
        therapySessionStore.toggleVideoStopped();
    }

    cancelSession() {
        this.action('cancelSession', this.nearestSession!);
        this.cancelling = false;
    }

    openCancelDialog() {
        this.cancelling = true;
    }

    hideCancelDialog() {
        this.cancelling = false;
    }
}
