<template>
    <v-dialog :value="!displayed" max-width="700" style="border-radius: 15px;" @click:outside="setDisplayed">
        <div class="offer">
            <div>
                <h2>Oferta dla nowych użytkowników</h2>
                <v-row class="plans">
                    <v-col cols="12" sm="6">
                        <Plan name="Tydzień psychoterapii tekstowej" :price="prices.trialWithoutSession"
                              :preserve-height="false" :trial="true"/>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col xs="12" sm="6">
                        <Plan name="Tydzień psychoterapii tekstowej i 30 minutowa sesja"
                              :price="prices.trialWithSession" :preserve-height="false" :trial="true"/>
                    </v-col>
                </v-row>
                <p>Zarejestruj się i wykup pojedynczy tydzień próbny.</p>
                <router-link :to="{ name: 'route_register' }"
                             @click="setDisplayed"
                             class="btn btn-black text-white">
                    Zarejestruj się
                </router-link>
            </div>
        </div>
    </v-dialog>
</template>

<script>
import Plan from "@/components/Common/Plan";
import prices from '../../prices';

export default {
    name: "WelcomeOffer",
    components: {Plan},
    data() {
        return {prices};
    },
    computed: {
        displayed: () => {
            return localStorage.getItem('_offer_displayed') === '1';
        }
    },
    methods: {
        setDisplayed: () => localStorage.setItem('_offer_displayed', '1')
    }
}
</script>

<style scoped lang="scss">
@import '~vuetify/src/styles/settings/_variables.scss';

.offer {
    padding: 25px;
    background-color: #fcf3f2;
    border-radius: 15px;
    text-align: center;
    background-image: url('../../assets/hp/offer-bg2.png');
    background-position: 50px 25%;
}

.offer > div {
    background-image: url('../../assets/hp/offer-bg1.png');
    background-position: right 25px center;
}

@media #{map-get($display-breakpoints, 'xs-only')} {
    .offer {
        background-position: 25px;
    }
    .offer > div {
        background-position: right;
    }
}

.offer h2 {
    margin: 0 0 15px 0;
    padding: 0;
    font-size: 24px;
    line-height: 30px;
    font-weight: 700;
}

.offer p {
    margin: 0;
    padding: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 27px;

    @media #{map-get($display-breakpoints, 'xs-only')} {
        font-size: 12px;
        line-height: 21px;
    }
}

.btn {
    display: inline-block;
    margin-top: 20px;
}

.plans {
    margin: 20px 50px;
    @media #{map-get($display-breakpoints, 'xs-only')} {
        margin: 20px 5%;
    }
}
</style>
