<template>
    <v-app style="background-color:#faf9f9;">
        <Alert/>
        <div v-if="this.getUser() !== null && !this.getSocket().isConnected"
             style="padding: 10px; background-color: #c00; border-radius: 5px; z-index: 1000;
                position: fixed; bottom: 20px; left: 20px; color: white;">
            Brak połączenia z serwerem
        </div>
        <v-content>
            <transition name="fade">
                <router-view id="content" :key="$route.path"/>
            </transition>
            <div class="snackbar" :class="{'visible': this.isSnackbarVisible(), 'displayed': this.isSnackbarDisplayed()}">
                {{ getSnackbarMessage() }}
            </div>
        </v-content>
    </v-app>
</template>

<script>

    import { mapActions, mapGetters } from 'vuex';
    import UriMixin from "./mixins/UriMixin";
    import Vue from "vue";
    import VueNativeSock from "vue-native-websocket";
    import {store} from "@/service/store";
    import Alert from "@/components/Alert/Alert";
    import UIEvents from "@/service/store/UIEvents";

    const CHAT_HOST = process.env.VUE_APP_CHAT_HOST
        ? (process.env.VUE_APP_CHAT_PORT
            ? `${process.env.VUE_APP_CHAT_HOST}:${process.env.VUE_APP_CHAT_PORT}`
            : process.env.VUE_APP_CHAT_HOST)
        : 'chat.mojterapeuta.app';
    const WS_PROTOCOL = process.env.VUE_APP_WS_PROTOCOL || 'wss';

    export default {
        name: 'App',
        components: {Alert},
        mixins: [
            UriMixin,
        ],
        computed: {
            isLoggedIn: function () {
                return !!this.$store.getters.isLogged;
            },
        },
        methods: {
            ...mapActions([
                'loadCurrentChatMessages',
                'fetchUserMe',
                'doLogout',
            ]),
            ...mapGetters([
                'getSocket',
                'getUser',
                'getConsultation',
                'isSnackbarVisible',
                'isSnackbarDisplayed',
                'getSnackbarMessage'
            ]),

        },
        mounted() {
            this.$store.commit('setWindowWidth', window.innerWidth);
            this.$store.commit('setWindowHeight', window.visualViewport ? window.visualViewport.height : window.innerHeight);
            this.$store.commit('setBottomPanelHeight',  window.innerWidth < 800 ? 89 : 67);
            window.scroll(0, 1);
            window
                .addEventListener('resize', () => {
                    this.$store.commit('setWindowWidth', window.innerWidth);
                    this.$store.commit('setWindowHeight', window.visualViewport ? window.visualViewport.height : window.innerHeight);
                    const bottomPanel = document.getElementById('panel-bottom');
                    if (bottomPanel) {
                        this.$store.commit('setBottomPanelHeight', bottomPanel.scrollHeight - (this.$store.getters.getWindowWidth < 800 ? 20 : 0));
                    }
                })
            ;
            this.$store.dispatch('sendingMessages/loadFromStorage');
            UIEvents.setListener(() => {
                this.fetchUserMe();
                this.loadCurrentChatMessages();
            });
        },
        data: () => ({
            windowWidth: null,
        }),
        watch: {
            isLoggedIn(newValue, oldValue) {
                console.log(`Updating from ${oldValue} to ${newValue}`);
                if (true === newValue) {
                    this.$store
                        .dispatch('fetchUserMe')
                        .then((result) => {
                            if (result.therapist) {
                                // you are therapist
                                this.$store.dispatch('fetchTherapies', result.therapist.id);
                                if (this.$router.currentRoute.name === 'route_consultation') {
                                    this.$router.push({name: 'route_calendar'});
                                }
                            } else if (result.consultant) {
                                // you are consultant
                                this.$store.dispatch('fetchConsultations', result.consultant.id);
                                if (this.$router.currentRoute.name === 'route_consultation') {
                                    this.$router.push({name: 'route_chat'});
                                }
                            } else {
                                // you are user who is a patient
                                // else if (typeof result.active_therapy !== "undefined" && result.active_therapy && result.active_therapy.id) {
                                //     this.$store.commit('SET_CURRENT_CHAT_CHANNEL_ID', result.active_therapy.chat_channel)
                                //     this.$store.dispatch('loadChatChannel', this.uriToId(result.active_therapy.chat_channel));
                                // }

                                if (result.active_therapy) {
                                    this.$store.dispatch('loadChatChannel', this.uriToId(result.active_therapy.chat_channel));
                                    if (this.$router.currentRoute.name === 'route_consultation') {
                                        this.$router.push({name: 'route_chat'});
                                    }
                                } else if (result.consultation) {
                                    this.$store.dispatch('loadChatChannel', this.uriToId(result.consultation.chat_channel));
                                    if (this.$router.currentRoute.name === 'route_chat') {
                                        this.$router.push({name: 'route_consultation'});
                                    }
                                }

                                this.$store.dispatch('fetchSettings');
                            }
                        });

                    // connect WS
                    Vue.use(VueNativeSock, `${WS_PROTOCOL}://${CHAT_HOST}/text?token=${this.$store.getters.getToken}`, {
                        reconnection: true,
                        store: store,
                        format: 'json',
                    })
                } else {
                    this.$store.commit('setUser', null);
                    this.$store.commit('user/setUser', null);
                    this.$store.commit('therapist_chat/setChats', []);
                    this.$store.commit('SET_MESSAGES', []);
                    this.$store.commit('RESET_MEMBERS');
                }
            },
        },
        created () {
            this.$store.commit('saveToken', localStorage.getItem('token'));
        },
    };
</script>

<style lang="scss">
    @import '~vuetify/src/styles/settings/_variables.scss';

    html, body, .v-application {
        font-family: "Inter", sans-serif;
        //overflow: hidden;
        overflow-y: auto;
    }

    .row {
        margin: 0;
    }

    .cursor { cursor:pointer;cursor:hand; }

    h2 { font-size:52px;font-weight:bold;color:#1a1a1a;margin-top:40px;margin-bottom:40px; }
    h2 { font-size:42px;font-weight:bold;color:#1a1a1a;margin-top:40px;margin-bottom:40px; }
    h3 {
        font-size:32px;
        font-weight:bold;
        color:#1a1a1a;
        line-height:1.5;
        margin: 40px 0;
    }
    /* hp, plans, headers */
    h5 {
         font-size:18px;margin:0 0 15px 0;
    }
    h6 { opacity:0.5;text-transform:uppercase;color:#081E2D;font-size:18px;font-weight:normal; }


    p {
        font-size:18px;
        /*font-weight:regular;*/
        line-height:2;
        color:#1a1a1a;
    }
    .v-application a { color:#000; }

    .card {
        border-radius: 15px;
        background-color: #fff;
        box-shadow: rgba(8, 30, 45, 0.074) 0 -2px 14px;
        padding: 20px;
    }

    .shine {
        background: #f6f7f8 linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%) no-repeat;
        background-size: 1000px 100%;
        animation : shimmer 2s infinite;
    }
    .shine.darker {
        background: #e8e8e8 linear-gradient(to right, #e8e8e8 0%, #e0e0e0 20%, #e8e8e8 40%, #e8e8e8 100%) no-repeat;
    }

    @keyframes shimmer {
        0% {
            background-position: -1000px 0;
        }
        100% {
            background-position: 1000px 0;
        }
    }

    .text-white { color:#fff !important; }
    .text-green { color:#396e51 !important; }
    a { text-decoration:none;color:#000; }
    a:hover, a:visited, a:active { text-decoration:none;color:#000; }
    .btn { padding:15px;border-radius:2px;text-decoration:none; }
    .btn-sm { padding:12px 25px; }
    .btn-xs { padding:9px 12px; }
    .btn-black { color:#fff;background-color:#000;font-weight:bold; }
    .btn-fluid { width:100%; }
    a.btn-black { color:#fff !important; font-weight: 600; }
    a.btn-black-outline { color:#000 !important; }
    a.btn-black:active { color:#fff !important; }
    a.btn-black:visited { color:#fff !important; }
    a.btn-black:hover { color:#fff !important; }
    .btn-black-outline { color:#000;background:transparent;font-weight:600;border:1px solid #000; }
    .btn-green { color:#fff;background-color:#366e50;font-weight:600; }
    .btn-white { color:#fff;background-color:#fff;font-weight:600;border:1px solid #fff; }
    .btn-outline { background-color:transparent !important; }

    .form input {
        border: 2px solid #8b8a8a;
        border-radius: 4px;
        height: 40px;
        width: 100%;
        margin-bottom: 20px;
        padding: 5px 10px;
    }

    .page-header {
        position: absolute;
        top: 0;
        width: 100%;
        height: 71px;
        border-bottom: 1px solid #1a1a1a;

        h2 {
            margin: 0 24px;
            font-size: 16px;
            font-weight: normal;
            line-height: 71px;
        }

        @media #{map-get($display-breakpoints, 'xs-only')} {
            top: 35px;
            height: 36px;

            h2 {
                line-height: 36px;
            }
        }
    }

    .static-label {
        font-size: 14px;
        line-height: 38px;
    }

    .static-form-control {
        font-size: 15px;
        font-weight: 500;
        line-height: 24px;
    }

    .authorization .fill-height {
        align-items: flex-start;
    }
    .authorization {
        width: 100%;
        height: 100%;
        background-color: #fcf3f3;
        background-image: url(assets/hp/bg-auth-1.png);
        background-size: 230px;
        background-position: calc(50% + 200px) calc(50% - 100px);

        h2 {
            margin: 0;
            font-size: 18px;
            text-align: center;
            line-height: 38px;
            font-weight: 600;
        }
    }
    .authorization > div {
        width: 100%;
        height: 100%;
        background-image: url(assets/hp/bg-auth-2.png);
        background-size: 300px;
        background-position: calc(50% - 120px) calc(50% + 150px);
    }
    .authorization .logo {
        height: 30px;
    }
    .authorization .form > div {
        background-color: #faf9f9;
        border-radius: 15px;
        box-shadow: 0 5px 14px 1px rgba(8, 30, 45, 0.07);
        width:350px;
        padding:32px;
        margin: auto;

        img {
            height: 25px;
            display: block;
            margin: 0 auto 20px;
        }
    }
    @media #{map-get($display-breakpoints, 'xs-only')} {
        .authorization .fill-height {
            align-items: center;
        }
        .authorization {
            background-position: top -30px right -20px;
            > div {
                background-position: bottom -10px left -50px;
            }
        }
    }
    .snackbar {
        opacity: 0;
        display: none;
        position: fixed;
        z-index: 1000;
        bottom: 30px;
        right: 0;
        left: 0;
        width: 500px;
        margin: auto;
        background-color: #333;
        color: #fff;
        padding: 15px;
        border-radius: 10px;
        box-shadow: rgba(0, 0, 0, 0.3) 0 2px 2px;
        text-align: center;
        font-size: 16px;
        line-height: 20px;
        font-weight: 300;
        transition: opacity ease-in-out 500ms;

        &.visible {
            opacity: 1;
        }
        &.displayed {
            display: block;
        }

        @media #{map-get($display-breakpoints, 'xs-only')} {
            width: 90%;
        }
    }

    @media only screen and (max-width: 799px) {
        .v-navigation-drawer__content {
            height: auto !important;
        }
    }

</style>
