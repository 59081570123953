<template>
    <div class="plan">
        <span class="plan-name"><strong>Dodatkowa sesja online</strong></span>
        <span class="plan-price"><strong>{{ price }}</strong> <small>zł/{{ duration }}min</small></span>
    </div>
</template>

<script>
export default {
    name: "AdditionalSession",
    props: {
        price: {
            type: Number,
            required: true
        },
        duration: {
            type: Number,
            required: true
        }
    }
}
</script>

<style scoped>
.plan {
    border-radius: 15px;
    background-color: #fff;
    box-shadow: rgba(8, 30, 45, 0.074) 0 -2px 14px;
    padding: 20px;
}
.plan-price {
    float: right;
}
.plan-price small {
    font-size: 12px;
}
</style>
