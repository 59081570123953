
const UriMixin = {
    methods: {
        uriToId: (uri) => {
            if (0 === uri.indexOf('/')) {
                const part = uri.split('/');
                uri = part[part.length - 1];
            }

            return uri;
        },
    }
}

export default UriMixin;
