import {Module, Mutation, VuexModule} from "vuex-module-decorators";
import {Chat} from "@/dataModel/chat";

/**
 * Keeps list of therapist's/consultant's chats.
 */
@Module({namespaced: true, name: 'therapist_chat'})
export class TherapistChatStore extends VuexModule {

    private _chats: Chat[] = [];

    get chats() {
        return this._chats;
    }

    get unreadChats() {
        return this._chats.filter(chat => chat.unread);
    }

    get readChats() {
        return this._chats.filter(chat => !chat.unread);
    }

    @Mutation
    public setChats(newValue: Chat[]) {
        this._chats = newValue;
    }

    @Mutation
    public markAsRead(chatId: string) {
        this._chats = this._chats.map((chat) => chat.chat_channel === chatId ? {...chat, unread: false} : chat);
    }
}
