import Repository from '../Repository';
import { buildHeaders } from '../Headers';

const ENDPOINT = '/api/storages';

export default {
    getOneById(id) {
        return Repository
            .get(`${ENDPOINT}/${id}`, {
                headers: buildHeaders()
            })
            .then((response) => {
                return response.data;
            })
            .catch(() => {
                this.errored = true
            })
    },
    preview(id) {
        return Repository
            .get(`${ENDPOINT}/${id}/preview`, {
                headers: buildHeaders()
            })
        ;
    },
    create(file, type, title) {
        const data = new FormData();

        data.append('file', file);
        data.append('type', type);
        data.append('title', title);

        return Repository
            .post('/api/upload', data, {
                headers: buildHeaders({
                    'Content-Type': 'multipart/form-data',
                })
            })
            .then(result => result.data)
            .then((result) => {
                return result;
            })
        ;
    },
    delete(id) {
        return Repository
            .delete(`${ENDPOINT}/${id}`, {
                headers: buildHeaders(),
            })
    },
}
