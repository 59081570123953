import Repository from '../Repository';
import {buildHeaders} from '../Headers';

const ENDPOINT = '/api/therapies';

export default {
    startTherapy(therapist) {
        return Repository
            .post(`${ENDPOINT}`, {
                therapist: therapist
            }, {
                headers: buildHeaders()
            });
    }
}
