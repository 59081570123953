<template>
    <div class="dialog">
        <v-card flat>
            <v-card-title v-if="title">{{ title }}</v-card-title>
            <v-card-text style="text-align: justify" v-if="text">
                {{ text }}
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <a @click="hide" v-if="!loading && !finished">Anuluj</a>
                <Button :placeholder="confirmText" :disabled="loading || finished"
                        :icon="icon()" :spin="loading"
                        @click.native="action" style="float: right"/>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import Button from "@/components/Form/Button";

export default {
    name: "ConfirmationDialog",
    props: {
        action: {
            type: Function,
            required: true
        },
        title: {
            type: String
        },
        text: {
            type: String
        },
        confirmText: {
            type: String,
            required: true
        },
        confirmIcon: {
            type: String
        },
        loading: {
            type: Boolean,
            default: false
        },
        finished: {
            type: Boolean,
            default: false
        }
    },
    components: {Button},
    methods: {
        icon() {
            if (this.loading) {
                return 'spinner';
            } else if (this.finished) {
                return 'check';
            }
            return this.confirmIcon;
        },
        hide() {
            this.$emit('hide');
        }
    }
}
</script>

<style scoped>
.dialog {
    background-color: #fff;
}

a {
    color: #666 !important;
    margin-right: 20px;
}

a:hover {
    color: #999 !important;
}
</style>
