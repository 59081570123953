<template>
    <div>
        <MainMenu/>

        <div id="calendar-main">
            <!-- create session -->
            <v-dialog v-model="createSessionDialog" max-width="300">
                <CreateSessionDialog :idx="createSessionIndex" :day="createSessionDay"/>
            </v-dialog>

            <v-row class="week-selector page-header" no-gutters style="min-width: 800px;">
                <v-col cols="auto">
                    <button @click="previousWeek()" style="margin-left: 5px;">
                        <font-awesome-icon icon="chevron-circle-left"/>
                    </button>
                </v-col>
                <v-col cols="auto">
                    <button @click="nextWeek()" style="margin-right: 8px;">
                        <font-awesome-icon icon="chevron-circle-right"/>
                    </button>
                </v-col>
                <v-col>
                    <span>{{ getWeekDescription() }}</span>
                </v-col>
            </v-row>

            <v-row class="calendar-header" no-gutters style="min-width: 800px">
                <v-col class="times" cols="auto">
                    <button class="small" @click="scaleDown">
                        <font-awesome-icon icon="minus"/>
                    </button>
                    <button class="small" @click="scaleUp">
                        <font-awesome-icon icon="plus"/>
                    </button>
                </v-col>
                <v-col v-for="day in this.getDays()" :key="day.toISOString()">
                    <span class="day-number">{{ day.date() }}</span>
                    <span class="day-name">{{ day.format('ddd') }}</span>
                </v-col>
            </v-row>
            <v-row class="calendar no-gutters" ref="calendar" style="min-width: 800px">
                <v-col class="times" cols="auto">
                    <div :style="{height: cellHeight}"></div>
                    <div :style="{height: cellHeight}">01:00</div>
                    <div :style="{height: cellHeight}">02:00</div>
                    <div :style="{height: cellHeight}">03:00</div>
                    <div :style="{height: cellHeight}">04:00</div>
                    <div :style="{height: cellHeight}">05:00</div>
                    <div :style="{height: cellHeight}">06:00</div>
                    <div :style="{height: cellHeight}">07:00</div>
                    <div :style="{height: cellHeight}">08:00</div>
                    <div :style="{height: cellHeight}">09:00</div>
                    <div :style="{height: cellHeight}">10:00</div>
                    <div :style="{height: cellHeight}">11:00</div>
                    <div :style="{height: cellHeight}">12:00</div>
                    <div :style="{height: cellHeight}">13:00</div>
                    <div :style="{height: cellHeight}">14:00</div>
                    <div :style="{height: cellHeight}">15:00</div>
                    <div :style="{height: cellHeight}">16:00</div>
                    <div :style="{height: cellHeight}">17:00</div>
                    <div :style="{height: cellHeight}">18:00</div>
                    <div :style="{height: cellHeight}">19:00</div>
                    <div :style="{height: cellHeight}">20:00</div>
                    <div :style="{height: cellHeight}">21:00</div>
                    <div :style="{height: cellHeight}">22:00</div>
                    <div :style="{height: cellHeight}">23:00</div>
                </v-col>
                <v-col class="day" v-for="(day, i) in this.getDays()" :key="day.toISOString()">
                    <div :style="{height: cellHeight}" class="time-slot"></div>
                    <div :style="{height: cellHeight}" class="time-slot"></div>
                    <div :style="{height: cellHeight}" class="time-slot"></div>
                    <div :style="{height: cellHeight}" class="time-slot"></div>
                    <div :style="{height: cellHeight}" class="time-slot"></div>
                    <div :style="{height: cellHeight}" class="time-slot"></div>
                    <div :style="{height: cellHeight}" class="time-slot"></div>
                    <div :style="{height: cellHeight}" class="time-slot"></div>
                    <div :style="{height: cellHeight}" class="time-slot"></div>
                    <div :style="{height: cellHeight}" class="time-slot"></div>
                    <div :style="{height: cellHeight}" class="time-slot"></div>
                    <div :style="{height: cellHeight}" class="time-slot"></div>
                    <div :style="{height: cellHeight}" class="time-slot"></div>
                    <div :style="{height: cellHeight}" class="time-slot"></div>
                    <div :style="{height: cellHeight}" class="time-slot"></div>
                    <div :style="{height: cellHeight}" class="time-slot"></div>
                    <div :style="{height: cellHeight}" class="time-slot"></div>
                    <div :style="{height: cellHeight}" class="time-slot"></div>
                    <div :style="{height: cellHeight}" class="time-slot"></div>
                    <div :style="{height: cellHeight}" class="time-slot"></div>
                    <div :style="{height: cellHeight}" class="time-slot"></div>
                    <div :style="{height: cellHeight}" class="time-slot"></div>
                    <div :style="{height: cellHeight}" class="time-slot"></div>
                    <div :style="{height: cellHeight}" class="time-slot"></div>
                    <!--                <div class="sessions" @click.stop.prevent="openCreateSessionDialog(i)">-->
                    <div class="sessions">
                        <div v-for="session in getCalendarSessions()[i]" :key="session.start.toISOString()" @click.stop
                             :class="{'inactive': session.status === 'inactive'}"
                             :style="{height: getHeight(session), top: getTop(session)}">
                            <a @click="openCancelSessionDialog(session)"
                               v-if="canCancel(session)" class="remove">
                                <font-awesome-icon icon="times"></font-awesome-icon>
                            </a>
                            <strong>{{ session.person }}</strong>
                            <br>{{ session.start.format('HH:mm') }} - {{ session.end.format('HH:mm') }}
                        </div>
                    </div>
                </v-col>
            </v-row>
        </div>

        <v-dialog v-if="sessionToCancel" :value="cancelSession" max-width="400"
                  @click:outside="cancelSession = false"
                  @hide="cancelSession = false">
            <ConfirmationDialog title="Odwołanie sesji"
                :text="'Masz zamiar odwołać sesję ' + sessionToCancel.start.format('dddd, D MMMM, HH:mm')"
                confirm-text="Odwołaj sesję"
                confirm-icon="times"
                :action="doCancelSession"/>
        </v-dialog>
    </div>
</template>

<script>
import MainMenu from "../MainMenu";
import {mapGetters, mapMutations} from "vuex";
import CreateSessionDialog from "@/components/Common/CreateSessionDialog";
import ConfirmationDialog from "@/components/Common/ConfirmationDialog";
import dayjs from "dayjs";

export default {
    name: "CalendarPage",
    components: {
        ConfirmationDialog,
        CreateSessionDialog,
        MainMenu,
    },
    data: () => ({
        createSessionDialog: false,
        createSessionDay: null,
        createSessionIndex: 0,
        dayNames: ['Pon', 'Wt', 'Śr', 'Cz', 'Pt', 'So', 'Nd'],
        cancelSession: false,
        sessionToCancel: null
    }),
    mounted() {
        this.scrollToEnd();
        this.$store.commit('fetchCalendar');
    },
    computed: {
        cellHeight: function () {
            return `${(60 * this.getCalendarScale())}px`;
        }
    },
    methods: {
        ...mapGetters(['getDays', 'getCalendarScale', 'getCalendarSessions', 'getWeekDescription']),
        ...mapMutations(['createSession', 'scaleDown', 'scaleUp']),
        nextWeek() {
            this.$store.commit('nextWeek');
            this.$store.commit('fetchCalendar');
        },
        previousWeek() {
            this.$store.commit('previousWeek');
            this.$store.commit('fetchCalendar');
        },
        scrollToEnd: function () {
            let content = this.$refs.calendar;
            if (content) this.$el.scrollTop = content.scrollHeight;
        },
        openCreateSessionDialog: function (index) {
            this.createSessionDialog = true;
            this.createSessionDay = this.getDays()[index];
            this.createSessionIndex = index;
        },
        getHeight: function (session) {
            return `${session.duration * this.getCalendarScale()}px`
        },
        getTop: function (session) {
            return `${((session.start.hour() * 60) + session.start.minute()) * this.getCalendarScale()}px`
        },
        canCancel: function (session) {
            const now = dayjs();
            return session.status === 'active'
                && now.isBefore(session.start)
                && now.add(24, 'hours').isBefore(session.start);
        },
        openCancelSessionDialog: function (session) {
            const now = dayjs();
            if (now.isAfter(session.start)) {
                this.$store.commit('snackbarShow', 'Nie możesz odwołać sesji z przeszłości.')
            } else if (now.isAfter(session.start.add(24, 'hours'))) {
                this.$store.commit('snackbarShow', 'Sesję można odwołać do 24 godzin przed rozpoczęciem.')
            } else {
                this.cancelSession = true;
                this.sessionToCancel = session;
            }
        },
        doCancelSession: function () {
            this.$store.dispatch('cancelSession', this.sessionToCancel);
            this.cancelSession = false;
        }
    }
}
</script>

<style scoped lang="scss">
@import '~vuetify/src/styles/settings/_variables.scss';

#calendar-main {
    overflow: auto;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;

    @media #{map-get($display-breakpoints, 'sm-and-down')} {
        top: 35px;
    }
}

.week-selector {
    height: 36px;
    line-height: 36px;
    position: sticky;
    z-index: 1;
    background: #fcfcfc;
    top: 0;
}

.calendar-header {
    background: #fcfcfc;
    position: sticky;
    top: 36px;
    z-index: 1;
    height: 40px;

    .times {
        margin-top: 5px;
        text-align: center;
    }
}

.calendar-header div {
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}

.day-number {
    font-size: 20px;
    display: inline-block;
    padding: 5px;
    vertical-align: -5px;
}

.day-name {
    font-size: 13px;
    text-transform: uppercase;
}

.times {
    width: 55px;
    text-align: right;
    font-size: 12px;
    border-right: 1px solid #ddd;
    margin-top: -10px;
}

.times > div {
    padding: 0 5px;
}

.time-slot {
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    position: relative;
}

.day {
    position: relative;
}

.day .sessions {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.day .sessions > div {
    margin: 0 1px;
    background-color: #F8D8DA;
    position: absolute;
    left: 0;
    border-radius: 5px;
    padding: 5px;
    font-size: 11px;
    right: 0;
    overflow-y: hidden;
    box-shadow: #ddd 0 2px 3px;
    border: 1px solid #EF5A27;
}
.day .sessions > div.inactive {
    background-color: #eee;
    border-color: #ddd;
}

.day .sessions > div strong {
    font-weight: 500;
}

button {
    margin: 1px;
    border-radius: 5px;
    background-color: #fff;
    height: 24px;
    line-height: 24px;
    width: 24px;
    display: inline-block;
    text-align: center;
    box-shadow: #aaa 0 0 5px;

    &.small {
        height: 20px;
        line-height: 20px;
        width: 20px;
    }
}

button:hover {
    box-shadow: #aaa 0 0 8px;
}

.remove {
    display: block;
    position: absolute;
    font-size: 150%;
    right: 5px;
    top: 0;
    color: #EF5A27;
}
</style>
