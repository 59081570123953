<template>
    <div style="display: inline-block; max-width: 660px; width: 100%;">
        <v-row class="card align-center pa-2" style="margin: 15px 0;">
            <v-col cols="auto">
                <img style="display: inline-block" :src="require('../../assets/default-avatar-300x300.png')">
            </v-col>
            <v-col>
                <div v-if="therapist" class="name">{{ therapist.firstname + ' ' + therapist.lastname }}</div>
                <div v-else class="name shine"></div>
                <div v-if="therapist" class="bio">{{ therapist.bio }}</div>
                <div v-else>
                    <div class="line shine"></div>
                    <div class="line shine"></div>
                    <div class="line shine"></div>
                </div>
            </v-col>
            <v-col cols="auto">
                <Button :disabled="therapist === null || cannotSelectTherapist(therapist)"
                        placeholder="Rozpocznij terapię"
                        @click.native="setTherapist(therapist)"/>
            </v-col>
        </v-row>

        <v-dialog :value="dialog" max-width="400" v-if="therapist" @click:outside="dialog = false">
            <v-card>
                <v-card-title>Rozpocznij terapię</v-card-title>
                <v-card-text v-if="this.getUser().active_therapy !== null">
                    Wybierając terapetuę, rozpoczniesz nową terapię. Wybierając nowego terapeutę
                    utracisz dostęp do historii czatu z aktualnym terapeutą.
                </v-card-text>
                <v-card-text v-else>
                    Po wybraniu terapetuę, pojawi Ci się nowe okno czatu, które będzie dostępne
                    tylko dla Ciebie i Twojego psychoterapeuty.
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <a @click="dialog = false">Anuluj</a>
                    <Button :icon="isBeingPaid ? 'spinner' : 'user'" :spin="isBeingPaid"
                            :disabled="isBeingPaid" placeholder="Wybierz terapeutę" @click.native="startTherapy"/>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import TherapistRepository from "@/repository/Resource/TherapistRepository";
import TherapyRepository from "@/repository/Resource/TherapyRepository";
import Button from "@/components/Form/Button";
import {mapGetters, mapMutations} from "vuex";

export default {
    name: "Therapist",
    components: {Button},
    props: ['id'],
    data: function () {
        return {
            therapist: null,
            isBeingPaid: false,
            dialog: false
        }
    },
    methods: {
        ...mapGetters(['getCheckoutTherapist', 'getUser']),
        ...mapMutations(['setCheckoutTherapist', 'setActiveTherapy', 'snackbarShow']),
        init() {
            TherapistRepository
                .getOneById(this.id)
                .then(t => {
                    this.therapist = t;
                    this.$emit('data-loaded');
                });
        },
        cannotSelectTherapist(therapist) {
            // cannot change therapist when there's upcoming session
            const hasNearestSession = this.getUser().nearest_session !== null;
            // cannot change therapist to the same therapist
            const hasTherapyWithThisTherapist = this.getUser().active_therapy !== null
                && this.getUser().active_therapy.user.id === therapist.user_id;
            return hasNearestSession || hasTherapyWithThisTherapist;
        },
        startTherapy() {
            this.isBeingPaid = true;
            const therapist = this.getCheckoutTherapist();
            TherapyRepository.startTherapy(therapist.id)
                .then(response => {
                    if (response.status === 201) {
                        this.setActiveTherapy(response.data);
                        this.snackbarShow(`Wybrałeś terapeutę ${therapist.firstname} ${therapist.lastname}`);
                    } else {
                        this.snackbarShow('Wystąpił nieoczekiwany błąd.');
                    }
                })
                .finally(() => {
                    this.isBeingPaid = false;
                    this.dialog = false;
                });

        },
        setTherapist(therapist) {
            this.setCheckoutTherapist(therapist);
            this.isBeingPaid = false;
            this.dialog = true;
        }
    },
    mounted() {
        this.init();
    }
}
</script>

<style scoped lang="scss">

@import '~vuetify/src/styles/settings/_variables.scss';

@media #{map-get($display-breakpoints, 'sm-and-down')} {
    .card {
        flex-direction: column;
        width: auto;
    }
}

img {
    border-radius: 50%;
    width: 90px;
}

.name {
    text-align: left;
    font-size: 16px;
    font-weight: bold;
    line-height: 32px;
}

.name.shine {
    width: 200px;
    height: 20px;
    margin: 6px 0;
}

.bio {
    text-align: left;
    font-size: 14px;
}

.line.shine {
    width: 100%;
    height: 14px;
    line-height: 14px;
    margin-bottom: 3px;
}

.therapist-name {
    font-size: 16px;
    font-weight: bold;
    line-height: 32px;
}

dt {
    border-top: 1px solid #E6E8EA;
    padding-top: 10px;
    font-size: 14px;
    line-height: 32px;

    &:first-child {
        padding-top: 0;
        border-top-width: 0;
    }
}

dd {
    padding: 10px 0;
}

a {
    color: #666 !important;
    margin-right: 20px;
}

a:hover {
    color: #999 !important;
}

</style>
