<template>
    <div>
        <MainMenu/>

        <div class="page-header">
            <h2>Twój abonament</h2>
        </div>

        <div id="subscription-main">
            <v-row v-if="activeSubscription">
                <v-col cols="auto">
                    <Plan class="plan"
                          :name="activeSubscription.plan.name"
                          :price="activeSubscription.plan.price"
                          :answers="activeSubscription.plan.answers ? '1-2' : null"
                          :sessions="activeSubscription.plan.sessions"
                          :session-duration="activeSubscription.plan.sessionDuration"/>
                </v-col>
                <v-col>
                    <dl>
                        <dt class="static-label">Pozostałe sesje w ramach abonamentu</dt>
                        <dd class="static-form-control">{{ activeSubscription.sessions_left }}</dd>
                        <dt class="static-label">Następna płatność</dt>
                        <dd class="static-form-control">{{ activeSubscription.current_period_end.substring(0, 10) }}</dd>
                    </dl>
                </v-col>
            </v-row>
            <v-row v-else>
                <v-col style="font-size: 14px;">
                    Brak wykupionego abonamentu
                </v-col>
            </v-row>
        </div>

    </div>
</template>

<script>
import MainMenu from "../MainMenu";
import Plan from "@/components/Common/Plan";
import {mapGetters} from "vuex";

export default {
    name: "SubscriptionPage",
    components: {
        Plan,
        MainMenu
    },
    computed: {
        activeSubscription() {
            if (this.getUser()) {
                return this.getUser().active_subscription
            }
            return null;
        }
    },
    methods: {
        ...mapGetters(['getUser'])
    }
}
</script>

<style scoped lang="scss">
@import '~vuetify/src/styles/settings/_variables.scss';

#subscription-main {
    padding: 24px;
    height: calc(100vh - 71px);
    position: absolute;
    width: 100%;
    top: 71px;
    overflow-y: auto;

    .plan {
        display: inline-block;
        max-width: 250px;
        width: 100%
    }

    @media #{map-get($display-breakpoints, 'sm-and-down')} {
        > .row {
            flex-direction: column;
        }
    }
}
</style>
