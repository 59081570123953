import {QueuedMessage} from "@/dataModel/chat";
import Vue from "vue";

const subscriptions: { [sendingId: string]: () => void } = {};

export function messageTimeout(sendingId: string) {
    console.log('message timeout', sendingId);
    delete subscriptions[sendingId];
}

export function messageDelivered(sendingId: string) {
    if (subscriptions[sendingId]) {
        console.log('message delivered', sendingId)
        subscriptions[sendingId]();
    }
    delete subscriptions[sendingId];
}

export function waitForMessage(sendingId: string, callback: () => void) {
    subscriptions[sendingId] = callback;
}

export function sendMessage(message: QueuedMessage) {
    Vue.prototype.$socket.send(JSON.stringify(message));
}
