<template>
    <div class="authorization">
        <div class="container fill-height">
            <div class="row hidden-xs-only options justify-space-between">
                <div class="col-auto">
                    <img class="logo" :src="require('../../../assets/hp/logo.png')">
                </div>
                <div class="col-auto">
                    <router-link :to="{ name: 'route_login' }" style="color:#1a1a1a;font-weight:500;">
                        Masz już konto?
                    </router-link>
                    <router-link :to="{ name: 'route_login' }" class="btn btn-sm btn-black">
                        Zaloguj się
                    </router-link>
                </div>
            </div>
            <div class="row form">
                <div>
                    <img class="logo hidden-sm-and-up" :src="require('../../../assets/hp/logo.png')">

                    <h2>Zarejestruj się</h2>

                    <form @submit.prevent="registerSubmit" style="padding-top:30px;" autocomplete="off">
                        <div class="form-group">
                            <input type="text" v-model="username" id="username" name="username" placeholder="Email"
                                   :class="{ 'error-input': !!usernameMessage }" :disabled="loading"/>
                            <div v-show="submitted && usernameMessage"
                                 :class="{ 'error-invalid': true, 'visible':!!usernameMessage }">{{ usernameMessage }}
                            </div>
                        </div>

                        <div class="form-group">
                            <input type="text" v-model="firstname" id="firstname" name="firstname" placeholder="Imię"
                                   :class="{ 'error-input': !!firstnameMessage }" :disabled="loading"/>
                            <div v-show="submitted && firstnameMessage"
                                 :class="{ 'error-invalid': true, 'visible':!!firstnameMessage }">{{ firstnameMessage }}
                            </div>
                        </div>

                        <div class="form-group">
                            <v-checkbox v-model="termsOfUse" class="mx-2"
                                        style="margin-top:0;" color="#1a1a1a"
                                        persistent-hint :disabled="loading"
                                        :class="{ 'error-input': !!termsOfUseMessage }">
                                <template v-slot:label>
                                    <div style="font-size: 80%;">Oświadczam, że zapoznałem się z treścią
                                        <a @click.stop href="https://mojterapeuta.app/regulamin.pdf">Regulaminu</a>
                                        i zgadzam się z jego postanowieniami.
                                    </div>
                                </template>
                            </v-checkbox>
                            <div v-show="submitted && termsOfUseMessage"
                                 :class="{ 'error-invalid': true, 'visible':!!termsOfUseMessage }">
                                {{ termsOfUseMessage }}
                            </div>
                        </div>
                        <div class="form-group">
                            <v-checkbox v-model="privacyPolicy" class="mx-2"
                                        style="margin-top:0;" color="#1a1a1a"
                                        persistent-hint :disabled="loading"
                                        :class="{ 'error-input': !!privacyPolicyMessage }">
                                <template v-slot:label>
                                    <div style="font-size: 80%;">Zgadzam się na przetwarzanie danych wrażliwych (m.in. zdrowia) w celu
                                    usprawnienia procesu bezpłatnej konsultacji, dobrania terapeuty,
                                        a także realizacji umowy zgodnie z <a @click.stop href="https://mojterapeuta.app/polityka-prywatnosci.pdf">Polityką prywatności</a></div>
                                </template>
                            </v-checkbox>
                            <div v-show="submitted && privacyPolicyMessage"
                                 :class="{ 'error-invalid': true, 'visible':!!privacyPolicyMessage }">
                                {{ privacyPolicyMessage }}
                            </div>
                        </div>
                        <Button class="btn-fluid"
                                :icon="loading ? 'spinner' : null" :spin="loading"
                                :disabled="loading" placeholder="Rozpocznij teraz"/>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import Spot1Icon from '../Icons/Spot1Icon';
import UserRepository from "../../../repository/Resource/UserRepository";
import Router from "../../../service/Router";
import Button from "@/components/Form/Button";

export default {
    name: "RegisterPage",
    components: {Button},
    data() {
        return {
            loading: false,
            username: '',
            usernameMessage: '',
            firstname: '',
            firstnameMessage: '',
            termsOfUse: false,
            termsOfUseMessage: '',
            privacyPolicy: false,
            privacyPolicyMessage: '',
            submitted: false
        }
    },
    methods: {
        resetMessages() {
            this.usernameMessage = '';
            this.firstnameMessage = '';
            this.termsOfUseMessage = '';
            this.privacyPolicyMessage = '';
        },
        registerSubmit() {
            this.loading = true;
            this.submitted = true;
            this.resetMessages();
            this.$store.commit('loginStart');

            let emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            if (!this.username) {
                this.usernameMessage = 'Adres email jest wymagany';
            } else if (!emailRegex.test(String(this.username).toLowerCase())) {
                this.usernameMessage = 'Nieprawidłowy format adresu e-mail';
            }

            if (!this.firstname) {
                this.firstnameMessage = 'Imię jest wymagane';
            } else if (this.firstname.length < 2) {
                this.firstnameMessage = 'Imię musi składać się z conajmniej 2 znaków';
            }

            if (!this.termsOfUse) {
                this.termsOfUseMessage = 'Akceptacja regulaminu jest wymagana';
            }

            if (!this.privacyPolicy) {
                this.privacyPolicyMessage = 'Akceptacja polityki prywatności jest wymagana';
            }

            if (this.usernameMessage || this.firstnameMessage || this.termsOfUseMessage || this.privacyPolicyMessage) {
                this.loading = false;
                return;
            }

            UserRepository
                .create(this.username, this.firstname)
                .then((response) => {
                    if (typeof response.headers['authorization-token'] === 'undefined' || !response.headers['authorization-token'].length) {
                        throw new Error();
                    }

                    this.$store.commit('saveToken', response.headers['authorization-token']);
                    localStorage.setItem('token', response.headers['authorization-token']);
                    Router.push('/consultation');
                })
                .catch((response) => {
                    if (400 === response.status) {
                        this.usernameMessage = response.data.violations[0].message;
                    }
                })
                .finally(() => this.loading = false)
            ;
        }
    }
}
</script>

<style scoped>
.error-invalid {
    position: relative;
    top: -18px;
    color: #e98da8;
    text-align: right;
    font-size: 12px;
}

.error-input {
    border-color: #e98da8;
}
</style>
