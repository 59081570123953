<template>
    <div class="secure-image" style="border:1px solid #ddd;overflow:hidden;position:relative;"
            :style="{'max-width': limitSize === true ? '100px' : '400px', 'max-height': limitSize === true ? '60px' : null}">
        <span v-if="this.controlRemove" style="color:#000;width:20px;height:20px;position:absolute;right:0;">
            <font-awesome-icon icon="times" @click="removeResource"/>
        </span>
        <img v-if="this.file" :src="this.file" style="max-width:500px;max-height:300px;display:block;margin-left:auto;margin-right:auto;">
        <img v-else src="../../assets/loading-ring.gif" style="width: 60px; height: 60px" alt="Loading...">
    </div>
</template>

<script type="ts">
    import StorageRepository from "../../repository/Resource/StorageRepository";

    export default {
        name: "SecureImage",
        props: {
            storageId: {
                type: String,
                required: true,
            },
            mimeType: {
                type: String,
                required: true,
            },
            limitSize: {
                type: Boolean,
            },
            controlRemove: {
                type: Boolean,
            }
        },
        data: () => {
            return {
                file: null,
            };
        },
        methods: {
            removeResource() {
                this.$store.dispatch('message/removeResource', this.storageId);
            }
        },
        created() {
            StorageRepository
                .preview(this.storageId)
                .then((result) => {
                    this.file = `data:${this.mimeType};base64,${result.data}`;
                    setTimeout(() => this.$emit('data-loaded'), 10);
                })
            ;
        }
    }
</script>

<style scoped>
.secure-image {
    border: 0 !important;
    border-radius: 15px;
}
/*
.secure-image {
    padding: 5px;
    border-radius: 5px;
}
*/
</style>
