import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {PendingMessage, Resource, Message} from "@/dataModel/chat";
import {User} from "@/dataModel/user";
import {v4 as uuid} from 'uuid';
import {sendingMessagesStore} from "@/service/store";

@Module({namespaced: true, name: 'message'})
export class MessageStore extends VuexModule {

    private _pendingMessages: { [channelId: string]: PendingMessage } = JSON.parse(localStorage.getItem('pendingMessages') ?? '{}');

    get pending(): PendingMessage {
        const channelId: string = this.context.rootGetters['getCurrentChatChannelId'];
        return this._pendingMessages[channelId] ?? {channelId, message: '', resources: []};
    }

    @Mutation
    updateMessage(pendingMessage: PendingMessage) {
        this._pendingMessages = {...this._pendingMessages, [pendingMessage.channelId]: pendingMessage};
        localStorage.setItem('pendingMessages', JSON.stringify(this._pendingMessages));
    }

    @Action
    setMessage(message: string) {
        const channelId: string = this.context.rootGetters['getCurrentChatChannelId'];
        const pendingMessage = {
            channelId: channelId,
            message: message,
            resources: this._pendingMessages[channelId]?.resources ?? []
        };
        this.context.commit('updateMessage', pendingMessage);
    }

    @Action
    clearMessage() {
        const channelId: string = this.context.rootGetters['getCurrentChatChannelId'];
        this.context.commit('updateMessage', {channelId, message: '', resources: []});
    }

    @Action
    addResource(resource: Resource) {
        const channelId: string = this.context.rootGetters['getCurrentChatChannelId'];
        const pendingMessage = {
            channelId: channelId,
            message: this._pendingMessages[channelId]?.message ?? '',
            resources: [...this._pendingMessages[channelId]?.resources ?? [], resource]
        }
        this.context.commit('updateMessage', pendingMessage);
    }

    @Action
    removeResource(id: string) {
        const channelId: string = this.context.rootGetters['getCurrentChatChannelId'];
        const pendingMessage = {
            channelId: channelId,
            message: this._pendingMessages[channelId]?.message ?? '',
            resources: (this._pendingMessages[channelId]?.resources ?? []).filter((res) => res.id !== id)
        }
        this.context.commit('updateMessage', pendingMessage);
    }

    @Action({rawError: true})
    send() {
        const channelId: string = this.context.rootGetters['getCurrentChatChannelId'];
        const user: User = this.context.rootGetters['getUser'];
        const payload: Message = {
            id: uuid(),
            created_at: new Date().toISOString(),
            member_id: user.id,
            channel_id: channelId,
            body: this._pendingMessages[channelId].message,
            resources: this._pendingMessages[channelId].resources,
            metadata: null,
        };
        sendingMessagesStore.push(payload);
        return this.context.dispatch('clearMessage');
    }
}
