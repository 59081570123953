// @ts-ignore
import Reducer from 'image-blob-reduce';

export default class ImageReduce extends Reducer {
    // @ts-ignore
    _create_blob = (env: Env) => {
        // @ts-ignore
        return this.pica.toBlob(env.out_canvas, 'image/jpeg', 0.85)
            .then(function (blob: Blob) {
                env.out_blob = blob;
                return env;
            });
    };

    toBlob(file: File | null, options: object): Promise<Blob> {
        return super.toBlob(file, options);
    }
}
