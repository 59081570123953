<template>
    <v-col class="header-container">
        <h2 :id="headerId" :style="{'background-color':bgColor}">{{ label }}</h2>
    </v-col>
</template>

<script>
export default {
    name: "Header",
    props: ['headerId', 'label', 'bgColor']
}
</script>

<style scoped lang="scss">
@import '~vuetify/src/styles/settings/_variables.scss';

.col {
    background-image: url(../../assets/hp/line.png);
    background-position: center;
    background-size: 100% 2px;
    padding-left: 0;

    @media #{map-get($display-breakpoints, 'xs-only')} {
        background-position: top;
        background-size: 95% 2px;
    }
}

h2 {
    padding-right: 20px;
    padding-left: 10px;
    display: inline-block;

    @media #{map-get($display-breakpoints, 'xs-only')} {
        margin: 10px 0;
        font-size: 24px;
        line-height: 42px;
    }
}
</style>